<template>
  <div ref="refCanvasContainer" class="relative w-full h-full bg-">
    <loader v-if="!collaborationReady" />
    <canvas id="c" />

    <div
      v-show="!connected" class="absolute top-0 right-0 left-0 bottom-0 bg-black bg-opacity-50 text-white flex justify-center items-center z-[2000] text-lg"
      v-text="t('general.connecting')"
    />
    <div class="absolute top-0 bottom-0 left-0 right-0 flex flex-col pointer-events-none">
      <div class="flex h-20 p-3">
        <titlebar
          v-show="!isFullScreen" :whiteboard="wb" :name="whiteboardDetails?.Name" :permission="userPermission" class="flex-1 flex-grow-0 flex-shrink pointer-events-auto"
          @toggle-comments="showCommentsPanel = !showCommentsPanel" @settings-changed="settingsChanged"
        />
        <div class="flex-grow" />
        <users-bar
          v-show="!isFullScreen" :users="connectedUsers" :whiteboard-id="whiteboardId" :whiteboard="wb" :permission="userPermission" :can-invite="canInvite"
          class="text-right pointer-events-auto"
        />
      </div>
      <div v-if="wb" class="flex-1">
        <objects-toolbar
          ref="objectsToolbarRef" :hide="isFullScreen" :left="isSidebarOpen ? '280px' : '20px'" :whiteboard="wb" :collaboration-ready="collaborationReady" :permission="userPermission"
          @object-added="onObjectAdded" @btn-click="onToolbarBtnClick" @handle-images-upload="handleImagesDrop"
        />
      </div>
      <div class="flex h-20 p-3">
        <div class="flex-grow" />
        <div class="flex h-8 m-auto bg-white rounded-lg cursor-pointer pointer-events-auto shadow-toolbar">
          <font-awesome-icon icon="fa-light fa-minus" class="w-5 h-5 mx-1 my-auto" @click="zoomOut" />
          <div class="m-auto text-md" @dblclick="resetZoom">
            {{ zoom }}%
          </div>
          <font-awesome-icon icon="fa-light fa-plus" class="w-5 h-5 mx-1 my-auto" @click="zoomIn" />
        </div>
      </div>
    </div>

    <sidebar v-show="!isFullScreen" v-model="isSidebarOpen" :whiteboard="wb" @toggle-fullscreen="toggleFullScreen" />
    <comments-list v-show="!isFullScreen" v-model="showCommentsPanel" :whiteboard="wb" @toggle-fullscreen="toggleFullScreen" />
    <object-properties
      v-if="wb && !isFullScreen" ref="objectPropertiesRef" :whiteboard-id="whiteboardId" :whiteboard="wb" :permission="userPermission"
      @open-text-editing-dialog="onOpenTextEditingDialog" @copy-model="onCopyModel" @edit-articles="onEditArticles" @copy-articles="onCopyArticles" @save-images="onSaveImages"
      @edit-favorites="showFav" @handle-images-drop="handleImagesDrop"
    />

    <!-- Sticky text editing dialog -->
    <tx-dialog v-model="textEditingDialogVisible" :title="t('general.edit')" show-ok-cancel @ok="setObjectText">
      <tx-input ref="objectTextRef" v-model="objectText" type="textarea" />
    </tx-dialog>

    <!-- Article Details -->
    <tx-drawer
      v-model="articleDetailsOpen" :width="enableNewStyleDetails ? '95%' : '1024px'" right
      @closed="closeArticleDetails"
    >
      <model-details
        v-if="enableNewStyleDetails && clickedArticle" :article="clickedArticle" @change-article-selection="onChangeArticle"
        @updated="onArticleUpdated" @change="onArticleClick" @close="closeArticleDetails" @on-edit-fav="onArticleDetailsEditFav"
      />
      <article-details
        v-else-if="clickedArticle" :article="clickedArticle"
        @updated="onArticleUpdated" @change="onArticleClick" @on-edit-fav="onArticleDetailsEditFav"
      />
    </tx-drawer>
    <!-- EDIT ARTICLES -->
    <tx-drawer v-model="updateArticlesDrawerVisible" width="700px" right @closed="closeUpdateArticles">
      <update-article
        :articles="selectedArticlesForEdit"
        :is-model="false" @cancel="closeUpdateArticles" @updated="onArticlesUpdated"
      />
    </tx-drawer>
    <!-- STYLE CREATE DRAWER -->
    <tx-drawer
      v-model="isAddArticleDrawerVisible" width="90%" right :show-close="false" :close-on-esc="false"
      :close-on-overlay-click="false"
    >
      <add-articles
        :action-type="styleCreateAction" :context-article="copyStyleContextArticle"
        :visible="isAddArticleDrawerVisible" @close="onAddArticleDrawerClosed"
      />
    </tx-drawer>

    <!-- Find & Replace Dialog -->
    <find-and-replace-dialog v-model="findReplaceDialogVisible" :whiteboard="wb" />

    <!-- Generate frame dialog -->
    <generate-dialog ref="refGenerateFrameDialog" v-model="generateFrameDialogVisible" :whiteboard="wb" :is-merch="false" />

    <!-- Save Images dialog -->
    <save-image-dialog ref="saveImageDlg" />

    <favorites-editor-dialog ref="favoritesDlg" />

    <!-- Upload image progress-bar -->
    <tx-dialog
      v-model="uploadImageProgressDialogVisible" :title="t('whiteboard.uploadImage.title')" show-ok :ok-state="uploadImageProgress < 100 ? 'disabled' : 'enabled'"
      confirm-text="general.close" @ok="closeUploadImageDialog"
    >
      <tx-progress v-model="uploadImageProgress" :title="uploadImagesProgressTitle" />
    </tx-dialog>
  </div>
</template>

<script setup lang="ts">
import { fabric } from 'fabric'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { computed, nextTick, onMounted, onUnmounted, ref, shallowRef, watch } from 'vue'
import ArticleDetails from '../articleDetails/ArticleDetails.vue'
import ObjectsToolbar from './components/ObjectsToolbar.vue'
import Sidebar from './components/Sidebar.vue'
import CommentsList from './components/CommentsList.vue'
import ObjectProperties from './components/ObjectProperties.vue'
import Titlebar from './components/Titlebar.vue'
import UsersBar from './components/UsersBar.vue'
import WbModelImage from './services/modelImage'
import WbArticleImage from './services/articleImage'
import WbArticleDetails from './services/articleDetails'
import WbModelDetails from './services/modelDetails'
import usePresence from './composables/presence'
import useCollaboration from './composables/collaboration'
import useWindowEvents from './composables/windowEvents'
import WbFrame from './services/frame'
import SaveImageDialog from '@/modules/browse/components/SaveImageDialog.vue'
import type { ICanvasObjectsLoadedEvent } from '@/modules/whiteboard/services/whiteboard'
import Whiteboard from '@/modules/whiteboard/services/whiteboard'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxDrawer from '@/shared/components/TxDrawer.vue'
import FindAndReplaceDialog from '@/modules/whiteboard/components/FindAndReplaceDialog.vue'
import GenerateDialog from '@/shared/components/GenerateDialog.vue'
import ModelDetails from '@/modules/articleDetails/ModelDetails.vue'
import AddArticles from '@/modules/browse/components/addArticles'
import type MyArticle from '@/models/myArticle'
import { getWhiteboardDetails } from '@/api/t1/whiteboard'
import type { WhiteboardDetailsModel } from '@/api/t1/model/whiteboardModel'
import { whiteboardConstants } from '@/models/constants'
import useArticleDetails from '@/shared/composables/articleDetails'
import { useUserStore } from '@/store/userData'
import { useNotificationStore } from '@/store/notification'
import appConfig from '@/services/appConfig'
import Loader from '@/shared/components/Loader.vue'
import UpdateArticle from '@/modules/articleDetails/components/UpdateArticle.vue'
import { getAccessibleUserGroupDetails } from '@/api/t1/user'
import FavoritesEditorDialog from '@/shared/components/favorites/FavoritesEditorDialog.vue'
import TxProgress from '@/shared/components/TxProgress.vue'

const { t } = useI18n()
const route = useRoute()
const userStore = useUserStore()
const router = useRouter()
const notificationStore = useNotificationStore()
const { articleDetailsOpen, clickedArticle, onArticleUpdated, onArticleClick, openArticleDetails, onChangeArticle, closeArticleDetails } = useArticleDetails('WhiteboardDetails')

// VARIABLES
const refCanvasContainer = ref<HTMLElement>()
const objectsToolbarRef = ref<InstanceType<typeof ObjectsToolbar>>()
const isSidebarOpen = ref(false)
const showCommentsPanel = ref(false)
const selectedEditingObj = ref<IWbObject>()
const objectText = ref('')
const objectTextRef = ref<InstanceType<typeof TxInput> | null>()
const zoom = ref(100)
const wb = shallowRef<Whiteboard>()
const whiteboardDetails = shallowRef<WhiteboardDetailsModel>()
const refGenerateFrameDialog = ref<InstanceType<typeof GenerateDialog>>()
const objectPropertiesRef = ref<InstanceType<typeof ObjectProperties>>()
const isAddArticleDrawerVisible = ref(false)
const updateArticlesDrawerVisible = ref(false)
let selectedArticlesForEdit: MyArticle[] = []
let styleCreateAction: StyleCreateActionType | undefined
let copyStyleContextArticle: MyArticle | undefined
let copyStyleSelectedObjectDetails: { left: number, top: number, type: string } | undefined
const userPermission = ref(whiteboardConstants.userPermissions.write as WhiteboardUserPermissionType)
const saveImageDlg = ref<InstanceType<typeof SaveImageDialog>>()
const favoritesDlg = ref<InstanceType<typeof FavoritesEditorDialog>>()

const whiteboardId = computed(() => Number(route.params.whiteboardId))

const { collaborationReady, connected, dbConn, dbDoc, userDoc } = useCollaboration(whiteboardId.value, wb)
const usersPresence = usePresence(whiteboardId.value, wb, dbConn)
const { textEditingDialogVisible, findReplaceDialogVisible, generateFrameDialogVisible, isFullScreen, isArticleOrModelDetailsVisible, isDisableCopyPasteUndoRedo, handleImagesDrop, closeUploadImageDialog, uploadImageProgressDialogVisible, uploadImagesProgressTitle, uploadImageProgress } = useWindowEvents(wb, whiteboardId)

const connectedUsers = computed(() => Array.from(usersPresence.users.value.values()))
const enableNewStyleDetails = computed(() => userStore.activeCatalog?.Config.EnableNewStyleDetails || false)
// only owner and admin user can invite users
const canInvite = computed(() => whiteboardDetails.value?.CreatedBy === userStore.userProfile.Id || userStore.userProfile.AccountDetails.AccountTypeId === 1)

function onResizeContent({ width, height }) {
  wb.value?.setSize(width, height)
}

function zoomIn() {
  if (wb.value) {
    const oldZoom = wb.value.canvas.getZoom()
    const zoom = oldZoom * 1.2
    if (zoom <= 5) {
      wb.value.zoom(zoom, { x: wb.value.canvas.getWidth() / 2, y: wb.value.canvas.getHeight() / 2 }, true)
    }
  }
}

function zoomOut() {
  if (wb.value) {
    const oldZoom = wb.value.canvas.getZoom()
    const zoom = oldZoom / 1.2
    if (zoom >= 0.02) {
      wb.value.zoom(zoom, { x: wb.value.canvas.getWidth() / 2, y: wb.value.canvas.getHeight() / 2 }, true)
    }
  }
}

function resetZoom() {
  if (wb.value) {
    wb.value.zoom(1, { x: wb.value.canvas.getWidth() / 2, y: wb.value.canvas.getHeight() / 2 }, true)
  }
}
function settingsChanged(showComments: boolean) {
  if (showCommentsPanel.value && !showComments) {
    showCommentsPanel.value = false
  }
  const allObjects = wb.value?.canvas.getObjects() as Array<IMbObject>
  if (allObjects && allObjects.length) {
    const discussionObjects = allObjects.filter(o => o.type === whiteboardConstants.objectTypes.discussion)
    if (discussionObjects.length) {
      discussionObjects.forEach((object) => {
        object.set('visible', showComments)
        const div = document.getElementById('manageDiscussion')
        if (div) {
          div.style.opacity = object.visible ? '1' : '0' // Smooth fade effect
          div.style.pointerEvents = object.visible ? 'auto' : 'none' // Disable inter
        }
      })
    }
    wb.value?.canvas.renderAll()
  }
}
function handleDoubleClick() {
  let showArticleDetails = false
  let articleId = -1
  let isRequest = false
  let catalogCode = -1
  const obj = wb.value?.canvas.getActiveObject() as IWbObject
  if (obj && obj.type === whiteboardConstants.objectTypes.sticky) {
    const prop = obj.getProp('text')
    objectText.value = prop.text
    selectedEditingObj.value = obj
    textEditingDialogVisible.value = userPermission.value === whiteboardConstants.userPermissions.write
    nextTick(() => {
      objectTextRef.value?.focus()
    })
  }

  if (obj && (obj.type === whiteboardConstants.objectTypes.articleImage || obj.type === whiteboardConstants.objectTypes.modelImage)) {
    showArticleDetails = true
    if (obj instanceof WbArticleImage) {
      catalogCode = obj.catalogCode
      articleId = obj.articleId
      isRequest = obj.isRequest
    }
    else if (obj instanceof WbModelImage) {
      catalogCode = obj.catalogCode
      articleId = obj.articleId
    }
  }
  else if (obj && (obj.type === whiteboardConstants.objectTypes.group)) {
    const allObjects = wb.value?.canvas.getObjects() as Array<IMbObject>
    const articleImages = allObjects.filter(o => o.type === 'articleImage')
    const modelImages = allObjects.filter(o => o.type === 'modelImage')
    // if there is one article image, show article details
    if (articleImages.length === 1 && modelImages.length === 0) {
      if (articleImages[0] instanceof WbArticleImage) {
        articleId = articleImages[0].articleId
      }
      showArticleDetails = true
    }
    else if (modelImages.length === 1 && articleImages.length === 0) {
      if (modelImages[0] instanceof WbModelImage) {
        articleId = modelImages[0].articleId
      }
      showArticleDetails = true
    }
  }

  if (userStore.activeCatalog && showArticleDetails && articleId !== -1) {
    if (isRequest) {
      appConfig.DB!.getRequestArticleById(userStore.activeCatalog!, articleId, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
        .then((article) => {
          if (article) {
            onArticleClick(article)
          }
        })
    }
    else {
      const catalogDetails = userStore.linkedCatalogDetails[catalogCode] || userStore.activeCatalog
      appConfig.DB!.getMyArticlesById(catalogDetails, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, articleId, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
        .then(([article]) => onArticleClick(article))
    }
  }
}

function setObjectText() {
  if (selectedEditingObj.value) {
    selectedEditingObj.value?.setProp('text', { text: objectText.value })
  }
  textEditingDialogVisible.value = false
}

function onToolbarBtnClick(btn: IToolbarButton<MerchToolbarAction>) {
  if (btn.key === 'generate') {
    refGenerateFrameDialog.value?.showDialog()
  }
}

function onObjectAdded(obj: fabric.Object) {
  if (obj && wb.value) {
    if (obj.type === whiteboardConstants.objectTypes.sticky) {
      wb.value.canvas.setActiveObject(obj)
      handleDoubleClick()
    }
    else if (obj.type === whiteboardConstants.objectTypes.discussion) {
      wb.value.canvas.setActiveObject(obj)
    }
    else if (obj.type === whiteboardConstants.objectTypes.frame) {
      wb.value.sendObjectsToBack([obj])
    }
  }
}

function onOpenTextEditingDialog(obj: IWbObject) {
  if (obj) {
    const prop = obj.getProp('text')
    objectText.value = prop.text
    selectedEditingObj.value = obj
    textEditingDialogVisible.value = true
    nextTick(() => {
      objectTextRef.value?.focus()
    })
  }
}

function onCopyModel(articleOrModelImage: WbArticleImage | WbModelImage) {
  copyStyleSelectedObjectDetails = {
    top: articleOrModelImage.aCoords?.tr.y || 0,
    left: articleOrModelImage.aCoords?.tr.x || 0,
    type: articleOrModelImage.type,
  }
  const catalogDetails = userStore.linkedCatalogDetails[articleOrModelImage.catalogCode] || userStore.activeCatalog
  appConfig.DB!.getMyArticlesById(catalogDetails, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, articleOrModelImage.articleId, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
    .then(([article]) => {
      copyStyleContextArticle = article
      styleCreateAction = 'copyModel'
      isAddArticleDrawerVisible.value = true
    })
}
function closeUpdateArticles() {
  updateArticlesDrawerVisible.value = false
}
function onArticlesUpdated() {
  notificationStore.addNotification({ message: t('updateArticle.updateSuccessfully', selectedArticlesForEdit.length), type: 'Success' })
  closeUpdateArticles()
}
function showFav(articleImagesObjects: WbArticleImage[]) {
  const articlesId = articleImagesObjects.map(obj => obj.articleId)
  let selectedArticles: MyArticle[] = []
  appConfig.DB!.getMyArticles(userStore.activeCatalog!, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, articlesId, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet).then((articles) => {
    selectedArticles = articles
    favoritesDlg.value?.showDialog(selectedArticles)
  })
}
function onEditArticles(articleImagesObjects: WbArticleImage[]) {
  const articlesId = articleImagesObjects.map(obj => obj.articleId)
  selectedArticlesForEdit = []
  appConfig.DB!.getMyArticles(userStore.activeCatalog!, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, articlesId, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet).then((articles) => {
    updateArticlesDrawerVisible.value = true
    selectedArticlesForEdit = articles
  })
}
function onCopyArticles(articleImage: WbArticleImage) {
  if (userStore.activeCatalog) {
    copyStyleSelectedObjectDetails = {
      top: articleImage.aCoords?.tr.y || 0,
      left: articleImage.aCoords?.tr.x || 0,
      type: articleImage.type,
    }
    const catalogDetails = userStore.activeCatalog
    appConfig.DB!.getMyArticlesById(catalogDetails, {}, userStore.myAttributes!, userStore.currentUsername, articleImage.articleId, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
      .then(([article]) => {
        copyStyleContextArticle = article
        styleCreateAction = 'copyArticle'
        isAddArticleDrawerVisible.value = true
      })
  }
}
function onSaveImages(articleImagesObjects: WbArticleImage[], isWhiteboard: boolean = false) {
  const articlesId = articleImagesObjects.map(obj => obj.articleId)
  // selectedArticlesForEdit = []
  appConfig.DB!.getMyArticles(userStore.activeCatalog!, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, articlesId, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet).then((articles) => {
    // updateArticlesDrawerVisible.value = true
    // selectedArticlesForEdit = articles
    saveImageDlg.value?.showDialog(articles, isWhiteboard)
  })
}
function onArticleDetailsEditFav(article: MyArticle) {
  favoritesDlg.value?.showDialog([article])
}

async function onAddArticleDrawerClosed(articles: Array<MyArticle> | null | undefined) {
  if (articles == null) { // close without creating articles
    onAddArticleClosed()
  }
  else if (articles.length) { // click next on preview
    try {
      await addArticlesToWhiteboard(articles)
    }
    catch (error) {
      console.warn(error)
    }
    finally {
      onAddArticleClosed()
    }
  }
}

async function addArticlesToWhiteboard(articles: Array<MyArticle>) {
  let top = copyStyleSelectedObjectDetails!.top
  let left = copyStyleSelectedObjectDetails!.left
  const addedObjects: Array<WbArticleImage | WbModelImage | WbArticleDetails | WbModelDetails> = [] as Array<WbArticleImage | WbModelImage | WbArticleDetails | WbModelDetails>
  // in case of model only 1 model object should be added to canvas
  const articlesToAddToCanvas = copyStyleSelectedObjectDetails!.type === whiteboardConstants.objectTypes.articleImage ? articles.length : 1
  for (let i = 0; i < articlesToAddToCanvas; i++) {
    const article = articles[i]
    let newArticleObject
    let newArticleDetailsObject
    try {
      if (copyStyleSelectedObjectDetails!.type === whiteboardConstants.objectTypes.articleImage) {
        newArticleObject = await WbArticleImage.loadArticleImage(article, 500, 500, { top, left, catalogCode: article.CatalogCode, articleId: article.Id, objectId: article.CatalogArticleId, isRequest: article._IsRequestArticle })
        if (newArticleObject) {
          // add article details
          // TODO: need to check what is the need of 52 multiplier and setting height of details explicitly
          newArticleDetailsObject = await WbArticleDetails.loadArticleDetails(article, userStore.myAttributes, { left, top: top + newArticleObject.height - (52 * 1.35) })
          // set article details object height otherwise it will take random height and while selection unnecessary white space is selected
          newArticleDetailsObject.setProp('height', 52)
        }
      }
      else if (copyStyleSelectedObjectDetails!.type === whiteboardConstants.objectTypes.modelImage) {
        newArticleObject = await WbModelImage.loadModelImage(article, 500, 500, { top, left, catalogCode: article.CatalogCode, articleId: article.Id, modelNumber: article.ModelNumber })
        if (newArticleObject) {
          // add model details
          // TODO: need to check what is the need of 52 multiplier and setting height of details explicitly
          newArticleDetailsObject = await WbModelDetails.loadModelDetails(article, { left, top: top + newArticleObject.height - (52 * 1.35) })
          // set article details object height otherwise it will take random height and while selection unnecessary white space is selected
          newArticleDetailsObject.setProp('height', 52)
        }
      }
      if (newArticleObject && newArticleDetailsObject) {
        addedObjects.push(newArticleObject)
        addedObjects.push(newArticleDetailsObject)
        top += 100
        left += 100
      }
    }
    catch (error) {
      console.warn(`Unable to add article: ${article.ArticleNumber} \n`, error)
    }
  }
  wb.value!.canvas.discardActiveObject()
  if (addedObjects.length) {
    wb.value!.addObjects(addedObjects, true)
  }
  const selection = new fabric.ActiveSelection(addedObjects, { canvas: wb.value!.canvas })
  wb.value!.canvas.setActiveObject(selection)
}

function onAddArticleClosed() {
  isAddArticleDrawerVisible.value = false
  copyStyleContextArticle = undefined
  styleCreateAction = undefined
  copyStyleSelectedObjectDetails = undefined
}

function toggleFullScreen() {
  if (document.fullscreenElement) {
    document.exitFullscreen()
    isFullScreen.value = false
  }
  else {
    refCanvasContainer.value?.requestFullscreen()
      .then(() => {
        isFullScreen.value = true
        // isSidebarOpen.value = false
      })
      .catch((error) => {
        console.warn(`unable to go full screen, \n ${error}`)
        isFullScreen.value = false
      })
  }
}

function handleFullScreen() {
  isFullScreen.value = !!(document.fullscreenElement)
}

async function gotoRouteSelection() {
  if (!['WhiteboardDetails', 'WhiteboardDetailsArticleDetails'].includes(route.name!.toString())) { return }
  if (userStore.activeCatalog && route.params && route.params.articleId) {
    await openArticleDetails()
  }
  else if (articleDetailsOpen.value) {
    closeArticleDetails()
  }
}

function onWindowResize() {
  onResizeContent({ width: window.innerWidth - 80, height: window.innerHeight })
}

function onSelected(e: fabric.IEvent<MouseEvent>) {
  if (e.selected) {
    for (const obj of e.selected as Array<IWbObject>) {
      if (obj.selectable && !obj.lock) {
        if (obj instanceof WbFrame) {
          obj.resetObjMoving(false)
        }
      }
    }
  }
}

function updateCanvasPermissions(obj: Whiteboard) {
  if (userPermission.value === whiteboardConstants.userPermissions.write) {
    // setCanvasObjectPermission(obj, true, true)
  }
  else if (userPermission.value === whiteboardConstants.userPermissions.readAndComment) {
    setCanvasObjectPermission(obj, false, true, whiteboardConstants.objectTypes.discussion)
  }
  else if (userPermission.value === whiteboardConstants.userPermissions.read) {
    setCanvasObjectPermission(obj, false, false, whiteboardConstants.objectTypes.discussion)
  }
}

function setCanvasObjectPermission(obj: Whiteboard, selectable: boolean, evented: boolean, targetObjectType?: string) {
  const objects = obj.canvas.getObjects() as fabric.Object[]
  if (objects) {
    objects.forEach((canvasObject) => {
      if (targetObjectType && canvasObject.type === targetObjectType) { // enable comment to clickable
        canvasObject.selectable = true
        canvasObject.evented = true
      }
      else {
        canvasObject.selectable = selectable
        canvasObject.evented = evented
        canvasObject.hasControls = selectable
      }
    })
  }
  obj.canvas.discardActiveObject()
  obj.canvas.requestRenderAll()
}

watch(() => [route.params, route.query.t], gotoRouteSelection)

// prevent copying selected objects when copying model details properites
watch(() => articleDetailsOpen.value, (newValue) => {
  isArticleOrModelDetailsVisible.value = newValue
})

watch(() => whiteboardDetails.value, async (newDetails) => {
  if (newDetails) {
    // admin have full access
    if (newDetails.CreatedBy !== userStore.userProfile.Id || userStore.userProfile.AccountDetails.AccountTypeId === 1) {
      let isUserFound: boolean = false
      if (newDetails.SharedUsers.length) {
        const currentUser = newDetails.SharedUsers.find(user => user.UserId === userStore.userProfile.Id)
        if (currentUser) {
          isUserFound = true
          userPermission.value = currentUser.Write === 1
            ? whiteboardConstants.userPermissions.write as WhiteboardUserPermissionType
            : currentUser.Read === 1
              ? whiteboardConstants.userPermissions.read as WhiteboardUserPermissionType
              : currentUser.ReadAndComment === 1
                ? whiteboardConstants.userPermissions.readAndComment as WhiteboardUserPermissionType
                : whiteboardConstants.userPermissions.read as WhiteboardUserPermissionType // default
        }
      }

      if (!isUserFound && newDetails.SharedUsersGroups.length) {
        for (const group of newDetails.SharedUsersGroups) {
          const groupPermission = group.Write === 1
            ? whiteboardConstants.userPermissions.write as WhiteboardUserPermissionType
            : group.Read === 1
              ? whiteboardConstants.userPermissions.read as WhiteboardUserPermissionType
              : group.ReadAndComment === 1
                ? whiteboardConstants.userPermissions.readAndComment as WhiteboardUserPermissionType
                : whiteboardConstants.userPermissions.read as WhiteboardUserPermissionType // default
          if (groupPermission !== whiteboardConstants.userPermissions.write) {
            const groupDetails = await getAccessibleUserGroupDetails(group.GroupId)
            const users = groupDetails.data.Users
            if (users.length && users.some(user => user.UserId === userStore.userProfile.Id)) {
              userPermission.value = groupPermission
              break
            }
          }
        }
      }
    }
    else {
      userPermission.value = whiteboardConstants.userPermissions.write as WhiteboardUserPermissionType
    }

    if (userPermission.value !== whiteboardConstants.userPermissions.write) {
      isDisableCopyPasteUndoRedo.value = true
    }
  }
}, { immediate: true })

onMounted(async () => {
  addEventListener('resize', onWindowResize)
  if (userStore.activeCatalog) {
    await getWhiteboardDetails(userStore.activeCatalog.CatalogCode, Number(route.params.whiteboardId))
      .then((resp) => {
        whiteboardDetails.value = resp.data
      })
      .catch((error) => {
        console.error('Unable to get whiteboard details.', error)
        const message = 'Unable to open whiteboard, please try again later or contact support'
        let details = ''
        if (error.response && error.response.data && error.response.data.length) {
          details = error.response.data.map(x => x.ErrorMessage).join(', ')
        }
        notificationStore.addNotification({ message, details, type: 'Alert' })
        router.push({ name: 'Whiteboards' })
      })
  }
  if (whiteboardDetails.value && refCanvasContainer.value) {
    wb.value = new Whiteboard(whiteboardId.value, 'c', refCanvasContainer.value?.clientWidth, refCanvasContainer.value?.clientHeight, whiteboardDetails.value, route.query.moveToObject?.toString())

    wb.value.on('zoom', (e) => {
      zoom.value = Math.round(e.factor * 100)
    })

    wb.value.on('add-dynamic-grid', (e) => {
      objectPropertiesRef.value?.openDynamicPropertiesDialog(undefined, e.pos)
    })

    wb.value.on('canvas-objects-loaded', (e: ICanvasObjectsLoadedEvent) => {
      const wbContent = e.wbDetails
      updateCanvasPermissions(wbContent)
      const showComments = userStore.activeCatalog && userStore.userPreferences?.whiteboard?.settings[userStore.activeCatalog.CatalogCode] ? userStore.userPreferences.whiteboard.settings[userStore.activeCatalog.CatalogCode].showComments : true
      settingsChanged(showComments)
    })

    wb.value.canvas.on('mouse:dblclick', handleDoubleClick)
    wb.value.canvas.on('selection:created', onSelected)
    wb.value.canvas.on('selection:updated', onSelected)

    wb.value.on('canvas-objects-loaded', (e: ICanvasObjectsLoadedEvent) => {
      const wbContent = e.wbDetails
      updateCanvasPermissions(wbContent)
    })
  }
  document.addEventListener('fullscreenchange', handleFullScreen, false)
  gotoRouteSelection()
})

onUnmounted(() => {
  removeEventListener('resize', onWindowResize)
  if (dbDoc.value) { dbDoc.value.destroy() }
  if (userDoc.value) { userDoc.value.destroy() }
  if (wb.value && wb.value.canvas) {
    wb.value.canvas.off()
    wb.value.canvas.clear()
  }
  document.removeEventListener('fullscreenchange', handleFullScreen)
})
</script>
