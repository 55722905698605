<template>
  <tx-panel v-model="panelVisible" :title="t('findAndReplaceDialog.title')" @close="doClose">
    <div class="flex w-full p-4">
      <!-- Inputs -->
      <div class="w-full mr-1">
        <tx-input v-model="form.findWhat" class="my-1" :label="t('findAndReplaceDialog.findWhat')" clearable />
        <tx-input v-model="form.replaceWith" class="my-1" :label="t('findAndReplaceDialog.replaceWith')" clearable />
        <!-- Match Objects -->
        <div class="w-64 h-32 p-1 overflow-y-auto border rounded">
          <p v-if="!matchObjects.length">
            {{ t('general.emptyList') }}
          </p>
          <ul v-else tabindex="-1" role="listbox" class="w-full leading-8">
            <li
              v-for="(obj, index) in matchObjects" :key="obj.id" tabindex="0"
              :class="{ 'bg-primary text-on-primary': selectedMatchObjectIndex === index }" class="relative flex items-center h-8 pl-1 cursor-pointer hover:bg-primary hover:text-on-primary"
              @click="onMatchObjectSelected(index)"
            >
              <div class="text-base truncate border-b grow border-grey">
                <span>{{ obj.type.substring(2) }}</span>
                <span> -> </span>
                <span>{{ obj.getProp('text').text }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- Actions -->
      <div class="flex flex-col w-32 p-1 ml-1 border rounded">
        <tx-button class="my-1" type="default" :text="t('findAndReplaceDialog.find')" height="30px" @click="doFind" />
        <tx-button class="my-1" type="default" :text="t('findAndReplaceDialog.findNext')" :disabled="!matchObjects.length" height="30px" @click="doFindNext" />
        <tx-button class="my-1" type="default" :text="t('findAndReplaceDialog.replace')" :disabled="!matchObjects.length || disabledReplaceBtn" height="30px" @click="doReplace" />
        <tx-button class="my-1" type="default" :text="t('findAndReplaceDialog.replaceAll')" :disabled="!matchObjects.length" height="30px" @click="doReplaceAll" />
      </div>
    </div>
  </tx-panel>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, reactive, ref, watch } from 'vue'
import WbTextBox from '../services/textBox'
import WbArticleDetails from '../services/articleDetails'
import WbSticky from '../services/sticky'
import type Whiteboard from '../services/whiteboard'
import WbModelDetails from '../services/modelDetails'
import TxPanel from '@/shared/components/TxPanel.vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxButton from '@/shared/components/TxButton.vue'
import { whiteboardConstants } from '@/models/constants'
import utils from '@/services/utils'

interface IProps {
  modelValue: boolean
  whiteboard?: Whiteboard
  loading?: boolean
}
const props = withDefaults(defineProps<IProps>(), { loading: false })

const emit = defineEmits<{
  (e: 'update:modelValue', val: boolean): void
}>()

const panelVisible = ref(false)
const form = reactive<{ findWhat: string, replaceWith: string }>({
  findWhat: '',
  replaceWith: '',
})
const matchObjects = ref<IWbObject[]>([])
const selectedMatchObjectIndex = ref<number>(0)
const selectedMatchObjectTextStartIndex = ref<number>(0)

const { t } = useI18n()

const disabledReplaceBtn = computed(() => selectedMatchObjectIndex.value >= matchObjects.value.length
  || matchObjects.value[selectedMatchObjectIndex.value].type === whiteboardConstants.objectTypes.articleDetails
  || matchObjects.value[selectedMatchObjectIndex.value].type === whiteboardConstants.objectTypes.modelDetails,
)

function doClose() {
  resetMatchObjects()
  emit('update:modelValue', false)
}

function doFind() {
  resetMatchObjects()
  if (props.whiteboard && utils.isDefined(form.findWhat) && utils.isValidStringValue(form.findWhat)) {
    const objs = props.whiteboard.canvas.getObjects() as Array<IWbObject>
    for (let i = 0; i < objs.length; i++) {
      if (objs[i].type === whiteboardConstants.objectTypes.textBox || objs[i].type === whiteboardConstants.objectTypes.articleDetails
        || objs[i].type === whiteboardConstants.objectTypes.modelDetails || objs[i].type === whiteboardConstants.objectTypes.sticky) {
        const content: string | undefined = objs[i].getProp('text').text
        if (utils.isDefined(content) && content.toLowerCase().includes(form.findWhat.toLowerCase())) {
          matchObjects.value.push(objs[i])
        }
      }
    }
    doFindNext()
  }
}

function onMatchObjectSelected(index: number) {
  resetSelectionStyles(matchObjects.value[selectedMatchObjectIndex.value])
  selectedMatchObjectTextStartIndex.value = 0
  selectedMatchObjectIndex.value = index
  doFindNext()
}

function doFindNext() {
  while (selectedMatchObjectIndex.value < matchObjects.value.length) {
    const obj = matchObjects.value[selectedMatchObjectIndex.value]
    const text: string = obj.getProp('text').text
    let found = false
    let i = selectedMatchObjectTextStartIndex.value
    for (; i < text.length; ++i) {
      if (text.substring(i, i + form.findWhat.length).toLowerCase() === form.findWhat.toLowerCase()) {
        found = true
        selectedMatchObjectTextStartIndex.value = i
        break
      }
    }

    resetSelectionStyles(obj)
    if (found && props.whiteboard) {
      const start = selectedMatchObjectTextStartIndex.value
      const end = start + form.findWhat.length
      if (obj instanceof WbTextBox || obj instanceof WbArticleDetails || obj instanceof WbModelDetails) {
        obj.setSelectionStyles({ fill: '#2196f3', fontStyle: 'italic' }, start, end)
      }
      selectedMatchObjectTextStartIndex.value++
      props.whiteboard.canvas.discardActiveObject()
      const zoom = props.whiteboard.canvas.getZoom()
      const vpt = props.whiteboard.canvas.viewportTransform
      if (vpt) {
        const objWidth = (obj.width || 0) * (obj.scaleX || 0)
        const objHeight = (obj.width || 0) * (obj.scaleY || 0)
        const objCenter = {
          x: (obj.left || 0) + (objWidth / 2),
          y: (obj.top || 0) + (objHeight / 2),
        }
        const canvasCenter = {
          x: props.whiteboard.canvas.getWidth() / 2,
          y: props.whiteboard.canvas.getHeight() / 2,
        }
        // Calculate new translation values to center the object
        vpt[4] = canvasCenter.x - objCenter.x * zoom
        vpt[5] = canvasCenter.y - objCenter.y * zoom
        props.whiteboard.canvas.setViewportTransform(vpt)
        props.whiteboard.canvas.setActiveObject(obj)
        props.whiteboard.canvas.requestRenderAll()
        break
      }
    }

    selectedMatchObjectTextStartIndex.value = 0
    selectedMatchObjectIndex.value++
    if (selectedMatchObjectIndex.value >= matchObjects.value.length) {
      resetSelectionStyles(obj)
    }
    props.whiteboard?.canvas.requestRenderAll()
  }
}

function replaceObjText(obj: IWbObject, replaceAll: boolean) {
  const text: string = obj.getProp('text').text
  const regEx = new RegExp(form.findWhat, 'gi')
  if (obj instanceof WbTextBox) {
    if (replaceAll) {
      const modifiedText = text.replace(regEx, form.replaceWith)
      obj.setProp('text', { text: modifiedText })
    }
    else {
      const index = selectedMatchObjectTextStartIndex.value - 1
      const pos = text.substring(index).toLowerCase().indexOf(form.findWhat.toLowerCase())
      if (pos >= 0) {
        const modifiedText = form.replaceWith + text.substring(index).substring(pos + form.findWhat.length)
        obj.setProp('text', { text: text.substring(0, index) + modifiedText })
      }
    }
  }
  else if (obj instanceof WbSticky) {
    const modifiedText = text.replace(regEx, form.replaceWith)
    obj.setProp('text', { text: modifiedText })
  }
}

function doReplace() {
  const obj = matchObjects.value[selectedMatchObjectIndex.value]
  if (utils.isDefined(obj)) {
    replaceObjText(obj, false)
    resetSelectionStyles(obj)
    doFindNext()
  }
}

function doReplaceAll() {
  if (matchObjects.value.length) {
    matchObjects.value.forEach((obj) => {
      replaceObjText(obj, true)
      resetSelectionStyles(obj)
    })
    resetMatchObjects()
  }
}

function resetSelectionStyles(obj: IWbObject) {
  if (obj instanceof WbTextBox || obj instanceof WbArticleDetails || obj instanceof WbModelDetails) {
    obj.setSelectionStyles({ fill: obj.fill, fontStyle: obj.fontStyle }, 0, obj.text?.length)
  }
}

function resetMatchObjects() {
  matchObjects.value.forEach(obj => resetSelectionStyles(obj))
  selectedMatchObjectTextStartIndex.value = 0
  selectedMatchObjectIndex.value = 0
  props.whiteboard?.canvas.discardActiveObject()
  props.whiteboard?.canvas.requestRenderAll()
  matchObjects.value = []
}

watch(() => form.findWhat, resetMatchObjects)

watch(() => props.modelValue, () => {
  if (props.modelValue) {
    form.findWhat = ''
    form.replaceWith = ''
    resetMatchObjects()
  }
  panelVisible.value = props.modelValue
})
</script>
