import { fabric } from 'fabric'
import { v4 as guid } from 'uuid'
import type { Subscription } from 'dexie'
import { liveQuery } from 'dexie'
import { isEmpty, pick } from 'lodash-es'
import type MyArticle from '@/models/myArticle'
import { useUserStore } from '@/store/userData'
import utils, { CancelToken } from '@/services/utils'
import appConfig from '@/services/appConfig'
import { whiteboardConstants } from '@/models/constants'
import type CatalogDetails from '@/models/catalogDetails'

interface IWbArticleDetailsOptions extends fabric.ITextboxOptions, IWbObjectProps {
  id?: string
  catalogCode?: number
  articleId?: number
  objectId?: string
  isRequest?: boolean
  showLabels?: boolean
  lock?: boolean
  preventUnlock?: boolean
  attributes?: string[]
  attributesSeparator?: string
  attributePlaceHolder?: string
  localOnly?: boolean
  preventDelete?: boolean
  excludeFromGroupSelection?: boolean
}

// eslint-disable-next-line ts/ban-ts-comment
// @ts-expect-error
export default class WbArticleDetails extends fabric.Textbox implements IWbObject {
  public id: string
  public article?: MyArticle
  public catalogCode?: number
  public articleId?: number
  public objectId?: string
  public isRequest?: boolean
  public showLabels: boolean
  public attributes?: string[]
  public attributesSeparator?: string
  public attributePlaceHolder?: string
  public type = whiteboardConstants.objectTypes.articleDetails
  public lock: boolean
  public preventUnlock: boolean
  public connectable: boolean
  private isLoadingFirstTime: boolean = true
  public localOnly: boolean
  public preventDelete?: boolean
  public excludeFromGroupSelection?: boolean
  public editableProps: Record<string, IWbObjectProp> = {
    backgroundColor: { name: 'background color', type: 'backgroundColor', editable: true },
    textColor: { name: 'text color', type: 'textColor', editable: true },
    font: { name: 'font', type: 'font', editable: true },
    fontSize: { name: 'font size', type: 'fontSize', editable: true },
    textAlign: { name: 'align', type: 'textAlign', editable: true },
    fontStyle: { name: 'font style', type: 'fontStyle', editable: true },
    lock: { name: 'Lock', type: 'lock', editableWhenLocked: true, editable: true },
    showLabels: { name: 'Show labels', type: 'showLabels', editable: true },
    attributes: { name: 'attributes', type: 'attributes', editable: true },
    addDiscussion: { name: 'add comment', type: 'addDiscussion', editableWhenLocked: true, editable: true },
  }

  public actions: Record<string, IWObjectActions> = {
    selectSimilar: { action: 'selectSimilar', label: 'Select Similar', faicon: 'fa-light fa-check-double', showInSubMenu: true },
    bringFront: { action: 'bringFront', label: 'Bring to Front', faicon: 'fa-light fa-bring-front', showInSubMenu: true },
    sendBack: { action: 'sendBack', label: 'Send to Back', faicon: 'fa-light fa-send-back', showInSubMenu: true },
    group: { action: 'group', label: 'Group', faicon: 'fa-light fa-object-group', showInSubMenu: true, multiple: true },
    delete: { action: 'delete', label: 'Remove', faicon: 'fa-light fa-trash-can', showInSubMenu: true },
    cloneAsImage: { action: 'cloneAsImage', label: 'Clone As Image', faicon: 'fa-light fa-clone', showInSubMenu: true },
  }

  public subscriptions: Array<Subscription>

  constructor(text: string, opt?: IWbArticleDetailsOptions, isLive: boolean = true) {
    super(text, opt)
    this.id = opt?.id || guid()
    this.catalogCode = opt?.catalogCode
    this.articleId = opt?.articleId
    this.objectId = opt?.objectId
    this.isRequest = opt?.isRequest
    this.showLabels = utils.isDefined(opt) && utils.isDefined(opt.showLabels) ? opt.showLabels : true
    this.attributes = opt?.attributes?.length ? opt.attributes : ['ArticleNumber', 'ModelName']
    this.attributesSeparator = opt?.attributesSeparator || '\n'
    this.attributePlaceHolder = opt?.attributePlaceHolder || ''
    this.fontFamily = opt?.fontFamily || 'Helvetica'
    this.fontSize = opt?.fontSize || 14
    this.textAlign = opt?.textAlign || 'center'
    this.lock = opt?.lock || false
    this.localOnly = opt?.localOnly || false
    this.editable = false
    this.connectable = true
    this.subscriptions = []
    this.preventUnlock = opt?.preventUnlock || false
    this.preventDelete = opt?.preventDelete || false
    this.excludeFromGroupSelection = opt?.excludeFromGroupSelection || false

    if (isLive) {
      const userStore = useUserStore()
      const catalogDetails = this.catalogCode ? (userStore.linkedCatalogDetails[this.catalogCode] || userStore.activeCatalog) : userStore.activeCatalog!
      if (this.isRequest && this.articleId) {
        const observable = liveQuery(async () => await appConfig.DB!.getRequestArticleById(catalogDetails, this.articleId!, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet))
        const subscription = observable.subscribe(async (article) => {
          // As discussed with Andre we dont need to set article again when we first time create the object as already we are loading the article
          if (!this.isLoadingFirstTime && article) {
            this.article = article
            this.set('text', await WbArticleDetails.getText(article, this.showLabels, this.attributes, this.attributesSeparator, this.attributePlaceHolder, userStore.myAttributes))
            this.dirty = true
            this.canvas?.requestRenderAll()
            this.canvas?.fire('object:modified', { target: this, ignoreHistory: true })
          }
          else {
            this.isLoadingFirstTime = false
          }
        })
        this.subscriptions.push(subscription)
      }
      else {
        const observable = liveQuery(async () => await appConfig.DB!.articles.where({ CatalogCode: catalogDetails.CatalogCode, Id: this.articleId }).toArray())
        const subscription = observable.subscribe(async ([article]) => {
          // As discussed with Andre we dont need to set article again when we first time create the object as already we are loading the article
          if (!this.isLoadingFirstTime) {
            const articles = await appConfig.DB!.buildMyArticles([article], catalogDetails, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, true)
            this.article = articles[0]
            this.set('text', await WbArticleDetails.getText(this.article, this.showLabels, this.attributes, this.attributesSeparator, this.attributePlaceHolder, userStore.myAttributes))
            this.dirty = true
            this.canvas?.requestRenderAll()
            this.canvas?.fire('object:modified', { target: this, ignoreHistory: true })
          }
          else {
            this.isLoadingFirstTime = false
          }
        })
        this.subscriptions.push(subscription)
      }
    }

    this.set('text', text) // super(text, options) is not setting the text property with the text value rather it display the static value received from server

    this.on('removed', () => {
      // console.log('unsubscribing from article details observables')
      this.subscriptions.forEach((subscription) => {
        if (subscription && !subscription.closed) {
          subscription.unsubscribe()
        }
      })
    })
    this.setLock(this.lock)

    this.on('selected', () => {
      this.selected = true
    })

    this.on('deselected', () => {
      this.selected = false
    })
    this.stateProperties = this.stateProperties?.concat(['showLabels', 'lock', 'attributes'])
  }

  async setProp(prop: string, value: any, ignoreHistory: boolean = false) {
    const userStore = useUserStore()
    const catalogDetails = userStore.linkedCatalogDetails[this.catalogCode!] || userStore.activeCatalog
    switch (prop) {
      case 'top':
        this.set('top', value.top)
        break
      case 'left':
        this.set('left', value.left)
        break
      case 'textColor':
        this.set('fill', value.textColor)
        break
      case 'font':
        this.set('fontFamily', value.font)
        break
      case 'fontSize':
        this.set('fontSize', value.fontSize)
        break
      case 'textAlign':
        this.set('textAlign', value.textAlign)
        break
      case 'bold':
        this.set('fontWeight', value.bold ? 'bold' : '')
        break
      case 'italic':
        this.set('fontStyle', value.italic ? 'italic' : '')
        break
      case 'underline':
        this.set('underline', value.underline)
        break
      case 'lock':
        this.set('lock', value.lock)
        this.setLock(value.lock)
        break
      case 'showLabels':
        this.set('showLabels', value.showLabels)
        this.set('text', await this.getAttributesValue(catalogDetails, this.articleId, this.objectId, this.isRequest, this.attributes, value.showLabels))
        break
      case 'attributes':
        this.set('attributes', value.attributes)
        this.set('text', await this.getAttributesValue(catalogDetails, this.articleId, this.objectId, this.isRequest, value.attributes, this.showLabels))
        break
      case 'text':
        // value in case of set text value should be fetch from article
        this.set('text', await this.getAttributesValue(catalogDetails, this.articleId, this.objectId, this.isRequest, this.attributes, this.showLabels))
        break
      case 'height':
        this.set('height', value)
        break
      case 'backgroundColor':
        this.set('backgroundColor', value.backgroundColor)
        break
      default:
        console.warn('Attempting to set unsupported WbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this, ignoreHistory })
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'textColor':
        result.textColor = this.fill
        break
      case 'font':
        result.font = this.fontFamily
        break
      case 'fontSize':
        result.fontSize = this.fontSize
        break
      case 'textAlign':
        result.textAlign = this.textAlign
        break
      case 'bold':
        result.bold = !!this.fontWeight
        break
      case 'italic':
        result.italic = !!this.fontStyle
        break
      case 'underline':
        result.underline = !!this.underline
        break
      case 'lock':
        result.lock = this.lock
        break
      case 'showLabels':
        result.showLabels = this.showLabels
        break
      case 'attributes':
        result.attributes = this.attributes
        break
      case 'text':
        result.text = this.text
        break
      case 'backgroundColor':
        result.backgroundColor = this.backgroundColor
        break
      default:
        console.warn('Attempting to get unsupported WbObjectProp', prop)
    }
    return result
  }

  setLock(lock: boolean) {
    this.set('lockMovementX', lock)
    this.set('lockMovementY', lock)
    this.set('lockRotation', lock)
    this.set('lockScalingFlip', lock)
    this.set('lockScalingX', lock)
    this.set('lockScalingY', lock)
    this.set('hasControls', !lock)
  }

  override toObject() {
    const propsToPluck = [
      'id',
      'type',
      'top',
      'left',
      'angle',
      'width',
      'height',
      'scaleX',
      'scaleY',
      'fill',
      'backgroundColor',
      'fontFamily',
      'fontSize',
      'fontStyle',
      'fontWeight',
      'underline',
      'textAlign',
      'lock',
      'showLabels',
      'articleId',
      'objectId',
      'catalogCode',
      'isRequest',
      'attributes',
      'attributesSeparator',
      'attributePlaceHolder',
      'localOnly',
      'preventUnlock',
    ]
    const returnObject = pick(this, propsToPluck)
    return returnObject
  }

  static fromObject(object: WbArticleDetails, callback?: Function) {
    // console.log('loading articleDetails fromObject', object)
    // handle existing objects
    if (!object.catalogCode) { object.catalogCode = -1 }
    if (object.catalogCode && object.articleId) {
      this.loadArticleDetailsById(object.catalogCode, object.articleId, object.objectId, object.isRequest, object).then((v) => { callback && callback(v) })
    }
  }

  static async getText(article: MyArticle, showLabels: boolean, attributes?: string[], attributesSeparator?: string, attributePlaceHolder?: string, myAttributes?: Record<string, IMyAttribute>) {
    const attributeData: any[] = []
    const userStore = useUserStore()
    if (attributes && attributes.length && utils.isDefined(myAttributes)) {
      for (const attributeSystemName of attributes) {
        if (utils.isDefined(myAttributes[attributeSystemName])) {
          let val = await utils.getAttributeTypeSpecificValue(myAttributes[attributeSystemName], article, appConfig.DB, userStore.activeCatalog, userStore.priceGroups, false, true)
          if (!utils.isValidStringValue(val)) {
            val = attributePlaceHolder
          }
          let label = ''
          const valEmpty = isEmpty(val)
          if ((showLabels || valEmpty) && userStore.activeCatalog) {
            const whiteboardLabelAttributeConfigValue = userStore.activeCatalog.Config.WhiteboardLabelAttributes
            if (whiteboardLabelAttributeConfigValue === undefined
              || (utils.isDefined(whiteboardLabelAttributeConfigValue) && Array.isArray(whiteboardLabelAttributeConfigValue) && whiteboardLabelAttributeConfigValue!.includes(myAttributes[attributeSystemName].SystemName))) {
              label = myAttributes[attributeSystemName].DisplayName
            }
          }
          attributeData.push(showLabels && label !== '' ? `${label}: ${val}` : valEmpty && label !== '' ? `[${label}]` : val)
        }
      }
    }
    return attributeData.join(attributesSeparator)
  }

  static async loadArticleDetails(article: MyArticle, myAttributes?: Record<string, IMyAttribute>, opt?: IWbArticleDetailsOptions, isLive: boolean = true) {
    const o = opt || { articleId: article.Id, objectId: article.CatalogArticleId, isRequest: article._IsRequestArticle }
    o.catalogCode = article.CatalogCode
    o.articleId = article.Id
    o.objectId = article.CatalogArticleId
    o.isRequest = article._IsRequestArticle
    o.showLabels = utils.isDefined(opt) && utils.isDefined(opt.showLabels) ? opt.showLabels : true
    o.attributes = opt?.attributes?.length ? opt.attributes : ['ArticleNumber', 'ArticleName']
    o.attributesSeparator = opt?.attributesSeparator || '\n'
    o.attributePlaceHolder = opt?.attributePlaceHolder || ''
    const obj = new WbArticleDetails(await this.getText(article, o.showLabels, o.attributes, o.attributesSeparator, o.attributePlaceHolder, myAttributes), o, isLive)
    obj.article = article
    return obj
  }

  static async loadArticleDetailsById(catalogCode: number, articleId: number, objectId?: string, isRequest?: boolean, opt?: IWbArticleDetailsOptions) {
    const userStore = useUserStore()
    if (userStore && userStore.activeCatalog) {
      let catalogDetails = userStore.activeCatalog
      if (userStore.linkedCatalogDetails[catalogCode]) {
        catalogDetails = userStore.linkedCatalogDetails[catalogCode]
      }
      let res: MyArticle[] | null = null
      if (isRequest) {
        const requestArticle = await appConfig.DB!.getRequestArticleById(catalogDetails, articleId, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
        if (requestArticle) {
          res = [requestArticle]
        }
      }
      else {
        res = await appConfig.DB!.getMyArticles(catalogDetails, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, [articleId], userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, true)
      }
      if (res && !(res instanceof CancelToken) && res.length > 0) {
        return await this.loadArticleDetails(res[0], userStore.myAttributes, opt)
      }
      else if (res && res.length === 0 && Object.keys(userStore.linkedCatalogDetails).length > 0) {
        for (const linkedCatalogCode in userStore.linkedCatalogDetails) {
          catalogDetails = userStore.linkedCatalogDetails[linkedCatalogCode]
          res = await appConfig.DB!.getMyArticles(catalogDetails, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, [articleId], userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, true)
          if (res && !(res instanceof CancelToken) && res.length > 0) {
            return await this.loadArticleDetails(res[0], userStore.myAttributes, opt)
          }
        }
      }
      else {
        console.warn('Failed to load article details by Id', res)
      }
    }
    console.warn('Unable to load Article Details by Id')
    return null
  }

  private async getAttributesValue(catalogDetails: CatalogDetails, articleId?: number, objectId?: string, isRequest?: boolean, attributes?: string[], showLabels: boolean = false) {
    const userStore = useUserStore()
    let article: MyArticle | undefined
    if (articleId != null) {
      try {
        if (isRequest) {
          article = await appConfig.DB!.getRequestArticleById(catalogDetails, articleId, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
        }
        else {
          const articles = await appConfig.DB!.getMyArticles(catalogDetails, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, [articleId], userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, true)
          article = articles[0]
        }
      }
      catch (error) {
        console.warn(error)
        article = this.article
      }
    }
    else if (this.article) {
      article = this.article
    }
    return await WbArticleDetails.getText(article!, showLabels, attributes, '\n', '', userStore.myAttributes)
  }

  override _render(ctx: CanvasRenderingContext2D): void {
    super._render(ctx)
    if (this.selected && this.article) {
      ctx.font = `${this.fontSize}` + 'px Helvetica'
      // let seasonData = userStore.activeCatalog?.CatalogCode === this.article.CatalogCode
      // ? userStore.activeCatalog.Season
      // : userStore.linkedCatalogDetails[this.article.CatalogCode].Season
      // const seasonDataMeasure = ctx.measureText(seasonData)
      ctx.fillStyle = '#1484f8'
      // ctx.fillRect(this.width!/2 - seasonDataMeasure.width, -this.height!/2 , seasonDataMeasure.width, 15)
      ctx.fillStyle = '#FFFFFF'
      // ctx.fillText(seasonData, this.width!/2 - seasonDataMeasure.width, -this.height! / 2 + 12)
    }
  }
}

const f: any = fabric
f.WbArticleDetails = WbArticleDetails
