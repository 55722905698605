<template>
  <div class="relative w-full h-full">
    <loader v-if="pageLoading" />
    <div v-show="!pageLoading" class="flex flex-col h-full px-2">
      <!-- BACK BUTTON -->
      <tx-button
        v-if="previousViewsStack.length > 0 && currentView !== viewsEnum.articlePreview" type="plainIcon"
        class="absolute z-10 w-6 h-6 top-7 left-7" faicon="fa-light fa-chevron-left" @click="onBack"
      />

      <!-- HEADER -->
      <div class="flex-grow-0 flex-shrink-0 mt-16 mb-8 text-2xl font-semibold text-center text-gray-600 capitalize">
        {{ headerTitle }}
      </div>

      <!-- FORM CONTAINER -->
      <div class="flex-grow overflow-hidden flex-shrink-1">
        <loader v-if="formLoading" class="mr-40" />

        <tx-alert :show="utils.isValidStringValue(articleFailedAlertMessage)" type="error" :text="articleFailedAlertMessage" />
        <tx-alert :show="utils.isValidStringValue(carryoverArticleFailedAlertMessage)" type="error" :text="carryoverArticleFailedAlertMessage" />

        <type-selection
          v-show="currentView === viewsEnum.addNew"
          v-model="typeSelectionModel"
          :visible="currentView === viewsEnum.addNew"
          @next="onNext"
          @cancel="closeAddArticleDrawer"
        />

        <model-form
          v-show="currentView === viewsEnum.modelForm"
          :create-type="createType"
          :form-layout-config="modelFormLayoutConfig"
          :show-only-seasonal-attributes="modelFormShowOnlySeasonalAttributes"
          :targeted-articles="targetedArticles"
          :visible="currentView === viewsEnum.modelForm"
          :visible-attributes="modelFormVisibleAttributes"
          :is-add-request="isAddRequest"
          :request-articles="requestArticles"
          :link-catalog-indexed-by-price-name-to-id="linkCatalogIndexedByPriceNameToId"
          @cancel="closeAddArticleDrawer"
          @next="onNext"
        />

        <carryover-models-form
          v-show="currentView === viewsEnum.carryoverModelsForm"
          :create-type="createType"
          :form-layout-config="modelFormLayoutConfig"
          :show-only-seasonal-attributes="modelFormShowOnlySeasonalAttributes"
          :targeted-articles="targetedArticles"
          :visible="currentView === viewsEnum.carryoverModelsForm"
          :visible-attributes="modelFormVisibleAttributes"
          :carryover-model-list="carryoverModelExistInActiveCatalog"
          @cancel="closeAddArticleDrawer"
          @next="onNext"
        />

        <model-articles-and-periods-form
          v-show="currentView === viewsEnum.modelArticlesAndPeriodsForm"
          :create-type="createType"
          :context-article="contextArticle"
          :model-form-model="modelFormModel"
          :retail-window-criteria-attribute-value="retailWindowCriteriaAttributeValue"
          :selected-articles="selectedArticles"
          :sorted-shipment-window-range="sortedActiveShipmentWindowRange"
          :visible="currentView === viewsEnum.modelArticlesAndPeriodsForm"
          :request-articles="requestArticles"
          @cancel="closeAddArticleDrawer"
          @next="onNext"
        />

        <article-form
          v-show="currentView === viewsEnum.articleForm"
          :create-type="createType"
          :articles-to-carryover="modelArticlesAndPeriodsFormModel.modelArticlesListModel"
          :model-form-model="modelFormModel"
          :periods-form-model="modelArticlesAndPeriodsFormModel.periodsFormModel"
          :retail-window-criteria-attribute-value="retailWindowCriteriaAttributeValue"
          :sorted-shipment-window-range="sortedActiveShipmentWindowRange"
          :targeted-articles="targetedArticles"
          :visible="currentView === viewsEnum.articleForm"
          :visible-attributes="articleFormVisibleAttributes"
          :request-attributes="requestAttributesFormVisibleAttributes"
          :model-copies="modelCopies"
          :is-add-request="isAddRequest"
          :is-confirm-request="isConfirmRequest"
          :request-articles="requestArticles"
          @cancel="closeAddArticleDrawer"
          @next="onNext"
        />

        <article-review-form
          v-if="currentView === viewsEnum.articlePreview"
          :articles="previewArticles"
          :show-next-button="false"
          :model-copies="modelCopies"
          :model-copies-created="modelCopiesCreated"
          :show-progress="showCreationProgress"
          @next="onNext"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { clone, uniqBy } from 'lodash-es'
import { computed, reactive, ref, watch, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { v4 as guid } from 'uuid'
import ArticleForm from './ArticleForm.vue'
import ArticleReviewForm from './ArticleReviewForm.vue'
import ModelArticlesAndPeriodsForm from './ModelArticlesAndPeriodsForm.vue'
import ModelForm from './ModelForm.vue'
import CarryoverModelsForm from './CarryoverModelsForm.vue'
import TypeSelection from './TypeSelection.vue'
import type Article from '@/models/article'
import type CatalogShipmentWindowRange from '@/models/catalogShipmentWindowRange'
import Loader from '@/shared/components/Loader.vue'
import MyArticle from '@/models/myArticle'
import TxButton from '@/shared/components/TxButton.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import utils from '@/services/utils'
import { AttributeType } from '@/models/catalogAttribute'
import useArticleLocalDataUpdater from '@/modules/articleDetails/composables/articleLocalDataUpdater'
import { appConstants, requestConstants } from '@/models/constants'
import { articlesBgJob, assignArticle, createArticle } from '@/api/t1/article'
import type { CreateRequestModel } from '@/api/t1/model/requestModel'
import { createRequestBulk } from '@/api/t1/request'
import { useArticleFormHelper } from '@/shared/composables/articleFormHelper'
import { useUserStore } from '@/store/userData'
import Job from '@/models/job'
import appConfig from '@/services/appConfig'
import { useNotificationStore } from '@/store/notification'
import type { Entity } from '@/services/db'

interface IProps {
  actionType?: StyleCreateActionType
  contextArticle?: MyArticle
  externalData?: Record<string, { ph: number, articleIds: Set<number> }>
  externalDataUpdated?: boolean
  selectedArticles?: MyArticle[]
  requestArticles?: MyArticle[]
  visible?: boolean
  isAddRequest?: boolean
  isConfirmRequest?: boolean
  externalLinkedCatalogArticles?: Record<number, MyArticle>
  linkCatalogIndexedByPriceNameToId?: Record<number, Record<string, number>>
}

const props = withDefaults(defineProps<IProps>(), { visible: false, isAddRequest: false })

const emit = defineEmits<{
  (e: 'close', previewArticles: MyArticle[] | null | undefined): void
  (e: 'showModelDetails'): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { doesModelBelongsToActiveCatalog, getArticlesMaxStateDetails, getArticlesMaxStateRank, normalizeAttributeValue } = useArticleFormHelper()
const { refreshLocalArticlesData } = useArticleLocalDataUpdater()
const notificationStore = useNotificationStore()

// DATA
const currentView = ref(0)
const formLoading = ref(false)
const headerTitle = ref('')
let modelFormModel = reactive<Record<string, any>>({})
let modelsFormModel = reactive<Record<number, Record<string, any>>>({})
const modelFormShowOnlySeasonalAttributes = ref(false)
let modelArticlesAndPeriodsFormModel = reactive({
  periodsFormModel: {} as Record<string, string>,
  modelArticlesListModel: [] as Array<MyArticle | Article>,
})
let articleFormModel = reactive<Record<string, any>>({})
let previewArticles = reactive<MyArticle[]>([])
const previousViewsStack: Array<number> = []
const pageLoading = ref(false)
const retailWindowCriteriaAttributeValue = ref<-999 | any>(-999)
let carryoverModelExistInActiveCatalog = reactive<Record<number, boolean>>({})
const viewsEnum = {
  modelDetails: -99,
  addNew: 0,
  modelForm: 1,
  modelArticlesAndPeriodsForm: 2,
  articleForm: 3,
  articlePreview: 4,
  carryoverModelsForm: 5,
}
let formInitialized = false
const skipRestrictingPropertyUpdateBasedOnArticleState: boolean = userStore.userProfile.Permissions.has('SpecialPermissionToIgnoreArticleState')
const typeSelectionModel = ref(0)
const sortOrderMultiplier = 100

const carryoverArticleFailedDueToAlreadyArticleExists = ref<string[]>([])
const carryoverFailedArticleNumberList = ref<string[]>([])
const failedArticleNumberList = ref<string[]>([])
const articleBgFailed = ref('')
const minimumCopiesLimitForBg = 5
const modelCopies = ref(0)
const modelCopiesCreated = ref(0)
const showCreationProgress = ref(false)
// DATA - END

// COMPUTED
const createType = computed(() => {
  let createType = props.actionType as StyleCreateType
  if (props.actionType === 'addNew') {
    if (typeSelectionModel.value === appConstants.addArticleTypes.newModel) {
      createType = 'newModel'
    }
  }
  return createType
})

const modelFormLayoutConfig = computed(() => {
  let modelFormLayoutConfig: Record<string, IStyleCreateFormLayoutConfig> | undefined
  const formConfig = props.isAddRequest ? userStore.activeCatalog?.Config?.RequestForm : userStore.activeCatalog?.Config?.ArticleForm
  if (createType.value && formConfig && formConfig[createType.value] && formConfig[createType.value]?.modelForm) {
    modelFormLayoutConfig = {}
    formConfig[createType.value]!.modelForm!.forEach((attributeData, index) => {
      if (!modelFormLayoutConfig!.hasOwnProperty(attributeData.attribute)) {
        modelFormLayoutConfig![attributeData.attribute] = {
          sortOrder: index * sortOrderMultiplier,
          readOnly: attributeData.readOnly,
          required: attributeData.required,
          prePopulate: attributeData.hasOwnProperty('prePopulate') ? !!attributeData.prePopulate : true,
        }
      }
    })
  }
  return modelFormLayoutConfig
})

const articleFormLayoutConfig = computed(() => {
  let articleFormLayoutConfig: Record<string, IStyleCreateFormLayoutConfig> | undefined
  const formConfig = props.isAddRequest ? userStore.activeCatalog?.Config?.RequestForm : userStore.activeCatalog?.Config?.ArticleForm
  // as discussed with amardeep for copyArticle from white board we will use carryoverModel configuration
  const createTypeVal = createType.value !== 'copyArticle' ? createType.value : 'carryoverModel'
  if (createTypeVal && formConfig && formConfig[createTypeVal] && formConfig[createTypeVal]?.articleForm) {
    articleFormLayoutConfig = {}
    formConfig[createTypeVal]!.articleForm!.forEach((attributeData, index) => {
      if (!articleFormLayoutConfig!.hasOwnProperty(attributeData.attribute)) {
        articleFormLayoutConfig![attributeData.attribute] = {
          sortOrder: index * sortOrderMultiplier,
          readOnly: attributeData.readOnly,
          required: attributeData.required,
          prePopulate: attributeData.hasOwnProperty('prePopulate') ? !!attributeData.prePopulate : true,
        }
      }
    })
  }
  return articleFormLayoutConfig
})

const requestAttributesFormLayoutConfig = computed(() => {
  let requestAttributesFormLayoutConfig: Record<string, IStyleCreateFormLayoutConfig> | undefined
  const formConfig = userStore.activeCatalog?.Config?.RequestForm
  if (createType.value && formConfig && formConfig[createType.value] && formConfig[createType.value]?.requestAttributesForm) {
    requestAttributesFormLayoutConfig = {}
    formConfig[createType.value]!.requestAttributesForm!.forEach((attributeData, index) => {
      if (!requestAttributesFormLayoutConfig!.hasOwnProperty(attributeData.attribute)) {
        requestAttributesFormLayoutConfig![attributeData.attribute] = {
          sortOrder: index * sortOrderMultiplier,
          readOnly: attributeData.readOnly,
          required: attributeData.required,
        }
      }
    })
  }
  return requestAttributesFormLayoutConfig
})

const defaultArticleState = computed(() => userStore.articleStateList.find(articleState => articleState.IsDefault === 1))

const targetedArticles = computed(() => {
  let targetedArticles: MyArticle[] = []
  if (createType.value !== 'newModel') {
    if (props.contextArticle) {
      targetedArticles.push(props.contextArticle)
    }
    else if (props.selectedArticles) {
      targetedArticles = props.selectedArticles
    }
  }
  return targetedArticles
})

const targetedArticlesCurrentArticleStateDetails = computed(() => getArticlesMaxStateDetails(targetedArticles.value))

const modelFormVisibleAttributes = computed(() => {
  let modelFormVisibleAttributes: IMyAttribute[] = []
  let maxSortOrderForStaticAttributes = modelFormLayoutConfig.value == null ? 0 : Object.keys(modelFormLayoutConfig.value).length * sortOrderMultiplier // sort order for attributes in modelFormLayoutConfig starts form 0

  // SOURCE MODEL NAME AND SOURCE MODEL NUMBER
  if (createType.value === 'copyModel') {
    const sourceModelName: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: '_SourceModelName',
      DisplayName: t('fields.sourceModelName'),
      AttributeType: AttributeType.Nvarchar,
      Visible: true,
      ReadOnly: true,
      SortOrder: -60,
    })

    const sourceModelNumber: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: '_SourceModelNumber',
      DisplayName: t('fields.sourceModelNumber'),
      AttributeType: AttributeType.Nvarchar,
      Visible: true,
      ReadOnly: true,
      SortOrder: -50,
    })

    modelFormVisibleAttributes.push(sourceModelName)
    modelFormVisibleAttributes.push(sourceModelNumber)
  }

  if (createType.value === 'carryoverArticle') {
    // MODEL NUMBER FIELD
    if (modelFormLayoutConfig.value == null || modelFormLayoutConfig.value.hasOwnProperty('ModelNumber')) {
      const modelNumberField = clone(appConstants.staticAttributes.ModelNumber)
      modelNumberField.SortOrder = -40
      modelNumberField.ReadOnly = true
      modelNumberField.IsRequired = false
      if (modelFormLayoutConfig.value != null) {
        if (modelFormLayoutConfig.value?.hasOwnProperty('ModelNumber')) {
          modelNumberField.SortOrder = modelFormLayoutConfig.value.ModelNumber.sortOrder
        }
        else {
          modelNumberField.SortOrder = maxSortOrderForStaticAttributes++
        }
      }
      modelFormVisibleAttributes.push(modelNumberField)
    }
  }

  // MODEL NAME FIELD, always will be visible when style form is visible regardless of configuration
  const modelNameField = clone(appConstants.staticAttributes.ModelName)
  modelNameField.maxLength = userStore.sellerDetails?.ModelNameLength || 100
  const targetedArticlesCurrentArticleState = targetedArticlesCurrentArticleStateDetails.value
  const isModelNameLockedBasedOnArticleState = !skipRestrictingPropertyUpdateBasedOnArticleState
    && props.actionType !== 'newModel' && props.actionType !== 'copyModel' && props.actionType !== 'childModel'
    && targetedArticlesCurrentArticleState != null && !targetedArticlesCurrentArticleState.IsModelNameEditable
  if (isModelNameLockedBasedOnArticleState && targetedArticlesCurrentArticleState.AllowChangeRequestForModelName
    && appConfig.stateLockingExternalChangeManagementURLForModelName && appConfig.stateLockingExternalChangeManagementURLForModelName.toString().trim().length
  ) {
    modelNameField.ExternalChangeManagementURL = appConfig.stateLockingExternalChangeManagementURLForModelName
  }
  // never make model name readonly if creating a model, for carryover model always readonly
  modelNameField.SortOrder = -30
  modelNameField.IsRequired = props.actionType !== 'carryoverModel' && props.actionType !== 'carryoverArticle'
  modelNameField.ReadOnly = (!modelNameField.IsRequired || isModelNameLockedBasedOnArticleState) && props.actionType !== 'newModel' && props.actionType !== 'copyModel' && props.actionType !== 'childModel'
  if (modelFormLayoutConfig.value != null) {
    if (modelFormLayoutConfig.value?.hasOwnProperty('ModelName')) {
      modelNameField.SortOrder = modelFormLayoutConfig.value.ModelName.sortOrder
      modelNameField.IsRequired = (modelNameField.IsRequired || modelFormLayoutConfig.value.ModelName.required) && props.actionType !== 'carryoverModel'
      modelNameField.ReadOnly = (!modelNameField.IsRequired && (modelFormLayoutConfig.value.ModelName.readOnly || isModelNameLockedBasedOnArticleState)) && props.actionType !== 'newModel' && props.actionType !== 'copyModel' && props.actionType !== 'childModel'
      modelNameField.prePopulate = modelFormLayoutConfig.value.ModelName.prePopulate
    }
    else {
      modelNameField.SortOrder = maxSortOrderForStaticAttributes++
    }
  }
  modelFormVisibleAttributes.push(modelNameField)

  // SIZE SCALE (read only)
  if (userStore.sellerDetails?.IsSizeScaleByStyle && (createType.value === 'carryoverModel' || createType.value === 'carryoverArticle') && (modelFormLayoutConfig.value == null || modelFormLayoutConfig.value.hasOwnProperty('SizeScale'))) {
    const sizeScaleField = clone(appConstants.staticAttributes.SizeScale)
    sizeScaleField.SortOrder = -20
    sizeScaleField.ReadOnly = true
    sizeScaleField.IsRequired = false
    if (modelFormLayoutConfig.value != null) {
      if (modelFormLayoutConfig.value?.hasOwnProperty('SizeScale')) {
        sizeScaleField.SortOrder = modelFormLayoutConfig.value.SizeScale.sortOrder
      }
      else {
        sizeScaleField.SortOrder = maxSortOrderForStaticAttributes++
      }
    }
    modelFormVisibleAttributes.push(sizeScaleField)
  }

  // SIZE SCALE (creatable for new/copy model)
  if (userStore.sellerDetails?.IsSizeScaleByStyle && (createType.value === 'newModel' || createType.value === 'copyModel') && modelFormLayoutConfig.value?.hasOwnProperty('SizeScale')) {
    const masterSizeScaleField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'MasterSizeScaleId',
      DisplayName: t('general.masterSizeScale'),
      Creatable: true,
      AttributeType: AttributeType.MasterSizeScale,
    })
    const sizeScaleField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'SizeScaleId',
      DisplayName: t('general.sizeScale'),
      Creatable: true,
      AttributeType: AttributeType.SizeScale,
    })
    const sizeField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'Sizes',
      DisplayName: t('general.sizes', 2),
      Creatable: true,
      AttributeType: AttributeType.MultiValue,
      ReadOnly: true,
    })
    sizeScaleField.SortOrder = modelFormLayoutConfig.value.SizeScale.sortOrder
    sizeScaleField.IsRequired = modelFormLayoutConfig.value.SizeScale.required
    sizeScaleField.ReadOnly = !sizeScaleField.IsRequired && modelFormLayoutConfig.value.SizeScale.readOnly
    masterSizeScaleField.SortOrder = modelFormLayoutConfig.value.SizeScale.sortOrder - 1
    masterSizeScaleField.IsRequired = modelFormLayoutConfig.value.SizeScale.required
    masterSizeScaleField.ReadOnly = !sizeScaleField.IsRequired && modelFormLayoutConfig.value.SizeScale.readOnly
    sizeField.SortOrder = modelFormLayoutConfig.value.SizeScale.sortOrder + 1

    modelFormVisibleAttributes.push(masterSizeScaleField)
    modelFormVisibleAttributes.push(sizeScaleField)
    modelFormVisibleAttributes.push(sizeField)
  }

  // check for privilege
  // PRICES
  if (userStore.activeCatalog?.IsPriceByStyleApply && createType.value !== 'childModel') {
    // as discussed with Amardeep we will consider defaultArticleState (state with isDefault) for newModel, copyModel and childModel
    const targetedArticlesMaxStateRank = (props.actionType === 'newModel' || props.actionType === 'copyModel' || props.actionType === 'childModel')
      ? defaultArticleState.value?.StateRank || 0
      : getArticlesMaxStateRank(targetedArticles.value, !!userStore.activeCatalog!.IsPriceByStyleApply)

    //  if config is null all prices  else in config with sort order  default sort order -10
    userStore.activeCatalog?.CatalogPriceGroupList.forEach((priceGroup) => {
      if (priceGroup.Status && (modelFormLayoutConfig.value == null || modelFormLayoutConfig.value[priceGroup.Name])) {
        let isRequired = false
        let readOnly = false
        let sortOrder = -10
        let prePopulate: boolean | undefined = false
        const isPriceLockedBasedArticleState = priceGroup.StateRank != null && targetedArticlesMaxStateRank >= (priceGroup.StateRank || 0)
        if (modelFormLayoutConfig.value != null) {
          if (modelFormLayoutConfig.value[priceGroup.Name]) {
            isRequired = modelFormLayoutConfig.value[priceGroup.Name].required
            readOnly = modelFormLayoutConfig.value[priceGroup.Name].readOnly
            sortOrder = modelFormLayoutConfig.value[priceGroup.Name].sortOrder
            prePopulate = modelFormLayoutConfig.value[priceGroup.Name].prePopulate
          }
          else {
            sortOrder = maxSortOrderForStaticAttributes++
          }
        }
        const priceGroupField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
          SystemName: priceGroup.Id.toString(),
          DisplayName: `${priceGroup.Name}(${priceGroup.CurrencyCode})`,
          AttributeType: AttributeType.Decimal,
          AttributeSource: '_Prices',
          Editable: true,
          IsRequired: isRequired,
          IsStatic: false,
          SortOrder: sortOrder,
          prePopulate,
          minValue: 1,
          ReadOnly: readOnly || isPriceLockedBasedArticleState,
          ExternalChangeManagementURL: isPriceLockedBasedArticleState && priceGroup.AllowChangeRequestForPriceGroups && appConfig.stateLockingExternalChangeManagementURLForPrices
          && appConfig.stateLockingExternalChangeManagementURLForPrices.toString().trim().length
            ? appConfig.stateLockingExternalChangeManagementURLForPrices
            : undefined,
          IsPersonal: true,
        })
        modelFormVisibleAttributes.push(priceGroupField)
      }
    })
  }

  // DYNAMIC ATTRIBUTES
  userStore.activeCatalog?.ModelLevelAttributeList.forEach((attribute) => {
    if ((!modelFormShowOnlySeasonalAttributes.value || !attribute.IsSeasonlessModelAttribute)
      && attribute.AttributeSource.toLowerCase() === 'self'
      && attribute.AttributeTypeId !== AttributeType.Calc
      && ((modelFormLayoutConfig.value == null && (attribute.Creatable || attribute.IsRequired))
      || ((modelFormLayoutConfig.value != null && modelFormLayoutConfig.value.hasOwnProperty(attribute.AttributeSystemName)) || (!props.isAddRequest && attribute.Creatable && attribute.IsRequired)))
    ) {
      const visibleAttribute: IMyAttribute = {
        SystemName: attribute.AttributeSystemName,
        DisplayName: attribute.AttributeDisplayName,
        AttributeType: attribute.AttributeTypeId,
        AttributeSource: attribute.AttributeSource,
        Creatable: attribute.Creatable,
        Editable: attribute.Editable,
        Visible: attribute.Visible,
        IsRequired: attribute.IsRequired,
        IsStatic: false,
        IsSeasonless: attribute.IsSeasonless || attribute.IsSeasonlessModelAttribute,
        IsModelLevel: true,
        IsRequest: false,
        Overridable: attribute.Overridable,
        ValidationExpression: attribute.ValidationExpression,
        ValidationMessage: attribute.ValidationMessage,
        VettingList: userStore.myAttributes![attribute.AttributeSystemName].VettingList,
        AllowFiltering: userStore.myAttributes![attribute.AttributeSystemName].AllowFiltering,
        FilterLookup: userStore.myAttributes![attribute.AttributeSystemName].FilterLookup,
        Criteria: userStore.myAttributes![attribute.AttributeSystemName].Criteria,
        CriteriaVettingList: userStore.myAttributes![attribute.AttributeSystemName].CriteriaVettingList,
        parsedValidationExpression: userStore.myAttributes![attribute.AttributeSystemName].parsedValidationExpression,
        SortOrder: attribute.SortOrder || 0,
        prePopulate: false,
        ReadOnly: false,
        DefaultValue: attribute.DefaultValue,
        ExternalChangeManagementURL: undefined,
        IsPersonal: userStore.myAttributes![attribute.AttributeSystemName].IsPersonal,
      }

      if (modelFormLayoutConfig.value != null) {
        if (modelFormLayoutConfig.value.hasOwnProperty(visibleAttribute.SystemName)) {
          const currentAttributeConfigValue = modelFormLayoutConfig.value[visibleAttribute.SystemName]
          visibleAttribute.SortOrder = currentAttributeConfigValue.sortOrder
          visibleAttribute.IsRequired = attribute.IsRequired || currentAttributeConfigValue.required
          visibleAttribute.ReadOnly = !attribute.IsRequired && !currentAttributeConfigValue.required && currentAttributeConfigValue.readOnly
          visibleAttribute.prePopulate = currentAttributeConfigValue.prePopulate
        }
        else {
          visibleAttribute.SortOrder = maxSortOrderForStaticAttributes
        }
      }
      modelFormVisibleAttributes.push(visibleAttribute)
    }
  })

  modelFormVisibleAttributes = utils.sort(modelFormVisibleAttributes, ['SortOrder', 'DisplayName']) as IMyAttribute[]

  // Add ParentModelNumber and ParentModelName in an specific position as per implementation on T1S
  if (createType.value === 'newModel' || createType.value === 'copyModel' || createType.value === 'childModel') {
    const parentModelTypeAttributeIndex = modelFormVisibleAttributes.findIndex(attribute => attribute.SystemName === 'parent_model_type')
    const parentModelNumberField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'ParentModelNumber',
      DisplayName: t('fields.parentModelNumber'),
      AttributeType: AttributeType.SellerModel,
      Visible: true,
      Creatable: true,
      IsModelLevel: true,
      IsStatic: false,
      SortOrder: 0,
      ReadOnly: createType.value === 'childModel',
      IsPersonal: true,
    })

    const parentModelNameField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'ParentModelName',
      DisplayName: t('fields.parentModelName'),
      AttributeType: AttributeType.Nvarchar,
      Visible: true,
      Creatable: true,
      IsModelLevel: true,
      IsStatic: false,
      SortOrder: 0,
      ReadOnly: true,
      IsPersonal: true,
    })

    // add ParentModelNumber and ParentModelName after parent_model_type attribute
    if (parentModelTypeAttributeIndex !== -1) {
      modelFormVisibleAttributes.splice(parentModelTypeAttributeIndex + 1, 0, parentModelNumberField)
      modelFormVisibleAttributes.splice(parentModelTypeAttributeIndex + 2, 0, parentModelNameField)
    }
    else if (createType.value === 'childModel') {
      modelFormVisibleAttributes.push(parentModelNumberField)
      modelFormVisibleAttributes.push(parentModelNameField)
    }
  }
  return modelFormVisibleAttributes
})

const articleFormDynamicVisibleAttribute = computed(() => {
  const articleFormDynamicVisibleAttribute: IMyAttribute[] = []
  const maxSortOrderForStaticAttributes = articleFormLayoutConfig.value != null ? (Object.keys(articleFormLayoutConfig.value).length + 1) + sortOrderMultiplier : 0

  // as discussed with Amardeep we will consider defaultArticleState (state with isDefault) for newModel, copyModel and childModel
  const targetedArticlesMaxStateRank = (props.actionType === 'newModel' || props.actionType === 'copyModel' || props.actionType === 'childModel')
    ? defaultArticleState.value?.StateRank || 0
    : getArticlesMaxStateRank(targetedArticles.value, !!userStore.activeCatalog!.IsPriceByStyleApply)

  // PRICES (if at article level and have privileges)
  if (userStore.activeCatalog && !userStore.activeCatalog.IsPriceByStyleApply) {
    userStore.activeCatalog!.CatalogPriceGroupList.forEach((priceGroup) => {
      if (priceGroup.Status && (articleFormLayoutConfig.value == null || articleFormLayoutConfig.value[priceGroup.Name])) {
        const isPriceLockedBasedArticleState = priceGroup.StateRank != null && targetedArticlesMaxStateRank >= (priceGroup.StateRank || 0)
        const priceFiled: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
          SystemName: priceGroup.Id.toString(),
          DisplayName: `${priceGroup.Name}(${priceGroup.CurrencyCode})`,
          AttributeType: AttributeType.Decimal,
          AttributeSource: '_Prices',
          Visible: true,
          Creatable: true,
          IsModelLevel: false,
          IsStatic: false,
          SortOrder: 0,
          ReadOnly: isPriceLockedBasedArticleState,
          ExternalChangeManagementURL: isPriceLockedBasedArticleState && priceGroup.AllowChangeRequestForPriceGroups && appConfig.stateLockingExternalChangeManagementURLForPrices && appConfig.stateLockingExternalChangeManagementURLForPrices.toString().trim().length ? appConfig.stateLockingExternalChangeManagementURLForPrices : undefined,
          IsPersonal: true,
        })

        if (articleFormLayoutConfig.value == null) {
          utils.insertSorted(priceFiled, articleFormDynamicVisibleAttribute, (a, b) => utils.comparer(a, b, ['SortOrder', 'DisplayName']))
        }
        else if (articleFormLayoutConfig.value.hasOwnProperty(priceGroup.Name)) {
          priceFiled.IsRequired = articleFormLayoutConfig.value[priceGroup.Name].required
          priceFiled.ReadOnly = (!articleFormLayoutConfig.value[priceGroup.Name].required && articleFormLayoutConfig.value[priceGroup.Name].readOnly) || isPriceLockedBasedArticleState
          priceFiled.SortOrder = articleFormLayoutConfig.value[priceGroup.Name].sortOrder
          priceFiled.prePopulate = articleFormLayoutConfig.value[priceGroup.Name].prePopulate
          utils.insertSorted(priceFiled, articleFormDynamicVisibleAttribute, (a, b) => utils.comparer(a, b, ['SortOrder', 'DisplayName']))
        }
      }
    })
  }

  if ((createType.value === 'newModel' || createType.value === 'copyModel' || createType.value === 'childModel' || createType.value === 'carryoverModel' || createType.value === 'copyArticle')
    && articleFormLayoutConfig.value != null && articleFormLayoutConfig.value.ColorId) {
    const primaryColor: IMyAttribute = Object.assign({}, appConstants.staticAttributes.ColorId, {
      IsStatic: false,
      IsRequired: articleFormLayoutConfig.value.ColorId.required,
      ReadOnly: (!articleFormLayoutConfig.value.ColorId.required && articleFormLayoutConfig.value.ColorId.readOnly),
      SortOrder: articleFormLayoutConfig.value.ColorId.sortOrder || 0,
      prePopulate: articleFormLayoutConfig.value.ColorId.prePopulate,
    })
    utils.insertSorted(primaryColor, articleFormDynamicVisibleAttribute, (a, b) => utils.comparer(a, b, ['SortOrder', 'DisplayName']))
  }
  if ((createType.value === 'newModel' || createType.value === 'copyModel' || createType.value === 'carryoverModel' || createType.value === 'carryoverArticle')
    && articleFormLayoutConfig.value != null && articleFormLayoutConfig.value.CreateIn) {
    const CreateIn: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
      AttributeType: AttributeType.Nvarchar,
      SystemName: 'StateId',
      DisplayName: t('admin.articles.fields.stateName'),
      IsStatic: false,
      IsRequired: articleFormLayoutConfig.value.CreateIn.required,
      ReadOnly: (!articleFormLayoutConfig.value.CreateIn.required && articleFormLayoutConfig.value.CreateIn.readOnly),
      SortOrder: articleFormLayoutConfig.value.CreateIn.sortOrder || 0,
      prePopulate: articleFormLayoutConfig.value.CreateIn.prePopulate,
      showNone: false,
      IsSeasonless: true,
    })
    utils.insertSorted(CreateIn, articleFormDynamicVisibleAttribute, (a, b) => utils.comparer(a, b, ['SortOrder', 'DisplayName']))
  }

  // CATALOG ATTRIBUTES
  userStore.activeCatalog!.ArticleLevelAttributeList.forEach((attribute) => {
    if (
      attribute.AttributeSource.toLowerCase() === 'self'
      && attribute.AttributeTypeId !== AttributeType.Calc
      && ((articleFormLayoutConfig.value == null && (attribute.Creatable && attribute.IsRequired))
      || ((articleFormLayoutConfig.value != null && articleFormLayoutConfig.value.hasOwnProperty(attribute.AttributeSystemName)) || (!props.isAddRequest && attribute.Creatable && attribute.IsRequired)))
    ) {
      let sortOrder = attribute.SortOrder || 0
      let isRequired = attribute.IsRequired
      let readOnly = (attribute.IsSeasonless && createType.value === 'carryoverArticle')
      let prePopulate: boolean | undefined = false
      if (articleFormLayoutConfig.value != null) {
        if (articleFormLayoutConfig.value.hasOwnProperty(attribute.AttributeSystemName)) {
          sortOrder = articleFormLayoutConfig.value[attribute.AttributeSystemName].sortOrder
          isRequired = attribute.IsRequired || articleFormLayoutConfig.value[attribute.AttributeSystemName].required
          readOnly = (!(attribute.IsRequired || articleFormLayoutConfig.value[attribute.AttributeSystemName].required) && articleFormLayoutConfig.value[attribute.AttributeSystemName].readOnly)
          || (attribute.IsSeasonless && createType.value === 'carryoverArticle')
          prePopulate = articleFormLayoutConfig.value[attribute.AttributeSystemName].prePopulate
        }
        else {
          sortOrder = maxSortOrderForStaticAttributes
          readOnly = attribute.IsSeasonless && createType.value === 'carryoverArticle'
        }
      }

      const articleLevelAttributeFiled: IMyAttribute = {
        SystemName: attribute.AttributeSystemName,
        DisplayName: attribute.AttributeDisplayName,
        AttributeType: attribute.AttributeTypeId,
        AttributeSource: attribute.AttributeSource,
        Creatable: attribute.Creatable,
        Editable: attribute.Editable,
        Visible: attribute.Visible,
        IsRequired: isRequired,
        IsStatic: false,
        IsSeasonless: attribute.IsSeasonless || attribute.IsSeasonlessModelAttribute,
        IsModelLevel: false,
        IsRequest: false,
        Overridable: attribute.Overridable,
        ValidationExpression: attribute.ValidationExpression,
        ValidationMessage: attribute.ValidationMessage,
        VettingList: userStore.myAttributes![attribute.AttributeSystemName].VettingList,
        AllowFiltering: userStore.myAttributes![attribute.AttributeSystemName].AllowFiltering,
        FilterLookup: userStore.myAttributes![attribute.AttributeSystemName].FilterLookup,
        Criteria: userStore.myAttributes![attribute.AttributeSystemName].Criteria,
        CriteriaVettingList: userStore.myAttributes![attribute.AttributeSystemName].CriteriaVettingList,
        parsedValidationExpression: userStore.myAttributes![attribute.AttributeSystemName].parsedValidationExpression,
        SortOrder: sortOrder,
        prePopulate,
        ReadOnly: readOnly,
        DefaultValue: attribute.DefaultValue,
        ExternalChangeManagementURL: undefined,
        IsPersonal: userStore.myAttributes![attribute.AttributeSystemName].IsPersonal,
      }
      utils.insertSorted(articleLevelAttributeFiled, articleFormDynamicVisibleAttribute, (a, b) => utils.comparer(a, b, ['SortOrder', 'DisplayName']))
    }
  })
  return articleFormDynamicVisibleAttribute
})

const articleFormVisibleAttributes = computed(() => {
  const articleFormVisibleAttributes: IMyAttribute[] = []
  // STATIC ATTRIBUTES
  const articleField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
    AttributeType: AttributeType.Nvarchar,
    DisplayName: t('general.article'),
    SystemName: 'Article',
    ReadOnly: true,
  })
  articleFormVisibleAttributes.push(articleField)
  if (userStore.activeCatalog?.IsShippingWindowApply) {
    articleFormVisibleAttributes.push(appConstants.staticAttributes.Period)
    articleFormVisibleAttributes.push(appConstants.staticAttributes.RetailIntroMonth)
    articleFormVisibleAttributes.push(appConstants.staticAttributes.RetailExitMonth)
    articleFormVisibleAttributes.push(appConstants.staticAttributes.ShipmentStartDate)
    articleFormVisibleAttributes.push(appConstants.staticAttributes.ShipmentEndDate)
  }
  articleFormDynamicVisibleAttribute.value.forEach((attribute) => {
    articleFormVisibleAttributes.push(attribute)
  })
  return articleFormVisibleAttributes
})

const requestAttributesFormVisibleAttributes = computed(() => {
  const result: IMyAttribute[] = []
  if (userStore.activeCatalog) {
    userStore.activeCatalog.RequestAttributeList.forEach((attribute) => {
      if (attribute.Status > 0 && attribute.AttributeTypeId !== AttributeType.Calc && utils.isDefined(userStore.myAttributes![attribute.AttributeSystemName])
        && (requestAttributesFormLayoutConfig.value == null || (requestAttributesFormLayoutConfig.value.hasOwnProperty(attribute.AttributeSystemName)))
      ) {
        let sortOrder = 0
        let isRequired = false
        let readOnly = false
        if (requestAttributesFormLayoutConfig.value != null) {
          if (requestAttributesFormLayoutConfig.value.hasOwnProperty(attribute.AttributeSystemName)) {
            sortOrder = requestAttributesFormLayoutConfig.value[attribute.AttributeSystemName].sortOrder
            isRequired = requestAttributesFormLayoutConfig.value[attribute.AttributeSystemName].required
            readOnly = (!(requestAttributesFormLayoutConfig.value[attribute.AttributeSystemName].required) && requestAttributesFormLayoutConfig.value[attribute.AttributeSystemName].readOnly)
          }
        }

        const requestAttributeFiled: IMyAttribute = {
          SystemName: attribute.AttributeSystemName,
          DisplayName: attribute.AttributeDisplayName,
          AttributeType: attribute.AttributeTypeId,
          AttributeSource: 'request',
          Creatable: true,
          Editable: true,
          Visible: true,
          IsRequired: isRequired,
          IsStatic: false,
          IsSeasonless: false,
          IsModelLevel: false,
          IsRequest: true,
          Overridable: false,
          ValidationExpression: attribute.ValidationExpression,
          ValidationMessage: attribute.ValidationMessage,
          VettingList: userStore.myAttributes![attribute.AttributeSystemName].VettingList,
          AllowFiltering: userStore.myAttributes![attribute.AttributeSystemName].AllowFiltering,
          FilterLookup: userStore.myAttributes![attribute.AttributeSystemName].FilterLookup,
          Criteria: userStore.myAttributes![attribute.AttributeSystemName].Criteria,
          CriteriaVettingList: userStore.myAttributes![attribute.AttributeSystemName].CriteriaVettingList,
          parsedValidationExpression: userStore.myAttributes![attribute.AttributeSystemName].parsedValidationExpression,
          SortOrder: sortOrder,
          prePopulate: false,
          ReadOnly: readOnly,
          IsPersonal: userStore.myAttributes![attribute.AttributeSystemName].IsPersonal,
        }
        utils.insertSorted(requestAttributeFiled, result, (a, b) => utils.comparer(a, b, ['SortOrder', 'DisplayName']))
      }
    })
  }
  return result
})

const sortedActiveShipmentWindowRange = computed(() => {
  return utils.sort(userStore.activeCatalog!.ShipmentWindowRangeList.filter(s => s.Status), 'Period') as CatalogShipmentWindowRange[]
})

const carryoverArticleFailedAlertMessage = computed(() => {
  let message = ''
  if (carryoverArticleFailedDueToAlreadyArticleExists.value.length > 0 && carryoverFailedArticleNumberList.value.length > 0) {
    message = t('createArticle.messages.carryoverArticleAlreadyExistInCatalogAndFewCarryoverFailed', { carryoverArticleFailedDueToAlreadyArticleExists: carryoverArticleFailedDueToAlreadyArticleExists.value.join(), carryoverFailedArticleNumberList: carryoverFailedArticleNumberList.value.join() })
  }
  else if (carryoverArticleFailedDueToAlreadyArticleExists.value.length > 0) {
    message = t('createArticle.messages.carryoverArticleAlreadyExistInCatalog', { carryoverArticleFailedDueToAlreadyArticleExists: carryoverArticleFailedDueToAlreadyArticleExists.value.join() })
  }
  else if (carryoverFailedArticleNumberList.value.length > 0) {
    message = t('createArticle.messages.carryoverArticleCreationFailed', { carryoverFailedArticleNumberList: carryoverFailedArticleNumberList.value.join() })
  }
  else if (articleBgFailed.value.length) {
    message = articleBgFailed.value
  }
  return message
})
const articleFailedAlertMessage = computed(() => {
  let message = ''
  if (failedArticleNumberList.value.length > 0) {
    message = t((props.isAddRequest ? 'createArticle.messages.createRequestFailed' : 'createArticle.messages.createArticlesFailed'), { failedArticleNumberList: failedArticleNumberList.value.join() })
  }
  return message
})
// COMPUTED - END

// METHODS
async function init() {
  currentView.value = viewsEnum.addNew // reset current view
  if (targetedArticles.value.length) {
    // RetailWindowAttribute is seasonless model level attribute, in all the actions targeted articles belongs to same model so the value will be same across all model articles
    // only in case of new model or child model take the value from model form (init at onNext function), if model form does not have this property configured fallback to targeted articles
    retailWindowCriteriaAttributeValue.value = targetedArticles.value[0][userStore.activeCatalog!.RetailWindowAttribute]
  }
  if (props.actionType === 'addNew') {
    headerTitle.value = t('modelCreate.title.addNew')
  }
  else if (props.actionType === 'newModel') {
    headerTitle.value = t('modelCreate.title.newModel')
    showModelForm()
    modelFormShowOnlySeasonalAttributes.value = false
  }
  else if (props.actionType === 'copyModel') {
    headerTitle.value = props.isAddRequest ? t('modelCreate.title.similarModelRequest') : t('modelCreate.title.copyModel')
    showModelForm()
    modelFormShowOnlySeasonalAttributes.value = false
    if (props.externalData != null) { // if coming from model details
      if (!previousViewsStack.includes(viewsEnum.modelDetails)) {
        previousViewsStack.push(viewsEnum.modelDetails)
      }
    }
  }
  else if (props.actionType === 'childModel') {
    headerTitle.value = t('modelCreate.title.childModel')
    showModelForm()
    modelFormShowOnlySeasonalAttributes.value = false
  }
  else if (props.actionType === 'carryoverModel') {
    headerTitle.value = props.isAddRequest ? t('modelCreate.title.carryoverModelRequest') : t('modelCreate.title.carryoverModel')
    modelFormShowOnlySeasonalAttributes.value = true
    if (props.externalData != null) { // if coming from model details
      if (!previousViewsStack.includes(viewsEnum.modelDetails)) {
        previousViewsStack.push(viewsEnum.modelDetails)
      }
    }
    try {
      await figureOutNextScreenForCarryoverModel()
    }
    catch (error) {
      console.warn(error)
    }
  }
  else if (props.actionType === 'carryoverArticle') {
    headerTitle.value = t('modelCreate.title.carryoverArticles')
    modelFormShowOnlySeasonalAttributes.value = true
    modelArticlesAndPeriodsFormModel.modelArticlesListModel = props.selectedArticles!
    // as discussed with UI team who developed the filtering on periods quantity, in case of carryover articles, we consider the first article's value
    try {
      await figureOutNextScreenForCarryoverArticle()
    }
    catch (error) {
      console.warn(error)
    }
  }
  else if (props.actionType === 'copyArticle') {
    headerTitle.value = t('modelCreate.title.copyArticle')
    showModelArticlesAndPeriodsForm()
  }
}

async function onNext(payload?: Record<string, any>) {
  // add current view to previous views stack
  if (!previousViewsStack.includes(currentView.value)) {
    previousViewsStack.push(currentView.value)
  }

  // TODO: this might be for all actions where in style form we modify the value
  // if model form contains RetailWindowAttribute take the value enter by user (only for new model and copy model actions, for rest it will be read from targeted articles), in case of copy if not part of modelForm it will fallback to targeted article
  if (payload && payload.modelFormModel && payload.modelFormModel.hasOwnProperty(userStore.activeCatalog!.RetailWindowAttribute) && (createType.value === 'newModel' || createType.value === 'copyModel')) {
    retailWindowCriteriaAttributeValue.value = payload.modelFormModel[userStore.activeCatalog!.RetailWindowAttribute]
  }

  // assign payload values to local variables
  if (payload) {
    if (currentView.value === viewsEnum.modelForm) {
      modelFormModel = payload.modelFormModel
      modelCopies.value = modelFormModel.ModelCopies
    }
    else if (currentView.value === viewsEnum.carryoverModelsForm) {
      modelsFormModel = payload.modelsFormModel
    }
    else if (currentView.value === viewsEnum.modelArticlesAndPeriodsForm) {
      modelArticlesAndPeriodsFormModel = payload.modelArticlesAndPeriodsFormModel
    }
    else if (currentView.value === viewsEnum.articleForm) {
      articleFormModel = payload.articleFormModel
    }
  }

  // define flow based on action-type for better readability, this will make similar code to repeat
  if (createType.value === 'newModel') {
    if (currentView.value === viewsEnum.addNew) {
      showModelForm()
    }
    else if (currentView.value === viewsEnum.modelForm) {
      showModelArticlesAndPeriodsForm()
    }
    else if (currentView.value === viewsEnum.modelArticlesAndPeriodsForm) {
      showArticlesForm()
    }
    else if (currentView.value === viewsEnum.articleForm) {
      await createArticles(modelFormModel, articleFormModel, createType.value)
      showPreviewScreen()
    }
    else if (currentView.value === viewsEnum.articlePreview) {
      closeAddArticleDrawer()
    }
  }
  else if (createType.value === 'copyModel') {
    // current view is model form
    if (currentView.value === viewsEnum.modelForm) {
      showModelArticlesAndPeriodsForm()
    }
    else if (currentView.value === viewsEnum.modelArticlesAndPeriodsForm) {
      showArticlesForm()
    }
    else if (currentView.value === viewsEnum.articleForm) {
      if (props.isAddRequest) {
        await createArticlesRequest(modelFormModel, articleFormModel, createType.value)
        showPreviewScreen()
      }
      else {
        createArticles(modelFormModel, articleFormModel, createType.value)
        if (modelFormModel.ModelCopies > minimumCopiesLimitForBg) {
          closeAddArticleDrawer()
        }
        else {
          showPreviewScreen()
        }
      }
    }
    else if (currentView.value === viewsEnum.articlePreview) {
      closeAddArticleDrawer()
    }
  }
  else if (createType.value === 'childModel') {
    // current view is model form
    if (currentView.value === viewsEnum.modelForm) {
      showModelArticlesAndPeriodsForm()
    }
    else if (currentView.value === viewsEnum.modelArticlesAndPeriodsForm) {
      showArticlesForm()
    }
    else if (currentView.value === viewsEnum.articleForm) {
      await createArticles(modelFormModel, articleFormModel, createType.value)
      showPreviewScreen()
    }
    else if (currentView.value === viewsEnum.articlePreview) {
      closeAddArticleDrawer()
    }
  }
  else if (createType.value === 'carryoverModel') {
    // current view is model form
    if (currentView.value === viewsEnum.modelForm) {
      showArticlesForm()
    }
    else if (currentView.value === viewsEnum.articleForm) {
      if (props.isAddRequest) {
        await createArticlesRequest(modelFormModel, articleFormModel, createType.value)
        showPreviewScreen()
      }
      else {
        await createArticles(modelFormModel, articleFormModel, createType.value)
        showPreviewScreen()
      }
    }
    else if (currentView.value === viewsEnum.articlePreview) {
      closeAddArticleDrawer()
    }
  }
  else if (createType.value === 'carryoverArticle') {
    if (currentView.value === viewsEnum.modelForm || currentView.value === viewsEnum.carryoverModelsForm) {
      showArticlesForm()
    }
    else if (currentView.value === viewsEnum.articleForm) {
      let uniqueModelIds: number[] = []
      if (props.selectedArticles?.length) {
        uniqueModelIds = [...new Set(props.selectedArticles?.map(obj => obj.ModelId))]
      }
      await createArticles(modelFormModel, articleFormModel, createType.value, modelsFormModel, uniqueModelIds)
      if (uniqueModelIds.length > 1) {
        closeAddArticleDrawer()
      }
      else {
        showPreviewScreen()
      }
    }
    else if (currentView.value === viewsEnum.articlePreview) {
      closeAddArticleDrawer()
    }
  }
  else if (createType.value === 'copyArticle') {
    // current view is model form
    if (currentView.value === viewsEnum.modelArticlesAndPeriodsForm) {
      showArticlesForm()
    }
    else if (currentView.value === viewsEnum.articleForm) {
      await createArticles(modelFormModel, articleFormModel, createType.value)
      showPreviewScreen()
    }
    else if (currentView.value === viewsEnum.articlePreview) {
      closeAddArticleDrawer()
    }
  }
  else {
    console.info(`${createType.value} is wrong create type`)
  }
}

async function createArticlesRequest(modelFormModel, articleFormModel, createType: StyleCreateType) {
  failedArticleNumberList.value = []
  pageLoading.value = true
  try {
    const payloadObject = createArticlesPayloadObject(modelFormModel, articleFormModel, createType, modelsFormModel)

    const requestPayloads: CreateRequestModel[] = []

    if (payloadObject.carryoverArticlesPayload && payloadObject.carryoverArticlesPayload.length) {
      const requestSource = createType && createType === 'copyModel' ? requestConstants.requestSources.similarStyle : requestConstants.requestSources.carryover
      for (const art of payloadObject.carryoverArticlesPayload) {
        if (utils.isDefined(art.SizeScaleId)) { // if SizeScaleId add the MasterSizeScaleId
          art.MasterSizeScaleId = userStore.sizeScales[art.SizeScaleId].MasterSizeScaleId
          art.SizeScale = userStore.sizeScales[art.SizeScaleId].SizeScale
        }
        requestPayloads.push(await getRequestPayload(art, requestSource))
      }
    }
    if (payloadObject.createArticlesPayload) {
      const requestSource = createType && createType === 'copyModel' ? requestConstants.requestSources.similarStyle : requestConstants.requestSources.new
      for (const key in payloadObject.createArticlesPayload) {
        if (payloadObject.createArticlesPayload[key] && payloadObject.createArticlesPayload[key].length) {
          for (const art of payloadObject.createArticlesPayload[key]) {
            if (utils.isDefined(art.SizeScaleId)) { // if SizeScaleId add the MasterSizeScaleId
              art.MasterSizeScaleId = userStore.sizeScales[art.SizeScaleId].MasterSizeScaleId
              art.SizeScale = userStore.sizeScales[art.SizeScaleId].SizeScale
            }
            requestPayloads.push(await getRequestPayload(art, requestSource))
          }
        }
      }
    }

    const modelCopies = modelFormModel.ModelCopies ? Number(modelFormModel.ModelCopies) : undefined

    if (userStore.activeCatalog && requestPayloads.length > 0) {
      await createRequestBulk(userStore.activeCatalog!.CatalogCode, requestPayloads, modelCopies)
        .then(async (resp) => {
          const newObjectIds: string[] = requestPayloads.map(r => r.ObjectId)
          await userStore.doLoadData(['Requests'], undefined, true, undefined, newObjectIds)

          previewArticles = []
          if (resp && resp.data.length) {
            for (const request of resp.data) {
              const requestArticle = await appConfig.DB!.getRequestArticleById(userStore.activeCatalog!, request.Id, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
              if (requestArticle) {
                previewArticles.push(requestArticle)
              }
            }
          }
        })
        .catch ((error) => {
          console.error(error)
          failedArticleNumberList.value.push(requestPayloads[0].SourceModelNumber || '')
        })
    }
  }
  finally {
    pageLoading.value = false
  }
}

async function getRequestPayload(art, requestSource: number) {
  const payload: CreateRequestModel = {
    ObjectId: guid(),
    InitialContent: '',
    Content: '',
    State: requestConstants.requestStates.new,
    IsCreateArticleRequest: true,
    RequestSource: requestSource,
    RequestType: requestSource === requestConstants.requestSources.similarStyle ? requestConstants.requestTypes.SimilarStyle.key : requestSource === requestConstants.requestSources.carryover ? requestConstants.requestTypes.CarryoverArticle.key : requestConstants.requestTypes.AddColorway.key,
    RequestVersion: requestConstants.requestVersion,
    SourceModelId: art.ModelId,
    SourceModelNumber: art.ModelNumber,
    SourceArticleId: requestSource === requestConstants.requestSources.carryover ? art.ArticleId : props.contextArticle ? props.contextArticle.Id : art.ArticleId,
    SourceArticleNumber: requestSource === requestConstants.requestSources.carryover ? art.ArticleNumber : props.contextArticle ? props.contextArticle.ArticleNumber : art.ArticleNumber,
    SourceCatalogCode: props.contextArticle ? props.contextArticle.CatalogCode : userStore.activeCatalog?.CatalogCode,
    SourceCatalogName: props.contextArticle && props.contextArticle.CatalogCode ? props.contextArticle.CatalogCode === userStore.activeCatalog?.CatalogCode ? userStore.activeCatalog?.CatalogName : userStore.linkedCatalogDetails[props.contextArticle.CatalogCode].CatalogName : userStore.activeCatalog?.CatalogName,
    Attachments: art.Attachments,
  }

  const requestAttributes: Record<string, string[]> = {}
  const requestContent: Record<string, any> = {}
  if (utils.isDefined(art) && userStore.myAttributes) {
    for (const key in art) {
      if (utils.isDefined(art[key])) {
        if (userStore.myAttributes[key]?.IsRequest) {
          payload[key] = art[key]
          requestAttributes[key] = [art[key]]
        }
        else {
          requestContent[key] = art[key]
        }
      }
    }
  }
  // include the calc request attribute
  if (userStore.activeCatalog && userStore.myAttributes) {
    for (const requestAttribute of userStore.activeCatalog.RequestAttributeList) {
      const attribute = userStore.myAttributes[requestAttribute.AttributeSystemName]
      const value = await utils.getCalcRequestAttributeValue(userStore.activeCatalog, attribute, payload, requestContent, appConfig.DB, requestAttributes, userStore.priceGroups)
      if (utils.isDefined(value)) {
        payload[attribute.SystemName] = value
      }
    }
  }
  payload.Content = JSON.stringify(requestContent)

  return payload
}

async function createArticles(modelFormModel, articleFormModel, createType, modelsFormModel = {}, uniqueModelIds: number [] = []) {
  const isCreateModelBgJob = !!(createType === 'copyModel' && modelFormModel.ModelCopies > minimumCopiesLimitForBg)
  pageLoading.value = !!(createType !== 'copyModel' || isCreateModelBgJob)
  try {
    const payloadObject = createArticlesPayloadObject(modelFormModel, articleFormModel, createType, modelsFormModel, isCreateModelBgJob)
    if (createType === 'carryoverArticle' && uniqueModelIds.length > 1) {
      doCreateArticleBackgroundJob(payloadObject.carryoverArticlesPayload, true)
    }
    else {
      if (isCreateModelBgJob) {
        const payloadObjects: object[] = []
        Object.keys(payloadObject.createArticlesPayload).forEach((modelNumber) => {
          payloadObjects.push(...payloadObject.createArticlesPayload[modelNumber])
        })
        doCreateArticleBackgroundJob(payloadObjects, false)
      }
      else {
        await doCreateArticles(payloadObject.createArticlesPayload, payloadObject.carryoverArticlesPayload)
      }
    }
  }
  finally {
    pageLoading.value = false
  }
}

function showModelForm() {
  if (modelFormVisibleAttributes.value.length) {
    currentView.value = viewsEnum.modelForm
  }
  else {
    figureOutNextScreen(viewsEnum.modelForm)
  }
}

function showModelArticlesAndPeriodsForm() {
  currentView.value = viewsEnum.modelArticlesAndPeriodsForm
}

function showArticlesForm() {
  currentView.value = viewsEnum.articleForm
}

function showCarryoverModelsForm() {
  if (modelFormVisibleAttributes.value.length) {
    currentView.value = viewsEnum.carryoverModelsForm
  }
  else {
    figureOutNextScreen(viewsEnum.carryoverModelsForm)
  }
}

function showPreviewScreen() {
  currentView.value = viewsEnum.articlePreview
}

/**
 * @description this method should be coded in a way that it should be able to take use to next screen if the intended one should be skipped for any reason
 * for example if intended screen to be visible is model form but there are no attribute to display and this form should be skip to next form
 */
async function figureOutNextScreen(attemptFrom: number | undefined = undefined) {
  if (currentView.value === 0) { // if initial value
    if (createType.value === 'newModel' || createType.value === 'copyModel' || createType.value === 'childModel') {
      if (attemptFrom !== viewsEnum.modelArticlesAndPeriodsForm) {
        showModelArticlesAndPeriodsForm()
      }
      else {
        // if figureOutNextScreen is being called form showModelArticlesAndPeriodsForm and attempting to again go to same form, it means there is some issue
        console.error(`unresolved condition, action type: ${createType.value}, attempt from ${attemptFrom}`)
      }
    }
    else if (createType.value === 'carryoverArticle') {
      if (attemptFrom !== viewsEnum.articleForm) {
        showArticlesForm()
      }
      else {
        // if figureOutNextScreen is being called form articleForm and attempting to again go to same form, it means there is some issue
        console.error(`unresolved condition, action type: ${createType.value}, attempt from ${attemptFrom}`)
      }
    }
  }
}

async function figureOutNextScreenForCarryoverModel(attemptFrom: number | undefined = undefined) {
  let modelExistInActiveCatalog = {}
  pageLoading.value = true
  try {
    // check if article belong to a model that exist in current catalog or not
    if (props.contextArticle) {
      modelExistInActiveCatalog = await doesModelBelongsToActiveCatalog([props.contextArticle.ModelId])
    }
    await setupFormDataFromExternalData()
  }
  catch (error) {
    console.error('could not load model articles! \n', error)
    return
  }
  finally {
    pageLoading.value = false
  }
  // GO TO ARTICLE FORM - if context article belongs to a model from current catalog open article form
  if (modelExistInActiveCatalog[props.contextArticle!.ModelId]) {
    if (attemptFrom !== viewsEnum.articleForm) {
      showArticlesForm()
    }
    else {
      // if figureOutNextScreen is being called form showArticlesForm and attempting to again go to same form, it means there is some issue
      console.error(`unresolved condition, action type: ${createType.value}, attempt from ${attemptFrom}`)
    }
  }
  else { // GO TO MODEL FORM - if context article does not belongs to a model from current catalog open model form
    if (attemptFrom !== viewsEnum.modelForm) {
      showModelForm()
    }
    else {
      // if figureOutNextScreen is being called form showModelForm and attempting to again go to same form, it means there is some issue
      console.error(`unresolved condition, action type: ${createType.value}, attempt from ${attemptFrom}`)
    }
  }
}

async function figureOutNextScreenForCarryoverArticle(attemptFrom: number | undefined = undefined) {
  let modelExistInActiveCatalog = {}
  let uniqueModelIds: number[] = []
  pageLoading.value = true
  if (props.selectedArticles?.length) {
    uniqueModelIds = [...new Set(props.selectedArticles?.map(obj => obj.ModelId))]
    try {
      // we only carryover article from 1 model
      modelExistInActiveCatalog = await doesModelBelongsToActiveCatalog(uniqueModelIds)
    }
    catch (error) {
      console.error('cold not load model articles! \n', error)
    }
    finally {
      pageLoading.value = false
    }
  }
  if (uniqueModelIds.length === 1) {
    if (modelExistInActiveCatalog[uniqueModelIds[0]]) {
      if (attemptFrom !== viewsEnum.articleForm) {
        showArticlesForm()
      }
      else {
        // if figureOutNextScreen is being called form showArticlesForm and attempting to again go to same form, it means there is some issue
        console.error(`unresolved condition, action type: ${createType.value}, attempt from ${attemptFrom}`)
      }
    }
    else { // GO TO MODEL FORM - if context article does not belongs to a model from current catalog open model form
      if (attemptFrom !== viewsEnum.modelForm) {
        showModelForm()
      }
      else {
        // if figureOutNextScreen is being called form showModelForm and attempting to again go to same form, it means there is some issue
        console.error(`unresolved condition, action type: ${createType.value}, attempt from ${attemptFrom}`)
      }
    }
  }
  else {
    // GO TO ARTICLE FORM - if selected articles belongs to a model from current catalog open article form
    const isAnyModelNotExistsInCatalog = Object.keys(modelExistInActiveCatalog).find(modelId => modelExistInActiveCatalog[modelId] === false)
    if (isAnyModelNotExistsInCatalog) { // GO TO MODEL FORM - if selected article does not belongs to a model from current catalog open model form
      if (attemptFrom !== viewsEnum.modelForm) {
        // we will show all the models in the model form. existing models will be readonly
        carryoverModelExistInActiveCatalog = modelExistInActiveCatalog
        showCarryoverModelsForm()
      }
      else {
        // if figureOutNextScreen is being called form showModelForm and attempting to again go to same form, it means there is some issue
        console.error(`unresolved condition, action type: ${createType.value}, attempt from ${attemptFrom}`)
      }
    }
    else {
      if (attemptFrom !== viewsEnum.articleForm) {
        showArticlesForm()
      }
      else {
        // if figureOutNextScreen is being called form showArticlesForm and attempting to again go to same form, it means there is some issue
        console.error(`unresolved condition, action type: ${createType.value}, attempt from ${attemptFrom}`)
      }
    }
  }
}

/**
 * @description: this method is being use to setup from data from model details page for carryover model action
 */
async function setupFormDataFromExternalData() {
  modelArticlesAndPeriodsFormModel.modelArticlesListModel = []
  modelArticlesAndPeriodsFormModel.periodsFormModel = {}
  const periodToArticleMap = {}
  // sorted catalog code by SeasonalSequence in descending order
  const sortedCatalogCode = utils.sort(Object.values(userStore.linkedCatalogDetails), ['SeasonalSequence'], false).map(catalog => catalog.CatalogCode)
  const filter: Array<[number, number]> = []

  if (props.externalData) {
    for (const period in props.externalData) {
      // setup placeholder data
      if (props.externalData[period].ph && props.externalData[period].ph > 0) {
        modelArticlesAndPeriodsFormModel.periodsFormModel[period] = props.externalData[period].ph.toString()
      }

      // TODO: update ModelDetails to improve data structure
      // setup article to carryover
      if (props.externalData[period].articleIds && props.externalData[period].articleIds.size > 0) {
        props.externalData[period].articleIds.forEach((articleId) => {
          periodToArticleMap[articleId] = period
          // creating filter criterion
          sortedCatalogCode.forEach((catalogCode) => {
            filter.push([catalogCode, articleId])
          })
        })
      }
    }
  }
  else if (props.contextArticle && utils.isDefined(props.contextArticle.Period) && !props.isConfirmRequest) {
    modelArticlesAndPeriodsFormModel.periodsFormModel[props.contextArticle.Period] = '1'
  }
  else if (props.contextArticle && !utils.isDefined(props.contextArticle.Period) && !props.isConfirmRequest) { // if article doesnt have period show article form with period dropdown for request to add article to model
    modelArticlesAndPeriodsFormModel.periodsFormModel.null = '1'
  }

  if (filter.length) {
    try {
      const allArticles: Article[] = []
      const articles: Article[] | undefined = await appConfig.DB?.articles.where('[CatalogCode+Id]').anyOf(filter).toArray()
      if (allArticles && allArticles.length) {
        // sort article so that article that belong to catalog with higher SeasonalSequence appear at the beginning of the array, so in case of duplicate article we will take the article with higher seasonal sequence
        articles?.sort((a, b) => sortedCatalogCode.indexOf(a.CatalogCode) - sortedCatalogCode.indexOf(b.CatalogCode))
      }
      Object.keys(periodToArticleMap).forEach((articleId) => {
        if (articles && articles.length) {
          const dbArticle = articles.find(article => article.Id === Number(articleId))
          if (!dbArticle && props.externalLinkedCatalogArticles && props.externalLinkedCatalogArticles[articleId]) {
            allArticles.push(props.externalLinkedCatalogArticles[articleId] as Article)
          }
          else {
            allArticles.push(dbArticle!)
          }
        }
        else {
          if (props.externalLinkedCatalogArticles && props.externalLinkedCatalogArticles[articleId]) {
            allArticles.push(props.externalLinkedCatalogArticles[articleId] as Article)
          }
        }
      })
      if (allArticles && allArticles.length) {
        allArticles.forEach((article) => {
          if (periodToArticleMap.hasOwnProperty(article.Id)) {
            periodToArticleMap[article.ArticleNumber] = periodToArticleMap[article.Id]
            delete periodToArticleMap[article.Id]
          }
        })
        const modifiedUniqueArticles = uniqBy(allArticles, 'ArticleNumber') as Array<Article>
        modifiedUniqueArticles.forEach((article) => {
          if (periodToArticleMap.hasOwnProperty(article.ArticleNumber)) {
            article.Period = periodToArticleMap[article.ArticleNumber]
          }
        })
        modelArticlesAndPeriodsFormModel.modelArticlesListModel = modifiedUniqueArticles
      }
    }
    catch (error) {
      console.error('unable to fetch article for carryover!\n', error)
    }
  }
}

function createArticlesPayloadObject(modelFormModel, articleFormModel, createType, modelsFormModel, isCreateModelBgJob = false) {
  const catalogModelAttribute = userStore.activeCatalog?.ModelAttribute || '' // LicenseeModelNumber its a dynamic property
  const catalogArticleAttribute = userStore.activeCatalog?.ArticleAttribute || '' // LicenseeMaterialNumber its a dynamic property
  const result = {
    carryoverArticlesPayload: [] as any[],
    createArticlesPayload: {} as Record<any, any[]>,
  }
  const modelCopies = !props.isAddRequest && modelFormModel.ModelCopies ? modelFormModel.ModelCopies : 1
  let uniqueModelIds: number[] = []
  if (props.selectedArticles?.length) {
    uniqueModelIds = [...new Set(props.selectedArticles?.map(obj => obj.ModelId))]
  }
  // if modelCopies are more than 1 we need to generate the modal name for each copy
  const generateModelName = modelCopies > 1
  for (let i = 0; i < modelCopies; i++) {
    const key = utils.randomString(15)
    for (const row in articleFormModel) {
      const payload: Record<string, any> = {}
      const pricesPayload: Array<{ PriceGroupId: number, Price: number }> = []
      const currentRowArticleData = articleFormModel[row]
      const modelData = createType === 'carryoverArticle' && uniqueModelIds.length > 1 ? modelsFormModel[currentRowArticleData.ModelId] ? modelsFormModel[currentRowArticleData.ModelId] : targetedArticles.value.find(article => article.ModelId === currentRowArticleData.ModelId) : modelFormModel
      // ASSIGN STATIC ATTRIBUTE VALUE
      if (uniqueModelIds.length > 1) {
        payload.ModelNumber = modelData.ModelNumber ? modelData.ModelNumber : undefined
        payload.ModelId = modelData.ModelId ? modelData.ModelId : undefined
        payload.MasterModelId = modelData.MasterModelId ? modelData.MasterModelId : undefined
        payload.ModelName = modelData.ModelName ? modelData.ModelName : undefined
        payload.ArticleName = currentRowArticleData.ArticleName
      }
      else {
        // model name can be set by user or in case multiple copy generate
        payload.ModelName = generateModelName && targetedArticles.value[0].ModelNumber
          ? modelFormModel.hasOwnProperty('ModelName') ? `${modelFormModel.ModelName} copy ${i + 1} of ${targetedArticles.value[0].ModelNumber}` : `copy ${i + 1} of ${targetedArticles.value[0].ModelNumber}`
          : modelFormModel.hasOwnProperty('ModelName') ? modelFormModel.ModelName : (targetedArticles.value.length ? targetedArticles.value[0].ModelName : undefined)
        if (!isCreateModelBgJob) {
          payload.ModelNumber = targetedArticles.value.length ? targetedArticles.value[0].ModelNumber : undefined // all actions are perform on single model
          payload.ModelId = targetedArticles.value.length ? targetedArticles.value[0].ModelId : undefined // all actions are perform on single model
        }
        else {
          payload.ModelNumber = key
          payload.ArticleName = payload.ModelName
        }
        payload.MasterModelId = modelFormModel.hasOwnProperty('MasterModelId') ? modelFormModel.MasterModelId : (targetedArticles.value.length ? targetedArticles.value[0].MasterModelId : undefined) // all actions are perform on single model
      }
      // ArticleId and ArticleNumber should have value for carryover articles, and in case of carryover model and child model if it is not a placeholder
      payload.ArticleId = currentRowArticleData.ArticleId
      payload.ArticleNumber = currentRowArticleData.ArticleNumber
      if (userStore.activeCatalog?.IsShippingWindowApply) {
        payload.ShipmentWindowRangeId = currentRowArticleData.ShipmentWindowRangeId
        // as discussed, need this property at the backend to update the shipmentWindowRangeId
        payload.IsShippingWindowUpdatable = 1
      }

      // add size scale only if size scale only for new model or copy model action and only if it is style level and configured in T1S-ArticleForm/T1S-RequestForm configuration
      if (userStore.sellerDetails?.IsSizeScaleByStyle && (createType === 'newModel' || createType === 'copyModel') && modelFormLayoutConfig.value?.hasOwnProperty('SizeScale')) {
        payload.SizeScaleId = modelData.SizeScaleId
      }

      // ASSIGN DYNAMIC ATTRIBUTE VALUE
      // ASSIGN MODEL LEVEL ATTRIBUTES
      modelFormVisibleAttributes.value.forEach((attribute) => {
        // can not ignore undefined value as we do in T1 since some of the form input return undefined on reset and we wont be able to distinguished if user is removing the value or it has no value
        if (!attribute.IsStatic) {
          if (modelData.hasOwnProperty(attribute.SystemName)) {
            if (attribute.AttributeSource === '_Prices') {
              const price = Number.parseFloat(modelData[attribute.SystemName])
              if (price != null && !Number.isNaN(price)) {
                pricesPayload.push({
                  PriceGroupId: Number.parseInt(attribute.SystemName), // price group id is string here
                  Price: price,
                })
              }
            }
            else {
              const modelValue = modelData[attribute.SystemName]
              payload[attribute.SystemName] = normalizeAttributeValue(modelValue, attribute)
            }
          }
          else if (attribute.AttributeSource === '_Prices' && userStore.activeCatalog?.IsPriceByStyleApply) {
            const targetedArticle = targetedArticles.value.find(article => article.ModelId === currentRowArticleData.ModelId)
            if (targetedArticle && targetedArticle._Prices) {
              const price = targetedArticle._Prices[Number.parseInt(attribute.SystemName)]
              if (price) {
                pricesPayload.push({
                  PriceGroupId: price.PriceGroupId,
                  Price: price.Price,
                })
              }
            }
          }
        }
      })

      // ASSIGN ARTICLE LEVEL ATTRIBUTE
      articleFormVisibleAttributes.value.forEach((attribute) => {
        // can not ignore undefined value as we do in T1 since some of the form input return undefined on reset and we wont be able to distinguished if user is removing the value or it has no value
        if (!attribute.IsStatic && currentRowArticleData.hasOwnProperty(attribute.SystemName)) {
          if (attribute.AttributeSource !== '_Prices') {
            payload[attribute.SystemName] = normalizeAttributeValue(currentRowArticleData[attribute.SystemName], attribute)
          }
          else {
            const price = Number.parseFloat(currentRowArticleData[attribute.SystemName])
            if (price != null && !Number.isNaN(price)) {
              pricesPayload.push({
                PriceGroupId: Number.parseInt(attribute.SystemName), // price group id is string here
                Price: price,
              })
            }
          }
        }
      })
      if (props.isAddRequest) {
        requestAttributesFormVisibleAttributes.value.forEach((attribute) => {
          if (!attribute.IsStatic && currentRowArticleData.hasOwnProperty(attribute.SystemName)) {
            payload[attribute.SystemName] = normalizeAttributeValue(currentRowArticleData[attribute.SystemName], attribute)
          }
        })
        // assign attachments if any
        if (currentRowArticleData.Attachments && currentRowArticleData.Attachments.length) {
          payload.Attachments = currentRowArticleData.Attachments
        }
      }
      // if LicenseeModelNumber and LicenseeMaterialNumber is not present or value is not set send null to API.
      // we need to send these two fields always to API for copyModel and createChildModel API's. related Bug 50080
      if (createType === 'copyModel' || createType === 'childModel') {
        if (catalogModelAttribute !== '' && (!utils.isDefined(payload[catalogModelAttribute]) || payload[catalogModelAttribute] === '')) {
          payload[catalogModelAttribute] = null
        }
        if (catalogArticleAttribute !== '' && (!utils.isDefined(payload[catalogArticleAttribute]) || payload[catalogArticleAttribute] === '')) {
          payload[catalogArticleAttribute] = null
        }
      }
      // ADD PRICES
      if (pricesPayload.length) {
        payload.ArticlePrices = {
          Prices: pricesPayload,
          SkipChangeApproval: 1,
        }
      }

      if (currentRowArticleData._isPlaceholder) {
        if (!result.createArticlesPayload[key]) {
          result.createArticlesPayload[key] = [payload]
        }
        else {
          result.createArticlesPayload[key].push(payload)
        }
      }
      else {
        payload.ColorId = currentRowArticleData.ColorId
        result.carryoverArticlesPayload.push(payload)
      }
    }
  }
  return result
}

// this method will always resolve
async function doCreateArticles(createArticlesPayload, carryoverArticlesPayload) {
  carryoverArticleFailedDueToAlreadyArticleExists.value = []
  carryoverFailedArticleNumberList.value = []
  failedArticleNumberList.value = []
  const createdArticlesServerData = [] as any[]
  let carriedOverArticlesServerData = [] as any[]
  const newArticles = [] as any[]
  if (createArticlesPayload && Object.keys(createArticlesPayload).length) {
    const createArticlesPayloadKeys = Object.keys(createArticlesPayload)
    showCreationProgress.value = true
    for (let index = 0; index <= createArticlesPayloadKeys.length - 1; index++) {
      try {
        let createModelFlag: number = 0
        if (createType.value === 'newModel' || createType.value === 'copyModel') {
          createModelFlag = 1
        }
        else if (createType.value === 'childModel') {
          createModelFlag = 2
        }

        // support confirm request
        let requestId: number | undefined
        let shouldConfirmRequest = false
        if (props.requestArticles && props.requestArticles.length) {
          requestId = props.requestArticles[0].Id
          shouldConfirmRequest = true
        }
        const response = await createArticle(userStore.activeCatalog!.CatalogCode, createArticlesPayload[createArticlesPayloadKeys[index]], createModelFlag, requestId, shouldConfirmRequest)
        if (response && response.data && response.data.length) {
          createdArticlesServerData.push(...Array.isArray(response.data[0]) ? response.data[0] : [response.data[0]])
          newArticles.push(...Array.isArray(response.data[0].Articles) ? response.data[0].Articles : [response.data[0].Articles])
        }
        else {
          createdArticlesServerData.push(...Array.isArray(response.data) ? response.data : [response.data])
          newArticles.push(...Array.isArray(response.data[0].Articles) ? response.data[0].Articles : [response.data[0].Articles])
        }
        modelCopiesCreated.value++
      }
      catch (error) {
        console.warn(error)
        failedArticleNumberList.value.push(createArticlesPayload[createArticlesPayloadKeys[index]].ModelNumber)
      }
    }
  }
  if (carryoverArticlesPayload.length) {
    try {
      const isCreateChildModel = createType.value === 'childModel'
      // support confirm request
      let requestId: number | undefined
      let shouldConfirmRequest = false
      if (props.requestArticles && props.requestArticles.length) {
        requestId = props.requestArticles[0].Id
        shouldConfirmRequest = true
      }
      const response = await assignArticle(userStore.activeCatalog!.CatalogCode, carryoverArticlesPayload, isCreateChildModel, isCreateChildModel && createdArticlesServerData && createdArticlesServerData[0] ? createdArticlesServerData[0].ModelId : null, requestId, shouldConfirmRequest)
      if (response && response.data && response.data.length) {
        carriedOverArticlesServerData = Array.isArray(response.data[0]) ? response.data[0] : [response.data[0]]
        newArticles.push(...Array.isArray(response.data[0].Articles) ? response.data[0].Articles : [response.data[0].Articles])
      }
      else {
        carriedOverArticlesServerData = Array.isArray(response.data) ? response.data : [response.data]
        newArticles.push(...Array.isArray(response.data[0].Articles) ? response.data[0].Articles : [response.data[0].Articles])
      }
    }
    catch (error: any) {
      console.warn(error)
      carryoverArticlesPayload.forEach((article) => {
        if (error.response && error.response.data && error.response.data[0] && error.response.data[0].ErrorCode && error.response.data[0].ErrorCode === '387542283') {
          carryoverArticleFailedDueToAlreadyArticleExists.value.push(article.ArticleNumber as string)
        }
        else {
          carryoverFailedArticleNumberList.value.push(article.ArticleNumber as string)
        }
      })
    }
  }
  if (createdArticlesServerData.length || carriedOverArticlesServerData.length) {
    previewArticles = []
    const createdArticles = [...createdArticlesServerData, ...carriedOverArticlesServerData]
    try {
      let loadData: Entity[] = []
      const uniqArticlesByModelId = uniqBy(createdArticles, 'ModelId')
      if (uniqArticlesByModelId.length === 1) {
        await refreshLocalArticlesData(false, uniqArticlesByModelId[0].ModelId, undefined, false)
        loadData = ['Prices']
      }
      else {
        loadData = ['Articles', 'Prices']
      }
      if (props.requestArticles && props.requestArticles.length) {
        loadData.push('Requests')
      }
      const newArticleIds: string[] = newArticles.map(art => art.CatalogArticleId)
      await userStore.doLoadData(loadData, undefined, true, undefined, newArticleIds)
    }
    catch (error) {
      console.warn('unable to load newly created articles! \n', error)
    }
    const queryCriterion: Array<[number, number]> = []
    createdArticles.forEach((model) => {
      if (model.hasOwnProperty('Articles')) {
        model.Articles.forEach((article) => {
          queryCriterion.push([userStore.activeCatalog!.CatalogCode, article.ArticleId || article.Id])
        })
      }
      else if (model.hasOwnProperty('ArticleId') || model.hasOwnProperty('Id')) {
        queryCriterion.push([userStore.activeCatalog!.CatalogCode, model.ArticleId || model.Id])
      }
    })
    try {
      const createdArticles = await appConfig.DB?.articles.where('[CatalogCode+Id]').anyOf(queryCriterion).toArray()
      createdArticles?.forEach((article) => {
        const retailPrice = userStore.priceGroups.retail && article._Prices[userStore.priceGroups.retail.Id] ? article._Prices[userStore.priceGroups.retail.Id].Price : 0
        const wholesalePrice = userStore.priceGroups.wholesale && article._Prices[userStore.priceGroups.wholesale.Id] ? article._Prices[userStore.priceGroups.wholesale.Id].Price : 0
        const outletPrice = userStore.priceGroups.outlet && article._Prices[userStore.priceGroups.outlet.Id] ? article._Prices[userStore.priceGroups.outlet.Id].Price : 0
        previewArticles.push(new MyArticle(article, userStore.activeCatalog!.AssignedCatalogAttributes, retailPrice, wholesalePrice, outletPrice, [], true, [], [], [], userStore.activeCatalog!.Season, userStore.activeCatalog!.DataSourceTypeId, userStore.activeCatalog!.Config.ArticlesCustomSortOrder))
      })
    }
    catch (error) {
      console.error('Unable to load created articles!\n', error)
    }
  }
  showCreationProgress.value = false
}
async function doCreateArticleBackgroundJob(articlesPayload, isCarryover = false) {
  articleBgFailed.value = ''
  await articlesBgJob(userStore.activeCatalog!.CatalogCode, articlesPayload, isCarryover)
    .then(async (res) => {
      if (userStore.activeCatalog && res.data) {
        const job = new Job({
          Id: res.data.Id,
          CatalogCode: userStore.activeCatalog.CatalogCode,
          Type: 'Articles',
          Status: res.data.Status,
          UserId: userStore.userProfile.Id,
          CreatedDate: new Date(),
        })
        await appConfig.DB!.jobs.put(job)
        const message = t('createArticle.messages.jobSuccess')
        notificationStore.addNotification({ message, type: 'Success' })
      }
    })
    .catch((e) => {
      console.error(e)
      articleBgFailed.value = t('general.unexpectedError')
    })
}
function closeAddArticleDrawer() {
  emit('close', previewArticles.length ? previewArticles : null)
}

function onBack() {
  if (previousViewsStack.length) {
    const previousView = previousViewsStack.pop() as number
    if (previousView !== viewsEnum.modelDetails) {
      currentView.value = previousView
    }
    else {
      // if previous screen is model details do not update the currentView.value so that when user click on save button it goes to the same page (if no changes) without keeping track or figuring out which screen to show
      currentView.value = 0
      emit('showModelDetails')
    }
  }
}
// METHODS-END

// WATCHERS
watch(() => createType.value, () => {
  formInitialized = false
})

watch(() => props.externalDataUpdated, (externalDataUpdated) => {
  if (externalDataUpdated) {
    formInitialized = false // set formInitialized to false when user go to model details and update the articles to carryover or new placeholder quantity
  }
})

watchEffect(async () => { // effect only based on props.visible & createType.value
  if (props.visible && !formInitialized) {
    await init()
    formInitialized = true
  }
  else if (props.visible) { // if toggle visibility to true but form is already initialized
    if ((createType.value === 'carryoverModel' || createType.value === 'copyModel') && props.externalData != null) {
      // coming back from model details without update in placeholders or articles to be carried over
      if (!previousViewsStack.includes(viewsEnum.modelDetails)) {
        previousViewsStack.push(viewsEnum.modelDetails)
      }
    }
  }
})
// WATCHERS-ENDS
</script>
