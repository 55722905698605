import type { fabric } from 'fabric'
import { isString } from 'lodash-es'
import WbArticleImage from '../../articleImage'
import WbArticleDetails from '../../articleDetails'
import WbTextBox from '../../textBox'
import WbModelDetails from '../../modelDetails'
import WbImage from '../../image'
import type { DynamicTemplateOptionValue, IDynamicTemplate } from './dynamicGridTemplateFactory'
import type MyArticle from '@/models/myArticle'
import { AttributeType } from '@/models/catalogAttribute'
import { useUserStore } from '@/store/userData'
import { getArticleAssets } from '@/api/t1/article'
import appConfig from '@/services/appConfig'

const op = {
  title: {
    name: 'Title',
    type: 'text',
    min: 1,
    max: 500,
    required: true,
    default: null,
  },
  mainGroups: {
    name: 'Main Groups',
    type: 'attributes',
    min: 1,
    max: 3,
    required: true,
    default: null,
  },
  mainGroupsPerRow: {
    name: 'Main Groups Per Row',
    type: 'number',
    min: 1,
    max: 10,
    required: true,
    default: 3,
  },
  subGroup: {
    name: 'Sub Group',
    type: 'attributes',
    min: 1,
    max: 1,
    required: true,
    default: null,
  },
  sortBy: {
    name: 'Sort By',
    type: 'attributes',
    min: 1,
    max: 5,
    required: true,
    default: null,
  },
  imageSize: {
    name: 'Image Size',
    type: 'number',
    min: 50,
    max: 200,
    required: true,
    default: 110,
  },
  mainDetailsAttributes: {
    name: 'Main Detail Attributes',
    type: 'attributes',
    min: 1,
    max: 12,
    required: true,
    default: null,
  },
  variantState: {
    name: 'Variant State',
    type: 'state',
    min: 1,
    max: 1,
    required: true,
    default: null,
  },
  variantDetailsAttributes: {
    name: 'Variant Detail Attributes',
    type: 'attributes',
    min: 1,
    max: 12,
    required: true,
    default: null,
  },
  mainGroupFontSize: {
    name: 'Main Group Font Size',
    type: 'number',
    min: 10,
    max: 100,
    required: true,
    default: 40,
  },
  subGroupFontSize: {
    name: 'Sub Group Font Size',
    type: 'number',
    min: 10,
    max: 100,
    required: true,
    default: 24,
  },
  modelFontSize: {
    name: 'Model Font Size',
    type: 'number',
    min: 10,
    max: 100,
    required: true,
    default: 18,
  },
  articleDetailsFontSize: {
    name: 'Article Details Font Size',
    type: 'number',
    min: 10,
    max: 100,
    required: true,
    default: 8,
  },
  backImageKeys: {
    name: 'Back Image Keys (comma separated)',
    type: 'text',
    min: 0,
    max: 500,
    required: false,
    default: null,
  },
  spacing: {
    name: 'Spacing',
    type: 'text',
    min: 0,
    max: 500,
    required: true,
    default: 'Comfortable',
    list: ['Comfortable', 'Compact'],
  },
} as const

interface IArticleEntry {
  articleId: number
  backImgObj?: WbImage
  imgObj: WbArticleImage
  detailsObj: WbArticleDetails
  stillVisible: boolean
}

interface IModelEntry {
  modelId: number
  modelName: string
  obj: WbModelDetails
  stillVisible: boolean
  articles: IArticleEntry[]
}

interface ISubGroupEntry {
  subGroup: string
  obj: WbTextBox
  stillVisible: boolean
  models: IModelEntry[]
}

interface IMainGroupEntry {
  mainGroup: string
  obj: WbTextBox
  stillVisible: boolean
  subGroups: ISubGroupEntry[]
  coords: { x: number, y: number, w: number, h: number }
}

export class ModelFocusTemplate implements IDynamicTemplate {
  id = 'modelFocus'
  name = 'Model Focus'
  options = op

  private spacingLayout: { [layout: string]: { mainGroupMargin: number, mainGroupPadding: number, titleBottomMargin: number, subTitleBottomMargin: number, modelTitleBottomMargin: number, modelHorizontalSpaceBetween: number, modelVerticalSpaceBetween: number, articleSpaceBetween: number } }
    = {
      Comfortable: { mainGroupMargin: 40, mainGroupPadding: 20, titleBottomMargin: 40, subTitleBottomMargin: 65, modelTitleBottomMargin: 80, modelHorizontalSpaceBetween: 200, modelVerticalSpaceBetween: 150, articleSpaceBetween: 25 },
      Compact: { mainGroupMargin: 20, mainGroupPadding: 10, titleBottomMargin: 20, subTitleBottomMargin: 30, modelTitleBottomMargin: 50, modelHorizontalSpaceBetween: 50, modelVerticalSpaceBetween: 40, articleSpaceBetween: 10 },
    }

  private articles: MyArticle[] = []
  private opt: { [config in keyof typeof op as string]: DynamicTemplateOptionValue } = {}
  public minRequiredWidth: number = 0
  public minRequiredHeight: number = 0
  private objs: IMainGroupEntry[] = []
  private maxArtriclesPerStyle: number = 0

  build(canvas: fabric.Canvas, myAttributes: Record<string, IMyAttribute>, options: { [config in keyof typeof op as string]: DynamicTemplateOptionValue }, showLabels: boolean, width: number, height: number, top, left, articles: MyArticle[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const modelDetailsPromises: Promise<WbModelDetails>[] = []
      const articleDetailsPromises: Promise<WbArticleDetails>[] = []
      const articleImagesPromises: Promise<WbArticleImage>[] = []
      const backImagePromises: Promise<WbImage | undefined>[] = []
      const artsPerModel = {} as Record<number, number>

      this.articles = articles
      const articlesIndex = this.articles.reduce((acc, article) => {
        acc[article.Id] = article; return acc
      }, {} as Record<number, MyArticle>)
      this.opt = options

      // Create the objects structure
      articles.forEach((article) => {
        const grouping: string[][] = [] as string[][]

        const modelId = article.ModelId
        const detailsAttributes = article.StateId === options.variantState as number ? options.variantDetailsAttributes as string[] : options.mainDetailsAttributes as string[]

        if (!artsPerModel.hasOwnProperty(modelId)) { artsPerModel[modelId] = 0 }
        artsPerModel[modelId] = artsPerModel[modelId] + 1;

        (options.mainGroups as string[]).forEach((mainGroup, index) => {
          if (article[mainGroup] && myAttributes[mainGroup].AttributeType === AttributeType.MultiValue && Array.isArray(article[mainGroup])) {
            (article[mainGroup] as string[]).forEach((value) => {
              if (!grouping[index]) { grouping[index] = [] as string[] }
              if (!grouping[index].includes(value)) { grouping[index].push(value) }
            })
          }
          else {
            if (!grouping[index]) { grouping[index] = [] as string[] }
            if (!grouping[index].includes(article[mainGroup]?.toString() || '[Blank]')) { grouping[index].push(article[mainGroup]?.toString() || '[Blank]') }
          }
        })

        const mainGroups = this.combine2DArray(grouping, ' / ')

        mainGroups.forEach((mainGroup) => {
          if (this.objs.findIndex(g => g.mainGroup.toLowerCase() === mainGroup.toLowerCase()) === -1) {
            const obj = new WbTextBox(mainGroup, { left: 0, top: 0, fontSize: options.mainGroupFontSize as number, fontFamily: 'Roboto', textAlign: 'center', visible: false, lock: true, preventUnlock: true, localOnly: true, excludeFromGroupSelection: true, preventDelete: true })
            this.objs.push({ mainGroup, obj, stillVisible: false, subGroups: [], coords: { x: 0, y: 0, w: 0, h: 0 } })
            canvas.add(obj)
          }

          const grp = this.objs.find(g => g.mainGroup.toLowerCase() === mainGroup.toLowerCase())!
          grp.stillVisible = true

          const subGroupAttr = options.subGroup![0] as string
          let subGroups = [] as string[]
          if (myAttributes[subGroupAttr].AttributeType === AttributeType.MultiValue && Array.isArray(article[subGroupAttr])) {
            subGroups = article[subGroupAttr] as string[]
          }
          else {
            subGroups = [article[subGroupAttr]?.toString() || '[Blank]']
          }

          subGroups.forEach((subGroup) => {
            if (grp.subGroups.findIndex(sg => sg.subGroup.toLowerCase() === subGroup.toLowerCase()) === -1) {
              const obj = new WbTextBox(subGroup, { left: 0, top: 0, fontSize: options.subGroupFontSize as number, fontFamily: 'Roboto', visible: false, lock: true, preventUnlock: true, localOnly: true, excludeFromGroupSelection: true, preventDelete: true })
              grp.subGroups.push({ subGroup, obj, stillVisible: false, models: [] })
              canvas.add(obj)
            }

            const subGrp = grp.subGroups.find(sg => sg.subGroup.toLowerCase() === subGroup.toLowerCase())!
            subGrp.stillVisible = true

            if (subGrp.models.findIndex(m => m.modelId === modelId) === -1) {
              const newModel = { modelId, obj: {} as WbModelDetails, stillVisible: false, articles: [], modelName: article.ModelName.toLowerCase() }
              subGrp.models.push(newModel)
              modelDetailsPromises.push(
                WbModelDetails.loadModelDetails(
                  article,
                  { left: 0, top: 0, fontSize: options.modelFontSize as number, visible: true, showLabels: false, lock: true, preventUnlock: true, localOnly: true, attributes: ['ModelName'], excludeFromGroupSelection: true, preventDelete: true },
                ).then((md) => {
                  newModel.obj = md
                  return md
                }),
              )
            }

            const model = subGrp.models.find(m => m.modelId === modelId)!
            model.modelName = article.ModelName.toLowerCase()
            model.stillVisible = true
            const foundArtIndex = model.articles.findIndex(a => a.articleId === article.Id)

            if (foundArtIndex === -1) {
              const newArt: IArticleEntry = { articleId: article.Id, imgObj: {} as WbArticleImage, detailsObj: {} as WbArticleDetails, backImgObj: undefined, stillVisible: false }
              model.articles.push(newArt)
              articleDetailsPromises.push(
                WbArticleDetails.loadArticleDetails(
                  article,
                  myAttributes,
                  { left: 0, top: 0, width: options.imageSize as number, showLabels, visible: true, lock: true, preventUnlock: true, localOnly: true, attributes: detailsAttributes, fontSize: 8, fontFamily: 'Roboto', fill: '#000000', excludeFromGroupSelection: true, preventDelete: true },
                ).then((details) => {
                  newArt.detailsObj = details
                  return details
                }),
              )
              articleImagesPromises.push(
                WbArticleImage.loadArticleImage(
                  article,
                  500,
                  500,
                  { top: 0, left: 0, catalogCode: article.CatalogCode, articleId: article.Id, objectId: article.CatalogArticleId, isRequest: article._IsRequestArticle, visible: true, lock: true, preventUnlock: true, localOnly: true, preventDelete: true, noImageObjectProperties: { border: { borderColor: '#808080', strokeWidth: 1 }, backgroundColor: '#e6e6e6', attributes: ['ArticleNumber'] } },
                ).then((img) => {
                  newArt.imgObj = img
                  return img
                }),
              )

              if (options.backImageKeys && isString(options.backImageKeys) && options.backImageKeys.length > 0) {
                backImagePromises.push(this.loadBackImage(article, options.backImageKeys).then((img) => {
                  if (img) {
                    newArt.backImgObj = img
                  }
                  return img
                }).catch(() => { return undefined }))
              }
            }
            else {
              if (model.articles[foundArtIndex].detailsObj.showLabels !== showLabels) {
                model.articles[foundArtIndex].detailsObj.setProp('showLabels', { showLabels })
              }
              if (options.backImageKeys && isString(options.backImageKeys) && options.backImageKeys.length > 0) {
                backImagePromises.push(this.loadBackImage(article, options.backImageKeys, model.articles[foundArtIndex].backImgObj).then((img) => {
                  if (img) {
                    model.articles[foundArtIndex].backImgObj = img
                  }
                  return img
                }))
              }
            }

            const art = model.articles.find(a => a.articleId === article.Id)!
            art.stillVisible = true
          })
        })
      })

      // Set max number of articles per model
      this.maxArtriclesPerStyle = Math.max(...Object.values(artsPerModel))

      // Remove objects that are no longer needed
      for (let maingrpIdx = 0; maingrpIdx < this.objs.length; maingrpIdx++) {
        const mainGrp = this.objs[maingrpIdx]

        for (let subgrpIdx = 0; subgrpIdx < mainGrp.subGroups.length; subgrpIdx++) {
          const subGrp = mainGrp.subGroups[subgrpIdx]

          for (let modelIdx = 0; modelIdx < subGrp.models.length; modelIdx++) {
            const model = subGrp.models[modelIdx]

            for (let artIdx = 0; artIdx < model.articles.length; artIdx++) {
              const art = model.articles[artIdx]

              if (!art.stillVisible) {
                canvas.remove(art.imgObj)
                canvas.remove(art.detailsObj)
                if (art.backImgObj) {
                  canvas.remove(art.backImgObj)
                }

                model.articles.splice(artIdx, 1)
                artIdx--
              }
              art.stillVisible = false
            }

            if (!model.stillVisible) {
              canvas.remove(model.obj)
              subGrp.models.splice(modelIdx, 1)
              modelIdx--
            }
            model.stillVisible = false
          }

          if (!subGrp.stillVisible) {
            canvas.remove(subGrp.obj)
            mainGrp.subGroups.splice(subgrpIdx, 1)
            subgrpIdx--
          }
          subGrp.stillVisible = false
        }

        if (!mainGrp.stillVisible) {
          canvas.remove(mainGrp.obj)
          this.objs.splice(maingrpIdx, 1)
          maingrpIdx--
        }
        mainGrp.stillVisible = false
      }

      // Sort the objects
      const sortByProps = options.sortBy as string[]
      this.objs.sort((a, b) => a.mainGroup.localeCompare(b.mainGroup))
      this.objs.forEach((mainGrp) => {
        mainGrp.subGroups.sort((a, b) => a.subGroup.localeCompare(b.subGroup))
        mainGrp.subGroups.forEach((subGrp) => {
          subGrp.models.sort((a, b) => a.modelName.localeCompare(b.modelName))
          subGrp.models.forEach((model) => {
            model.articles.sort((a, b) => sortByProps.map(p => (articlesIndex[a.articleId][p]?.toString() || '[Blank]').localeCompare(articlesIndex[b.articleId][p]?.toString() || 'Blank')).reduce((acc, val) => acc === 0 ? val : acc, 0))
          })
        })
      })

      // Add the new article/model objects to canvas
      canvas.renderOnAddRemove = false
      Promise.all(modelDetailsPromises).then((modelDetails) => {
        modelDetails.forEach((md) => {
          md.visible = false
          canvas.add(md)
        })

        Promise.all(articleDetailsPromises).then((articleDetails) => {
          articleDetails.forEach((details) => {
            details.visible = false
            canvas.add(details)
          })

          Promise.all(articleImagesPromises).then((articleImages) => {
            articleImages.forEach((img) => {
              img.scale(options.imageSize as number * 0.002)
              img.visible = false
              canvas.add(img)
            })

            Promise.all(backImagePromises).then((backImages) => {
              backImages.forEach((img) => {
                if (img) {
                  img.scale(options.imageSize as number * 0.002)
                  img.visible = false
                  canvas.add(img)
                }
              })
              const res = this.recalculate(width, height, top, left)
              canvas.renderOnAddRemove = true
              resolve(res)
            })
          })
        })
      }).catch((err) => {
        reject(err)
      })
    })
  }

  private loadBackImage(article: MyArticle, keys: string, imgObj?: WbImage): Promise<WbImage | undefined> {
    const userStore = useUserStore()

    return new Promise<WbImage | undefined>((resolve) => {
      const backImageKeysArr = keys.split(',').map(k => k.trim().toLowerCase())
      if (backImageKeysArr.length > 0) {
        const catalogDetails = userStore.linkedCatalogDetails[article.CatalogCode] || userStore.activeCatalog
        getArticleAssets(catalogDetails.DuneContext, catalogDetails.ContextKey, article.ArticleNumber, backImageKeysArr).then((assets) => {
          if (assets.length > 0) {
            const asset = assets.findIndex(a => backImageKeysArr.includes(a.Key.toLowerCase()))
            if (asset === -1) {
              resolve(undefined)
            }
            else {
              const src = `${appConfig.AssetsUrl}/assets/content/${assets[0].StorageFile}?ContextKey=${encodeURIComponent(catalogDetails.ContextKey)}&f=webp&w=500&h=500&trim=true`
              if (imgObj) {
                imgObj.setSrc(src)
                resolve(imgObj)
              }
              else {
                WbImage.loadFromUrl(
                  src,
                  { left: 0, top: 0, width: 500, height: 500, visible: false, lock: true, preventUnlock: true, localOnly: true, preventDelete: true, selectable: false },
                ).then((img) => {
                  resolve(img)
                }).catch(() => {
                  resolve(undefined)
                })
              }
            }
          }
          else {
            resolve(undefined)
          }
        }).catch(() => {
          resolve(undefined)
        })
      }
      else {
        resolve(undefined)
      }
    })
  }

  /**
   * Recalculate the layout of the main group
   * @param mainGroup The main group object
   * @param width The width available for the main group
   * @param startTop The top position to start the layout
   * @param startLeft The left position to start the layout
   * @returns The total height of this main group
   */
  private recalculateMainGroup(mainGroup: IMainGroupEntry, width: number, startTop: number, startLeft: number): number {
    const layout = this.spacingLayout[this.opt.spacing as string]
    const pos = { left: startLeft + layout.mainGroupPadding, top: startTop + layout.mainGroupPadding }
    mainGroup.obj.set({ left: pos.left, top: pos.top, width: width - layout.mainGroupPadding * 2, visible: true })
    pos.top += (mainGroup.obj.height || 0) + layout.titleBottomMargin

    for (const subGroup of mainGroup.subGroups) {
      pos.top = this.recalculateSubGroup(subGroup, width - layout.mainGroupPadding * 2, pos.top, startLeft + layout.mainGroupPadding)
      pos.top += layout.subTitleBottomMargin
    }

    return pos.top - startTop
  }

  /**
   * Recalculate the layout of the sub group
   * @param subGroup The sub group object
   * @param width The width available for the sub group
   * @param startTop The top position to start the layout
   * @param startLeft The left position to start the layout
   * @returns The new top position after the sub group layout
   */
  private recalculateSubGroup(subGroup: ISubGroupEntry, width: number, startTop: number, startLeft: number): number {
    const layout = this.spacingLayout[this.opt.spacing as string]
    const pos = { left: startLeft, top: startTop }
    subGroup.obj.set({ left: pos.left, top: pos.top, width, visible: true })
    pos.top += (subGroup.obj.height || 0) + layout.subTitleBottomMargin

    const models: IModelEntry[] = []
    let rowWidth = 0
    for (const model of subGroup.models) {
      const modelWidth = model.articles.length * (this.opt.imageSize as number) + (model.articles.length - 1) * layout.articleSpaceBetween
      if (rowWidth + modelWidth + (models.length * layout.modelHorizontalSpaceBetween) > width) {
        pos.top = this.recalculateModelsInRow(models, width, pos.top, startLeft)
        models.length = 0
        rowWidth = modelWidth
      }
      rowWidth += modelWidth + (models.length * layout.modelHorizontalSpaceBetween)
      models.push(model)
    }

    if (models.length > 0) {
      pos.top = this.recalculateModelsInRow(models, width, pos.top, startLeft)
    }

    return pos.top
  }

  /**
   * Recalculate the layout of the models in a row
   * @param modelsInRow The models to layout
   * @param width The width available for the models
   * @param top The top position to start the layout
   * @param left The left position to start the layout
   * @returns The new top position after the models layout
   */
  recalculateModelsInRow(modelsInRow: IModelEntry[], width: number, top: number, left: number) {
    const layout = this.spacingLayout[this.opt.spacing as string]
    const pos = { left, top }
    let maxArticleDetailsHeight = 0

    // Calculate the space between the models if they are evenly spaced in the width without taking into consideration the layout.modelHorizontalSpaceBetween
    const spaceBetweenModels = (width - modelsInRow.reduce((acc, model) => acc + model.articles.length * (this.opt.imageSize as number) + (model.articles.length - 1) * layout.articleSpaceBetween, 0)) / (modelsInRow.length + 1)
    pos.left += spaceBetweenModels

    modelsInRow.forEach((model) => {
      // Get the total width of all articles of this model
      const modelWidth = model.articles.length * (this.opt.imageSize as number) + (model.articles.length - 1) * layout.articleSpaceBetween + layout.modelHorizontalSpaceBetween
      // Center the model title
      model.obj.set({ width: modelWidth, left: pos.left - layout.modelHorizontalSpaceBetween / 2, top: pos.top })

      // Reposition the article objects
      model.articles.forEach((article) => {
        if (article.backImgObj) {
          article.backImgObj.set({ left: pos.left + 25, top: pos.top + layout.modelTitleBottomMargin - 25 })
        }
        article.imgObj.set({ left: pos.left, top: pos.top + layout.modelTitleBottomMargin })
        article.imgObj.bringToFront()
        article.detailsObj.set({ left: pos.left, top: pos.top + layout.modelTitleBottomMargin + (this.opt.imageSize as number) + 5 })
        pos.left += (this.opt.imageSize as number) + layout.articleSpaceBetween
        maxArticleDetailsHeight = Math.max(maxArticleDetailsHeight, article.detailsObj.height || 0)
      })
      pos.left += spaceBetweenModels - layout.articleSpaceBetween
    })
    pos.top += layout.modelTitleBottomMargin + (this.opt.imageSize as number) + maxArticleDetailsHeight + layout.modelVerticalSpaceBetween
    return pos.top
  }

  /**
   * Recalculate the layout of the objects
   * @param width The width available for the layout
   * @param height The height available for the layout
   * @param startTop The top position to start the layout
   * @param startLeft The left position to start the layout
   * @returns True if the layout fits in the available space, false otherwise
   */
  recalculate(width: number, height: number, startTop: number, startLeft: number) {
    const layout = this.spacingLayout[this.opt.spacing as string]
    this.minRequiredWidth = (this.maxArtriclesPerStyle * (this.opt.imageSize as number) + (this.maxArtriclesPerStyle - 1) * layout.articleSpaceBetween + layout.mainGroupPadding * 2) * (this.opt.mainGroupsPerRow as number) + (this.opt.mainGroupsPerRow as number - 1) * layout.mainGroupMargin

    if (width < this.minRequiredWidth) {
      return false
    }
    const mainGroupsPerRow = this.opt.mainGroupsPerRow as number
    const actualGroupsPerRow = Math.min(this.objs.length, mainGroupsPerRow)
    const mainGroupWidth = (width - (actualGroupsPerRow - 1) * layout.mainGroupMargin) / actualGroupsPerRow

    // We have enough width, let's begin the layout
    const pos = { left: startLeft, top: startTop }
    let maxGroupHeight = 0
    this.objs.forEach((mainGroup, index) => {
      const mainGroupHeight = this.recalculateMainGroup(mainGroup, mainGroupWidth, pos.top, pos.left)
      mainGroup.coords = { x: pos.left - startLeft, y: pos.top - startTop, w: mainGroupWidth, h: 0 }
      const currentPositionInRow = (index + 1) % mainGroupsPerRow || mainGroupsPerRow

      maxGroupHeight = Math.max(maxGroupHeight, mainGroupHeight)
      if (currentPositionInRow === mainGroupsPerRow || index === this.objs.length - 1) {
        // Set the height of all main groups in this row
        this.objs.slice(index - currentPositionInRow + 1, index + 1).forEach((group) => {
          group.coords.h = maxGroupHeight
        })

        pos.top += maxGroupHeight + layout.mainGroupMargin
        pos.left = startLeft
        maxGroupHeight = 0
      }
      else {
        pos.left += mainGroupWidth + layout.mainGroupMargin
      }
    })

    this.minRequiredHeight = pos.top - startTop - layout.mainGroupMargin

    return (this.minRequiredHeight <= height)
  }

  propagateEvent(eventName: string, event: any) {
    this.objs.forEach((mainGroup) => {
      mainGroup.obj.fire(eventName, event)
      mainGroup.subGroups.forEach((subGroup) => {
        subGroup.obj.fire(eventName, event)
        subGroup.models.forEach((model) => {
          model.obj.fire(eventName, event)
          model.articles.forEach((article) => {
            article.imgObj.fire(eventName, event)
            article.detailsObj.fire(eventName, event)
          })
        })
      })
    })
  }

  hideObjects(hidden: boolean) {
    this.objs.forEach((mainGroup) => {
      mainGroup.obj.set('visible', !hidden)
      mainGroup.subGroups.forEach((subGroup) => {
        subGroup.obj.set('visible', !hidden)
        subGroup.models.forEach((model) => {
          model.obj.set('visible', !hidden)
          model.articles.forEach((article) => {
            article.imgObj.set('visible', !hidden)
            article.detailsObj.set('visible', !hidden)
            if (article.backImgObj) {
              article.backImgObj.set('visible', !hidden)
            }
          })
        })
      })
    })
  }

  destroy(canvas: fabric.Canvas) {
    this.objs.forEach((mainGroup) => {
      canvas.remove(mainGroup.obj)
      mainGroup.subGroups.forEach((subGroup) => {
        canvas.remove(subGroup.obj)
        subGroup.models.forEach((model) => {
          canvas.remove(model.obj)
          model.articles.forEach((article) => {
            canvas.remove(article.imgObj)
            canvas.remove(article.detailsObj)
            if (article.backImgObj) {
              canvas.remove(article.backImgObj)
            }
          })
        })
      })
    })
  }

  render(ctx: CanvasRenderingContext2D, width: number, height: number) {
    // Draw a white rectangle with grey border for each main group
    ctx.fillStyle = '#ffffff'
    ctx.strokeStyle = '#808080'
    ctx.lineWidth = 1
    this.objs.forEach((mainGroup) => {
      ctx.fillRect(mainGroup.coords.x - width / 2, mainGroup.coords.y - height / 2, mainGroup.coords.w, mainGroup.coords.h)
      ctx.strokeRect(mainGroup.coords.x - width / 2, mainGroup.coords.y - height / 2, mainGroup.coords.w, mainGroup.coords.h)
    })
  }

  getTitle() {
    return this.opt.title as string || '[Model Focus]'
  }

  private combine2DArray(arr: string[][], separator = '') {
    if (!arr || arr.length === 0) { return [] }

    function backtrack(index, path, result) {
      if (index === arr.length) {
        result.push(path.join(separator))
        return
      }

      for (let i = 0; i < arr[index].length; i++) {
        path.push(arr[index][i])
        backtrack(index + 1, path, result)
        path.pop()
      }
    }

    const result: string[] = []
    backtrack(0, [], result)
    return result
  }
}
