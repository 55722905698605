import { assignIn, pick } from 'lodash-es'
import { v4 as guid } from 'uuid'
import { fabric } from 'fabric'
import { whiteboardConstants } from '@/models/constants'

interface IWbImageOptions extends fabric.IImageOptions, IWbObjectProps {
  id?: string
  lock?: boolean
  preventUnlock?: boolean
  preventDelete?: boolean
}

export default class WbImage extends fabric.Image implements IWbObject {
  public id: string
  public type = whiteboardConstants.objectTypes.image
  public lock: boolean
  public preventUnlock: boolean
  public connectable: boolean
  public localOnly?: boolean | undefined
  public excludeFromGroupSelection?: boolean | undefined
  public preventDelete?: boolean

  public editableProps: Record<string, IWbObjectProp> = {
    scale: { name: 'size', type: 'scale', editable: true },
    lock: { name: 'Lock', type: 'lock', editable: true, editableWhenLocked: true },
  }

  public actions: Record<string, IWObjectActions> = {
    selectSimilar: { action: 'selectSimilar', label: 'Select Similar', faicon: 'fa-light fa-check-double', showInSubMenu: true },
    bringFront: { action: 'bringFront', label: 'Bring to Front', faicon: 'fa-light fa-bring-front', showInSubMenu: true },
    sendBack: { action: 'sendBack', label: 'Send to Back', faicon: 'fa-light fa-send-back', showInSubMenu: true },
    group: { action: 'group', label: 'Group', faicon: 'fa-light fa-object-group', showInSubMenu: true, multiple: true },
    cloneAsImage: { action: 'cloneAsImage', label: 'Clone As Image', faicon: 'fa-light fa-clone', showInSubMenu: true },
    crop: { action: 'cropImage', label: 'Crop Image', faicon: 'fa-light fa-crop', showInSubMenu: true },
    delete: { action: 'delete', label: 'Remove', faicon: 'fa-light fa-trash-can', showInSubMenu: true },
  }

  private cropRect?: fabric.Rect
  constructor(e: HTMLImageElement, opt?: IWbImageOptions) {
    super(e, opt)
    this.id = opt?.id || guid()
    this.lock = opt?.lock || false
    this.connectable = true
    this.preventUnlock = opt?.preventUnlock || false
    this.localOnly = opt?.localOnly || false
    this.excludeFromGroupSelection = opt?.excludeFromGroupSelection || false
    this.preventDelete = opt?.preventDelete || false
    this.setLock(this.lock)

    this.stateProperties = this.stateProperties?.concat(['lock'])
  }

  setProp(prop: string, value: any) {
    switch (prop) {
      case 'scale':
        if (value.scale <= 100 && this.width && this.height) {
          const scaleX = value.scaleX ? value.scaleX / 100 : value.scale / 100
          const scaleY = value.scaleY ? value.scaleY / 100 : value.scale / 100

          this.set('scaleX', scaleX)
          this.set('scaleY', scaleY)
        }
        break
      case 'lock':
        this.set('lock', value.lock)
        this.setLock(value.lock)
        break
      default:
        console.warn('Attempting to set unsupported WbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this })
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'scale':
        result.scale = Math.round((this.scaleX || 1) * 100)
        break
      case 'lock':
        result.lock = this.lock
        break
      default:
        console.warn('Attempting to get unsupported WbObjectProp', prop)
    }
    return result
  }

  setLock(lock: boolean) {
    this.set('lockMovementX', lock)
    this.set('lockMovementY', lock)
    this.set('lockRotation', lock)
    this.set('lockScalingFlip', lock)
    this.set('lockScalingX', lock)
    this.set('lockScalingY', lock)
    this.set('hasControls', !lock)
  }

  override toObject() {
    const propsToPluck = [
      'id',
      'type',
      'top',
      'left',
      'width',
      'height',
      'angle',
      'scaleX',
      'scaleY',
      'lock',
      'version',
      'localOnly',
      'preventUnlock',
      'cropX',
      'cropY',
    ]
    return { ...pick(this, propsToPluck), src: this.getSrc() }
  }

  enableCropping() {
    // Create the cropping rectangle (resizable)
    this.cropRect = new fabric.Rect({
      top: this.top,
      left: this.left,
      angle: this.angle,
      width: this.getScaledWidth(),
      height: this.getScaledHeight(),
      stroke: 'blue',
      strokeWidth: 2,
      strokeDashArray: [5, 5],
      fill: 'rgba(0,0,0,0.3)',
      lockRotation: true,
    })
    const cropX = this.cropX || 0
    const cropY = this.cropY || 0
    const width = this.width || 0
    const height = this.height || 0

    const originalSize = this.getOriginalSize()
    this.set({
      cropX: null,
      cropY: null,
      left: this.left! - cropX * this.scaleX!,
      top: this.top! - cropY * this.scaleY!,
      width: originalSize.width,
      height: originalSize.height,
      dirty: false,
    } as unknown as Partial<this>)
    this.cropRect.set({
      left: this.left! + cropX! * this.scaleX!,
      top: this.top! + cropY! * this.scaleY!,
      width: width * this.scaleX!,
      height: height * this.scaleY!,
      dirty: false,
    })
    this.restrictCropRect()
    this.selectable = false
    this.canvas?.add(this.cropRect)
    this.canvas?.setActiveObject(this.cropRect)
    this.canvas?.requestRenderAll()

    this.cropRect.on('modified', () => {
      if (!this.cropRect) { return }

      this.canvas?.remove(this.cropRect)
      const cropX = (this.cropRect.left! - this.left!) / this.scaleX!
      const cropY = (this.cropRect.top! - this.top!) / this.scaleY!
      const width = (this.cropRect.width! * this.cropRect.scaleX!) / this.scaleX!
      const height = (this.cropRect.height! * this.cropRect.scaleY!) / this.scaleY!

      // crop
      this.set({
        cropX,
        cropY,
        width,
        height,
        top: this.top! + cropY * this.scaleY!,
        left: this.left! + cropX * this.scaleX!,
        selectable: true,
        // cropped: 1,
      } as unknown as Partial<this>)
      this.selectable = true
      this.canvas?.requestRenderAll()
      this.canvas?.fire('object:modified', { target: this })
    })
  }

  restrictCropRect() {
    this.canvas?.on('object:moving', (event) => {
      if (event.target !== this.cropRect && !this.cropRect) { return }

      // Image boundaries
      const imgLeft = this.left
      const imgTop = this.top
      const imgRight = imgLeft! + this.width! * this.scaleX!
      const imgBottom = imgTop! + this.height! * this.scaleY!

      // Restrict movement within the image
      if (this.cropRect) {
        this.cropRect.left = Math.max(imgLeft!, Math.min(this.cropRect.left!, imgRight - this.cropRect.width! * this.cropRect.scaleX!))
        this.cropRect.top = Math.max(imgTop!, Math.min(this.cropRect.top!, imgBottom - this.cropRect.height! * this.cropRect.scaleY!))
      }
    })

    this.canvas?.on('object:scaling', (event) => {
      if (!this.cropRect || event.target !== this.cropRect) { return }
      const cropWidth = event.target.width! * event.target.scaleX!
      const cropHeight = event.target.height! * event.target.scaleY!
      // Image boundaries
      const imgLeft = this.left || 0
      const imgTop = this.top || 0
      const imgRight = imgLeft + (this.width! * this.scaleX!)
      const imgBottom = imgTop + (this.height! * this.scaleY!)
      if (this.left! > this.cropRect.left!) {
        this.cropRect.left = this.left
      }
      if (this.top! > this.cropRect.top!) {
        this.cropRect.top = this.top
      }
      // Restrict scaling within the image
      if (this.cropRect.left! + cropWidth > imgRight) {
        this.cropRect.scaleX = (imgRight - this.cropRect.left!) / this.cropRect.width!
      }
      if (this.cropRect.top! + cropHeight > imgBottom) {
        this.cropRect.scaleY = (imgBottom - this.cropRect.top!) / this.cropRect.height!
      }

      // Prevent shrinking below a minimum size
      this.cropRect.scaleX = Math.max(this.cropRect.scaleX!, 0.1)
      this.cropRect.scaleY = Math.max(this.cropRect.scaleY!, 0.1)
    })
  }

  static fromObject(object: WbImage, callback?: Function) {
    // eslint-disable-next-line ts/ban-ts-comment
    // @ts-expect-error
    this.loadFromUrl(object.src, object).then((v) => { callback && callback(v) })
  }

  static loadFromUrl(url: string, opt?: IWbImageOptions) {
    return new Promise<WbImage>((resolve, reject) => {
      fabric.util.loadImage(url, (oImg) => {
        const img = new Image()
        img.onload = () => {
          const options = assignIn({ top: 10 + img.height / 2, left: 10 + img.width / 2 }, opt)
          // oImg.crossOrigin = 'anonymous'
          resolve(new WbImage(oImg, options))
        }
        img.onerror = (err) => {
          console.error('Error loading image', url)
          reject(err)
        }
        img.src = url
        // img.crossOrigin = 'anonymous'
      }, null, 'anonymous')
    })
  }

  static getJsonObject(url, scaleValue, opt?: IWbImageOptions) {
    const o = opt
    if (o && scaleValue.scale <= 100) {
      const scale = scaleValue.scale / 100
      o.scaleX = scaleValue.scaleX ? scaleValue.scaleX / 100 : scale
      o.scaleY = scale
    }
    const img = new Image()
    const obj = new WbImage(img, o).toObject()
    obj.src = url
    return Promise.resolve(obj)
  }
}

const f: any = fabric
f.WbImage = WbImage
