import { fabric } from 'fabric'
import { v4 as guid } from 'uuid'
import type { Subscription } from 'dexie'
import { liveQuery } from 'dexie'
import { debounce, isArray, isString, pick } from 'lodash-es'
import { DynamicGridTemplateFactory } from './templates/dynamicGrid/dynamicGridTemplateFactory'
import type { DynamicTemplateOptionValue, IDynamicTemplate } from './templates/dynamicGrid/dynamicGridTemplateFactory'
import WbTextBox from './textBox'
import Utility from './utility'
import { whiteboardConstants } from '@/models/constants'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'
import type MyArticle from '@/models/myArticle'
import { FilterCriteria } from '@/models/filterCriteria'

interface IWbDynamicOptions extends fabric.IRectOptions, IWbObjectProps {
  filterJson?: string[]
  templateOptions?: { templateId: string, options: Record<string, DynamicTemplateOptionValue> }
  visibleArticleIds?: number[]
  attributesSeparator?: string
  attributePlaceHolder?: string
  hideInactive?: boolean
  preventDelete?: boolean
  showLabels?: boolean
}

export default class WbDynamic extends fabric.Rect implements IWbObject {
  public id: string
  public type = whiteboardConstants.objectTypes.dynamic
  public lock: boolean
  public showLabels: boolean
  public preventUnlock: boolean
  public connectable: boolean = false
  public filterJson?: string[]
  public visibleArticleIds?: number[]
  public templateOptions?: { templateId: string, options: Record<string, DynamicTemplateOptionValue> }
  public attributesSeparator?: string
  public attributePlaceHolder?: string
  public imageSize = 100
  public hideInactive = false
  public editableProps: Record<string, IWbObjectProp> = {
    lock: { name: 'Lock', type: 'lock' },
    addDiscussion: { name: 'add comment', type: 'addDiscussion' },
    showLabels: { name: 'Show labels', type: 'showLabels' },
    hideInactive: { name: 'hide inactive', type: 'hideInactive' },
  }

  public actions: Record<string, IWObjectActions> = {
    editSettings: { action: 'editDynamicObjectSettings', label: 'Edit Settings', faicon: 'fa-light fa-gear', showInSubMenu: true },
    selectSimilar: { action: 'selectSimilar', label: 'Select Similar', faicon: 'fa-light fa-check-double', showInSubMenu: true },
    cloneAsImage: { action: 'cloneAsImage', label: 'Clone As Image', faicon: 'fa-light fa-clone', showInSubMenu: true },
    delete: { action: 'delete', label: 'Remove', faicon: 'fa-light fa-trash-can', showInSubMenu: true },
  }

  public subscription?: Subscription
  public articles: MyArticle[] = []
  public preventDelete?: boolean

  public _isMoving = false
  public _isOverflow = false
  public _isSelected = false
  public _isFirstTimeLoading = false
  public _isLoading = false

  private overflowMessage = ''
  private _template: IDynamicTemplate
  private _padding = 25
  private titleObj: WbTextBox | null = null
  private canvasZoom = 1

  constructor(opt?: IWbDynamicOptions) {
    super(opt || {})
    this.id = opt?.id || guid()
    this.templateOptions = opt?.templateOptions || { templateId: 'modelFocus', options: {} }
    this.attributesSeparator = opt?.attributesSeparator || '\n'
    this.attributePlaceHolder = opt?.attributePlaceHolder || ''
    this.lock = opt?.lock || false
    this.visibleArticleIds = opt?.visibleArticleIds || []
    this.subscription = undefined
    this.hideInactive = opt?.hideInactive || false
    this.showLabels = opt?.showLabels || false

    this.overflowMessage = 'The frame is too small to display all articles'
    this.objectCaching = false
    this.preventUnlock = opt?.preventUnlock || false

    this.stroke = '#e2e2e2'
    this.strokeWidth = 1
    this.preventDelete = opt?.preventDelete || false
    this.selectable = false

    this.setControlsVisibility({ mtr: false, mt: false, mb: false, tr: false, br: false, bl: false, tl: false })
    this._template = DynamicGridTemplateFactory.getInstance().getTemplate(this.templateOptions.templateId || 'modelFocus')

    Utility.makeObjectSelectableByBorderWhenLocked(this)

    this.on('added', () => {
      console.log('Object added', this)
      this.applyFilter()
      if (this.canvas) {
        this.titleObj = new WbTextBox(this.name || '[Dynamic Grid]', { fontFamily: 'Roboto', fontSize: 18, fill: '#5c5c5c', selectable: false, localOnly: true, lock: true, truncate: true, excludeFromGroupSelection: true })
        this.canvas.add(this.titleObj)
        this.titleObj.set('selectable', false)
        this.titleObj.setCoords()
        this.titleObj.on('mousedown', () => {
          this.canvas?.setActiveObject(this)
        })
        this.adjustRelativeToZoom()
      }
    })

    this.on('removed', () => {
      this._template.destroy(this.canvas!)

      if (this.subscription && !this.subscription.closed) {
        this.subscription.unsubscribe()
      }
      if (this.canvas && this.titleObj) {
        this.canvas.remove(this.titleObj)
        this.titleObj = null
      }
    })

    this.on('moving', () => {
      this._isMoving = true
      this._template.hideObjects(true)
      this.repositionTitle()
      this._template?.propagateEvent('moving', { target: this })
    })

    this.on('mouseup', () => {
      if (this._isMoving) {
        this._isMoving = false
        this.backgroundColor = 'transparent'
        this.recalcObjectsPosition()
        this._template?.propagateEvent('mouseup', { target: this })
      }
    })

    this.on('selected', () => {
      this._isSelected = true
    })

    this.on('deselected', () => {
      this._isSelected = false
    })

    this.on('zoom', () => {
      this.adjustRelativeToZoom()
    })

    const debouncedRecalc = debounce(() => {
      this.recalcObjectsPosition()
    }, 500)

    this.on('scaling', () => {
      this.width = this.width! * this.scaleX!
      if (this._template && this._template.minRequiredWidth + this._padding * 2 > this.width) {
        this.width = this._template.minRequiredWidth + this._padding * 2
      }
      this.height = this.height! * this.scaleY!
      if (this._template && this._template.minRequiredHeight + this._padding * 2 > this.height) {
        this.height = this._template.minRequiredHeight + this._padding * 2
      }
      this.scaleX = 1
      this.scaleY = 1

      this._template.hideObjects(true)
      debouncedRecalc()
    })

    this.setLock(this.lock)
  }

  repositionTitle() {
    if (this.titleObj) {
      const tm = this.calcTransformMatrix()
      const pos = fabric.util.qrDecompose(tm)

      this.titleObj.set('left', pos.translateX - this.width! / 2)
      this.titleObj.set('width', this.width)
      this.titleObj.set('top', pos.translateY - this.height! / 2 - (this.titleObj.height || 0) - Math.min(10, 10 / this.canvasZoom))
      this.titleObj.setCoords()
    }
  }

  adjustRelativeToZoom() {
    const newZoom = this.canvas?.getZoom() || 1
    if (this.canvasZoom !== newZoom) {
      this.canvasZoom = newZoom
      if ((this.width || 0) * newZoom < 100) {
        this.titleObj?.set('visible', false)
      }
      else {
        this.titleObj?.set('visible', true)
        this.titleObj?.set('fontSize', Math.max(12, 18 / newZoom))
        this.repositionTitle()
      }
    }
  }

  getFilter() {
    const f = this.filterJson?.map(v => new FilterCriteria(JSON.parse(v))) || []
    const statusFilter = f.find(itm => itm.attribute === 'Status')
    if (statusFilter) {
      statusFilter.statusVal = this.hideInactive ? 1 : -1
      statusFilter.exclude = false
      statusFilter.mode = 8
    }
    else {
      f.push(new FilterCriteria({ attribute: 'Status', statusVal: this.hideInactive ? 1 : -1, exclude: false, mode: 8 }))
    }
    return f
  }

  applyFilter() {
    const filter = this.getFilter()
    if (!filter || !isArray(filter) || filter.length === 0) { return }
    const userStore = useUserStore()
    this.doLoading(true)

    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe()
    }

    const observable = liveQuery(async () => await appConfig.DB!.getArticlesByCriteria(userStore.activeCatalog!, userStore.myAttributes!, filter, true, userStore.currentUsername, userStore.currentCustomer, userStore.currentCustomerSegmentations))
    const subscription = observable.subscribe(async (articles) => {
      this._isFirstTimeLoading = false
      appConfig.DB!.buildMyArticles(articles, userStore.activeCatalog!, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, true).then((myArticles) => {
        this.updateArticles(myArticles)
        // this.rebuildGroups()
      })
    })
    this.subscription = subscription
  }

  updateArticles(articles: MyArticle[]) {
    const userStore = useUserStore()

    this.canvas!.renderOnAddRemove = false
    this._template.build(this.canvas!, userStore.myAttributes!, this.templateOptions?.options || {}, this.showLabels, this.width! - this._padding * 2, this.height! - this._padding * 2, this.top! + this._padding, this.left! + this._padding, articles).then((res) => {
      this.doLoading(false)
      this.titleObj?.set('text', this._template.getTitle())
      if (!res) {
        this.set({ width: Math.max(this._template.minRequiredWidth + this._padding * 2, this.width || 0), height: Math.max(this._template.minRequiredHeight + this._padding * 2, this.height || 0) } as any)
        this.recalcObjectsPosition()
      }
      this.recalcHeight()
      this._template.hideObjects(this._isOverflow)
      this.canvas!.renderOnAddRemove = true
      this.canvas!.requestRenderAll()
      setTimeout(() => {
        this._template?.propagateEvent('moving', { target: this })
        this.canvas!.requestRenderAll()
        this.canvas!.renderAll()
        console.log('rendered', this.canvas!.width!)
      }, 1000)
    })
  }

  recalcObjectsPosition() {
    const tm = this.calcTransformMatrix()
    const pos = fabric.util.qrDecompose(tm)

    // this._isOverflow = !this._template.recalculate(this.width! - this._padding * 2, this.height! - this._padding * 2, this.top! + this._padding, this.left! + this._padding)
    this._isOverflow = !this._template.recalculate(this.width! - this._padding * 2, this.height! - this._padding * 2, pos.translateY - this.height! / 2 + this._padding, pos.translateX - this.width! / 2 + this._padding)
    const neededWidth = this._template.minRequiredWidth + this._padding * 2
    if ((this.width || 0) < neededWidth) {
      this.set({ width: neededWidth } as any)
      return this.recalcObjectsPosition()
    }
    const neededHeight = this._template.minRequiredHeight + this._padding * 2
    if ((this.height || 0) < neededHeight) {
      this.set({ height: neededHeight } as any)
      return this.recalcObjectsPosition()
    }
    this.recalcHeight()
    this._template.hideObjects(this._isOverflow)
    this._template.propagateEvent('moving', { target: this })
    setTimeout(() => this.canvas?.requestRenderAll(), 100)
  }

  recalcHeight() {
    this.set({ height: this._template.minRequiredHeight + this._padding * 2 } as any)
  }

  doLoading(loading: boolean) {
    this._isLoading = loading
    if (loading) {
      this._template.hideObjects(true)
    }
  }

  setProp(prop: string, value: any) {
    switch (prop) {
      case 'lock':
        this.set('lock', value.lock)
        this.setLock(value.lock)
        break
      case 'filter':
        this.set('filterJson', value.map(itm => isString(itm) ? itm : itm.toJSON()))
        this.applyFilter()
        break
      case 'templateOptions':
        this.templateOptions = { templateId: value.id, options: { ...value.options } }
        this._isLoading = true
        this._template.destroy(this.canvas!)
        this._template = DynamicGridTemplateFactory.getInstance().getTemplate(value.id)
        break
      case 'hideInactive':
        this.hideInactive = value
        this.applyFilter()
        break
      case 'showLabels':
        this.showLabels = value.showLabels
        this.applyFilter()
        break
      default:
        console.warn('Attempting to set unsupported WbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this })
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'templateOptions':
        result.templateOptions = this.templateOptions
        break
      case 'lock':
        result.lock = this.lock
        break
      case 'filter':
        result.filter = this.filterJson?.map(itm => new FilterCriteria(JSON.parse(itm))) || '[]'
        break
      case 'hideInactive':
        result.hideInactive = this.hideInactive
        break
      case 'showLabels':
        result.showLabels = this.showLabels
        break
      default:
        console.warn('Attempting to get unsupported WbObjectProp', prop)
    }
    return result
  }

  setLock(lock: boolean) {
    this.set('lockMovementX', lock)
    this.set('lockMovementY', lock)
    this.set('lockScalingFlip', lock)
    this.set('lockScalingX', lock)
    this.set('lockScalingY', lock)
    this.set('hasControls', !lock)
    this.set('lockRotation', true)
    this.set('hasRotatingPoint', false)
  }

  override toObject() {
    const propsToPluck = [
      'id',
      'groupBy',
      'filterJson',
      'preventUnlock',
      'angle',
      'backgroundColor',
      'attributes',
      'fill',
      'height',
      'width',
      'left',
      'top',
      'scaleX',
      'scaleY',
      'opacity',
      'version',
      'lock',
      'type',
      'templateOptions',
      'hideInactive',
      'showLabels',
    ]
    return pick(this, propsToPluck)
  }

  static fromObject(object: WbDynamic, callback?: Function) {
    return fabric.Object._fromObject(whiteboardConstants.objectTypes.dynamic, object, callback)
  }

  override _render(ctx: CanvasRenderingContext2D): void {
    // Draw rectangle
    super._render(ctx)
    if (this._isLoading) {
      ctx.fillStyle = '#f3f4f6'
      ctx.fillRect(-this.width! / 2, -this.height! / 2, this.width!, this.height!)
      ctx.font = '30px Helvetica'
      const measure = ctx.measureText('Loading...')
      ctx.fillStyle = '#2196f3'
      ctx.fillText('Loading...', -measure.width / 2, -measure.actualBoundingBoxAscent / 2)
    }

    if (this._isOverflow) {
      ctx.font = '30px Helvetica'
      const measure = ctx.measureText(this.overflowMessage)
      ctx.fillStyle = '#f3f4f6'
      ctx.fillRect(-this.width! / 2, -this.height! / 2, this.width!, this.height!)
      ctx.fillStyle = 'red'
      // Draw the overflow message in the middle of the object
      ctx.fillText(this.overflowMessage, -measure.width / 2, -measure.actualBoundingBoxAscent / 2)
    }

    if (!this._isLoading && !this._isOverflow) {
      ctx.save()
      this._template.render(ctx, this.width! - this._padding * 2, this.height! - this._padding * 2, this.top! + this._padding, this.left! + this._padding)
      ctx.restore()
      // ctx.fillStyle = 'white'
      // ctx.fillRect(-this.width! / 2, -this.height! / 2, this.width!, this.height!)
    }
  }
}

const f: any = fabric
f.WbDynamic = WbDynamic
