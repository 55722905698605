import type { CreateUpdateAssetModel } from './model/assetModel'
import type DuneAsset from '@/models/duneAsset'
import Net from '@/services/net'

export function createAsset(requestObject: CreateUpdateAssetModel) {
  const formData = new FormData()
  for (const key in requestObject) {
    formData.append(key, requestObject[key])
  }
  return Net.dunes.post<{ Id: string }>(`/assets`, formData)
}

export function updateAsset(assetId: string, requestObject: CreateUpdateAssetModel) {
  const formData = new FormData()
  for (const key in requestObject) {
    formData.append(key, requestObject[key])
  }
  return Net.dunes.put<{ Id: string }>(`/assets/${assetId}`, formData)
}

export function getSilhouetteAssets(context: string, contextKey: string) {
  return Net.dunes.get<DuneAsset[]>(`/assets`, { params: { Context: context, ContextKey: contextKey, ImageSet: 'Silhouette', Status: true } })
}

export function changeAssetStatus(context: string, contextKey: string, assetIds: string[], status: boolean) {
  const payload = { Context: context, ContextKey: contextKey, Status: status, AssetIds: assetIds }
  return Net.dunes.put(`/assets/status`, payload)
}
