<template>
  <div class="w-full grow flex flex-col h-full overflow-hidden">
    <tx-data-table
      :data="data" :columns="columns" :loading="loadingData" :item-actions="itemActions" :list-actions="listActions" :visible-columns="visibleColumns" :show-status-filter="true"
      :title="t('general.articles')" :model-path="modelPath" :total-column-width="totalColumnWidth" :sort-column="{ UpdatedDate: false }"
      :loading-more="isLoadingMore" :total-data-count="totalRequestCount" @selection-changed="onSelectionChanged" @refresh="doRefresh" @row-click="showDetails" @load-more-data="onLoadMoreData"
      @state-filter-changed="onStateFilterChanged" @status-filter-changed="onStatusFilterChange"
    />
    <approve-reject-request-dialog ref="showApproveRejectDialog" @approve-reject="onApproveReject" />

    <tx-drawer v-model="isAddArticleDrawerVisible" width="90%" right @closed="onAddArticleDrawerClosed">
      <add-articles
        v-if="currentView === viewEnums.addArticleForm" :visible="currentView === viewEnums.addArticleForm"
        :action-type="styleCreateAction" :context-article="currentArticle" :request-articles="selectedRequestArticles" :is-add-request="false" :is-confirm-request="true"
        :link-catalog-indexed-by-price-name-to-id="linkCatalogIndexedByPriceNameToId"
        @close="onAddArticleDrawerClosed"
      />
    </tx-drawer>
    <tx-drawer v-model="isDrawerVisible" right width="700px">
      <active-or-deactive-article
        v-if="isStatusDrawerVisible"
        class="grow"
        :type="isActivate ? 'activate' : 'deactivate'" :show-header="true"
        :articles="[currentArticle!]" :confirm-request-id="confirmRequestId" :confirm-request-catalog-code="confirmRequestCatalogCode" @cancel="closeDrawer" @updated="onArticlesUpdated"
      />
      <manage-article-state
        v-else-if="isStateDrawerVisible"
        class="grow" :articles="[currentArticle!]" :confirm-request-id="confirmRequestId" :confirm-request-catalog-code="confirmRequestCatalogCode" :request-confirm-state-id="requestConfirmValue"
        :show-header="false" @cancel="closeDrawer" @updated="onArticlesUpdated"
      />
    </tx-drawer>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'
import SeasonlessRequestsModel from './seasonlessRequests'
import useRequestLocalDataUpdater from '@/modules/articleDetails/composables/requestLocalDataUpdater'
import ManageArticleState from '@/modules/articleDetails/components/ManageArticleState.vue'
import useArticleLocalDataUpdater from '@/modules/articleDetails/composables/articleLocalDataUpdater'
import useUserProfileSettings from '@/modules/admin/composables/userProfileSettings'
import ActiveOrDeactiveArticle from '@/modules/articleDetails/components/ActiveOrDeactiveArticle.vue'
import { useConfirmDialog } from '@/shared/composables/confirmDialog'
import TxDataTable from '@/shared/components/txDataTable'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'
import type { ITxDataTableColumn, ITxDataTableItemAction, ITxDataTableListAction, ITxDataTableVisibleColumn } from '@/shared/components/txDataTable/TxDataTable.types'
import { useNotificationStore } from '@/store/notification'
import { appConstants, editRequestKeys, privileges, requestConstants } from '@/models/constants'
import type { UpdateRequestsStatusModel } from '@/models/request'
import ApproveRejectRequestDialog from '@/shared/components/requests/ApproveRejectRequestDialog.vue'
import { getArticleDetails, updateArticle, updateArticlePrices } from '@/api/t1/article'
import { getSeasonlessRequests, updateSeasonlessRequestStatuses } from '@/api/t1/seasonlessRequests'
import MyArticle from '@/models/myArticle'
import { AttributeType } from '@/models/catalogAttribute'
import { updateModelSizeScale } from '@/api/t1/size'
import TxDrawer from '@/shared/components/TxDrawer.vue'
import type { ArticlePriceModel, UpdateArticlePricesModel } from '@/api/t1/model/articleModel'
import ArticlePrice from '@/models/articlePrice'
import useEventsBus from '@/shared/composables/eventBus'
import utils from '@/services/utils'
import { useArticleFormHelper } from '@/shared/composables/articleFormHelper'
import router from '@/router'
import RequestModel from '@/models/request'
import type CatalogPriceGroup from '@/models/catalogPriceGroup'
import CatalogDetails from '@/models/catalogDetails'
import { getRequests } from '@/api/t1/request'
import AddArticles from '@/modules/browse/components/addArticles'

const { t } = useI18n()
const userStore = useUserStore()
const notificationsStore = useNotificationStore()
const { applyPersistedListViewLayout } = useUserProfileSettings()
const confirmDialog = useConfirmDialog()
const { refreshLocalArticlesData } = useArticleLocalDataUpdater()
const { emitter } = useEventsBus()
const { updateRequestsLocally } = useRequestLocalDataUpdater()
const { skipRestrictingPropertyUpdateBasedOnArticleState, getArticlesLocked, getArticlesMaxStateDetails, getIndexedRestrictedAttributesBasedOnArticlesMaxSateRank, getIndexedRestrictedAttributesBasedOnArticlesStateRank } = useArticleFormHelper()

const columns = ref<ITxDataTableColumn[]>([])
const data = ref<any[]>([])
const loadingData = ref(true)
const visibleColumns = ref<ITxDataTableVisibleColumn[]>([])
const modelPath = ref('')
const totalColumnWidth = ref(1200)
const isDrawerVisible = ref(false)
const isAddArticleDrawerVisible = ref(false)
const selectedItems = ref<RequestModel[]>([])
const requestsModel = shallowRef<SeasonlessRequestsModel>()
const showApproveRejectDialog = ref<InstanceType<typeof ApproveRejectRequestDialog>>()
const confirmRequestId = ref<number | null>(null)
const requestConfirmValue = ref<number | null>(null)
const currentArticle = ref<MyArticle | null>(null)
const isStatusDrawerVisible = ref(false)
const isStateDrawerVisible = ref(false)
const isActivate = ref(false)
const isAllowedConfirmRequests = ref<{ [key: string]: boolean }>({})
const tablePageSize = 100
const pageNumber = ref(0) // page number starts from 0
const currentRequestStatus = ref(0)
const currentRequestState = ref(1) // new
const isLoadingMore = ref(false)
const viewEnums = {
  modelDetails: 0,
  addArticleForm: 1,
}
const currentView = ref(viewEnums.modelDetails)
const styleCreateAction = ref<StyleCreateActionType | undefined>(undefined)
const selectedRequestArticles = ref([] as MyArticle[])
const linkCatalogIndexedByPriceNameToId = ref<Record<number, Record<string, number>>>({})
const totalRequestCount = ref(0)
const confirmRequestCatalogCode = ref<number | null>(null)

enum actionType {
  delete = 'delete',
  activate = 'activate',
  approve = 'approve',
  reject = 'reject',
  confirm = 'confirm',
  view = 'view',
}

const itemActions = computed(() => ([
  {
    id: actionType.view,
    enabled: item => item.Status === 1,
    visible: userStore.userProfile.isValidPrivilege(privileges.admin.nav.seasonlessRequestDetails),
    icon: 'fa-light fa-search',
    label: t('general.viewRecord'),
    onClick: (item) => {
      showDetails(item)
    },
  },
  {
    id: actionType.delete,
    enabled: true,
    visible: item => userStore.userProfile.isValidPrivilege(privileges.admin.seasonlessRequests.updateRequestStatusSeasonless) && item.Status > 0 && (item.State === requestConstants.requestStates.draft || item.State === requestConstants.requestStates.new),
    icon: 'fa-light fa-trash-can',
    label: t('general.delete'),
    onClick: (item) => {
      onActivateDeactivate([item], 0)
    },
  },
  {
    id: actionType.approve,
    enabled: true,
    visible: item => userStore.userProfile.isValidPrivilege(privileges.admin.seasonlessRequests.updateRequestStateSeasonless) && (item.State === requestConstants.requestStates.new || item.State === requestConstants.requestStates.reject),
    label: t('requests.approve'),
    icon: 'fa-light fa-thumbs-up',
    onClick: (item) => {
      openApproveRejectDialog([item], actionType.approve)
    },
  },
  {
    id: actionType.reject,
    enabled: true,
    visible: item => userStore.userProfile.isValidPrivilege(privileges.admin.seasonlessRequests.updateRequestStateSeasonless) && (item.State === requestConstants.requestStates.new || item.State === requestConstants.requestStates.approve),
    label: t('requests.reject'),
    icon: 'fa-light fa-thumbs-down',
    onClick: (item) => {
      openApproveRejectDialog([item], actionType.reject)
    },
  },
  {
    id: actionType.confirm,
    enabled: true,
    visible: item => userStore.userProfile.isValidPrivilege(privileges.request.confirmRequest) && isAllowedConfirmRequests.value[item.Id],
    label: t('requests.confirm'),
    icon: 'fa-light fa-paper-plane',
    onClick: (item) => {
      onConfirmRequest(item)
    },
  },
]) as Array<ITxDataTableItemAction>)

const listActions = computed(() => {
  const actions: ITxDataTableListAction[] = [{
    id: actionType.delete,
    label: t('general.delete'),
    icon: 'fa-light fa-trash-can',
    visible: userStore.userProfile.isValidPrivilege(privileges.admin.seasonlessRequests.updateRequestStatusSeasonless),
    enabled: selectedItems.value.length > 0 && selectedItems.value.every(x => x.Status > 0),
    onClick: () => {
      onActivateDeactivate(selectedItems.value, 0)
    },
  }, {
    id: actionType.approve,
    label: t('requests.approve'),
    icon: 'fa-light fa-thumbs-up',
    visible: userStore.userProfile.isValidPrivilege(privileges.admin.seasonlessRequests.updateRequestStateSeasonless),
    enabled: selectedItems.value.length > 0 && selectedItems.value.every(x => x.State === requestConstants.requestStates.new || x.State === requestConstants.requestStates.reject),
    onClick: () => {
      openApproveRejectDialog(selectedItems.value, actionType.approve)
    },
  }, {
    id: actionType.reject,
    label: t('requests.reject'),
    icon: 'fa-light fa-thumbs-down',
    visible: userStore.userProfile.isValidPrivilege(privileges.admin.seasonlessRequests.updateRequestStateSeasonless),
    enabled: selectedItems.value.length > 0 && selectedItems.value.every(x => x.State === requestConstants.requestStates.new || x.State === requestConstants.requestStates.approve),
    onClick: () => {
      openApproveRejectDialog(selectedItems.value, actionType.reject)
    },
  }]
  return actions
})

onMounted(async () => {
  requestsModel.value = new SeasonlessRequestsModel(t)
  columns.value = requestsModel.value.getDataTableColumns()
  visibleColumns.value = requestsModel.value.getDefaultVisibleColumnList()
  modelPath.value = requestsModel.value.modelPath
  // retrieve persisted default colums for model if existed and apply that on visible columns
  // Note: This should be part of all module implementations.
  const { persistedColumns, totalTableColumnWidth } = applyPersistedListViewLayout(true, modelPath.value, columns.value, visibleColumns.value)
  if (persistedColumns) {
    visibleColumns.value = persistedColumns
  }
  if (totalTableColumnWidth) {
    totalColumnWidth.value = totalTableColumnWidth
  }
  await doRefresh()
})

onUnmounted(() => {
})
// check if user is allowed confirm the request,
// should have specific privilege, attribute should be editable,
// should not be restricted at state and role level(role level attributes comes as non editable)
async function isAllowedConfirm(request: RequestModel) {
  if (request.IsCreateArticleRequest) {
    return true
  }
  let isAllowedConfirmRequest = false
  const { userProfile, activeCatalog } = userStore
  const { AccountDetails } = userProfile
  const isAccountValid = AccountDetails.AccountTypeId === 1 || AccountDetails.AccountId === activeCatalog?.AccountId
  let priceGroups: CatalogPriceGroup[] = userStore.activeCatalog!.CatalogPriceGroupList
  let catalogAttributes = userStore.myAttributes
  let articleObject: MyArticle | any | undefined
  let catalogDetails: CatalogDetails = userStore.activeCatalog!
  if (utils.isDefined(request.SourceCatalogCode)) {
    if (request.SourceCatalogCode === userStore.activeCatalog?.CatalogCode) {
      articleObject = await appConfig.DB!.getArticle(userStore.activeCatalog!, request.SourceArticleId!)
    }
    else {
      if (!userStore.indexedCatalogDetailsFromAdmin[request.SourceCatalogCode!]) {
        catalogDetails = new CatalogDetails(await appConfig.DB!.getCatalogDetails(request.SourceCatalogCode!), userStore.userProfile.Roles)
        // cache the catalog details
        userStore.setIndexedCatalogDetailsFromAdmin(catalogDetails)
      }
      else {
        catalogDetails = userStore.indexedCatalogDetailsFromAdmin[request.SourceCatalogCode!]
      }
      if (catalogDetails) {
        priceGroups = catalogDetails.CatalogPriceGroupList
        const activeArticleStateList = userStore.articleStateList.filter(articleState => articleState.Status)
        catalogAttributes = await appConfig.DB!.buildMyAttributes(catalogDetails, {}, userStore.currentUsername, true, userStore.currentCustomer, userStore.currentCustomerSegmentations, activeArticleStateList, false)
        try {
          const response = await getArticleDetails(request.SourceCatalogCode!, request.SourceArticleId!)
          if (response && response.data && response.data.Article) {
            articleObject = response.data.Article
          }
        }
        catch (error) {
          console.error(`Error fetching article details for catalog ${request.SourceCatalogCode!} sourcearticleid ${request.SourceArticleId} : ${error}`)
        }
      }
    }
  }

  if (articleObject) {
    const key = Object.keys(request.Content)[0]
    const maxArticleStateRankDetails = getArticlesMaxStateDetails([articleObject], (key === editRequestKeys.prices ? !!catalogDetails!.IsPriceByStyleApply : key === editRequestKeys.sizeScale ? !!userStore.sellerDetails?.IsSizeScaleByStyle : key === editRequestKeys.modelName))
    if (request.RequestType === requestConstants.requestTypes.EditAttribute.key) {
      const attribute = catalogAttributes![key]
      const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.update)
      if (hasPrivilege && isAccountValid && attribute.Editable) {
        const indexedRestrictedAttributesBasedOnArticlesMaxSateRank = getIndexedRestrictedAttributesBasedOnArticlesMaxSateRank([articleObject])
        const indexedRestrictedAttributesBasedOnArticlesStateRank = getIndexedRestrictedAttributesBasedOnArticlesStateRank([articleObject])
        if (!attribute.ReadOnly && ((attribute.IsModelLevel && !indexedRestrictedAttributesBasedOnArticlesMaxSateRank.hasOwnProperty(attribute.SystemName)) || (!attribute.IsModelLevel && !indexedRestrictedAttributesBasedOnArticlesStateRank.hasOwnProperty(attribute.SystemName)))) {
          isAllowedConfirmRequest = true
        }
      }
    }
    else {
      // static properties
      if (key === editRequestKeys.stateName) {
        const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.AssignArticleStateToArticles)
        if (hasPrivilege && isAccountValid) {
          isAllowedConfirmRequest = true
        }
      }
      else if (key === editRequestKeys.modelName) {
        const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.update)
        if (hasPrivilege && isAccountValid) {
          isAllowedConfirmRequest = true
          if (utils.isDefined(maxArticleStateRankDetails)) {
            // if user has special permission assigned to his role then skip restriction
            if (!skipRestrictingPropertyUpdateBasedOnArticleState) {
              isAllowedConfirmRequest = maxArticleStateRankDetails.IsModelNameEditable === 1
            }
          }
        }
      }
      else if (key === editRequestKeys.prices) {
        const hasPrivilege = userProfile.isValidPrivilege(privileges.article.UpdateCatalogModelPrices)
          || userProfile.isValidPrivilege(privileges.article.UpdateCatalogArticleBulkPrices)
        if (hasPrivilege && isAccountValid) {
          const isAnyArticleLocked = await getArticlesLocked([articleObject], !!catalogDetails?.IsPriceByStyleApply)

          if (!isAnyArticleLocked || userProfile.isValidPrivilege(privileges.request.ignoreArticlePriceLockInConfirmPriceRequest)) {
            let allEditableAndNotRestricted = true

            for (const price of request.Content.Prices) {
              const priceGroup = priceGroups.filter(pg => pg.Id === price.Id)[0]
              if (priceGroup && maxArticleStateRankDetails) {
                const priceGroupStateRank = priceGroup.StateRank || 0
                const lockBasedOnArticlesState = !skipRestrictingPropertyUpdateBasedOnArticleState
                  && priceGroup.StateRank != null && maxArticleStateRankDetails.StateRank >= priceGroupStateRank
                if (!priceGroup.IsEditable || lockBasedOnArticlesState) {
                  allEditableAndNotRestricted = false
                  break
                }
              }
              else {
                allEditableAndNotRestricted = false
                break
              }
            }

            if (allEditableAndNotRestricted) {
              isAllowedConfirmRequest = true
            }
          }
        }
      }
      else if (key === editRequestKeys.status) {
        const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.updateStatus)
        if (hasPrivilege && isAccountValid) {
          isAllowedConfirmRequest = true
        }
      }
      else if (key === editRequestKeys.colorId) {
        const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.update)
        if (hasPrivilege && isAccountValid && catalogDetails!.DataSourceTypeId !== appConstants.catalogTypes.inherited
          && userStore.sellerDetails?.AutomaticNumbering === 1 && (articleObject.ColorId === null || articleObject.ColorId === 0)) {
          isAllowedConfirmRequest = true
        }
      }
      else if (key === editRequestKeys.sizeScale) {
        const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.updateSizeScale)
        const lockedBasedOnArticleState = (maxArticleStateRankDetails == null || !maxArticleStateRankDetails.IsSizeScaleEditable) && !skipRestrictingPropertyUpdateBasedOnArticleState
        if (hasPrivilege && isAccountValid && catalogDetails!.DataSourceTypeId !== appConstants.catalogTypes.inherited && userStore.sellerDetails?.IsSizeScaleByStyle && !lockedBasedOnArticleState) {
          isAllowedConfirmRequest = true
        }
      }
    }
  }
  return isAllowedConfirmRequest
}
function onSelectionChanged(items: any[]) {
  selectedItems.value = items
}

async function doRefresh(state: number = requestConstants.requestStates.new, status: number = 1, page: number = 0) {
  try {
    if (userStore.activeCatalog) {
      if (page === 0) {
        selectedItems.value = []
        data.value = []
        loadingData.value = true
      }
      else {
        isLoadingMore.value = true
      }

      // Fetch data
      const response = await getSeasonlessRequests(state, status, page, tablePageSize)

      if (response && response.data.length) {
        if (page === 0) {
          totalRequestCount.value = response.headers['x-total-count'] ? Number(response.headers['x-total-count']) : 0
        }
        const requestModelData = response.data.map((al: any) => new RequestModel(al))

        const newRecords = await utils.getModifyArticleRequestsTranslatedData(userStore.activeCatalog, userStore.myAttributes!, requestModelData, userStore.sizeScales, userStore.articleStateList, appConfig.DB!, userStore.priceGroups, true, userStore.indexedCatalogDetailsFromAdmin, userStore.userProfile!.Roles)
        // Merge new records with existing ones
        data.value = [...data.value, ...newRecords]

        // Check if user can confirm requests (if the privilege exists)
        if (userStore.userProfile.isValidPrivilege(privileges.request.confirmRequest)) {
          for (const item of newRecords) {
            if (item.State === requestConstants.requestStates.approve && utils.isDefined(item.SourceCatalogCode)) {
              isAllowedConfirmRequests.value[item.Id] = await isAllowedConfirm(item)
            }
            else {
              isAllowedConfirmRequests.value[item.Id] = false
            }
          }
        }
      }
    }
  }
  catch (error) {
    console.error('Error loading data:', error)
    notificationsStore.addNotification({ message: 'Failed to load data', type: 'Alert' })
    loadingData.value = false
    isLoadingMore.value = false
  }
  finally {
    currentRequestState.value = state
    currentRequestStatus.value = status
    loadingData.value = false
    isLoadingMore.value = false
  }
}

// handle load more code
async function onLoadMoreData() {
  if (!loadingData.value && !loadingData.value) {
    pageNumber.value++
    console.log(`Loading page ${pageNumber.value} of seasonless requests data!!!`)
    // check the filters and if the showALl toggle value
    await doRefresh(currentRequestState.value, currentRequestStatus.value, pageNumber.value)
  }
}

async function onStateFilterChanged(state: number) {
  if (state && state !== currentRequestState.value) {
    pageNumber.value = 0 // Reset the page number to 0 when filters change
    await doRefresh(state, currentRequestState.value, pageNumber.value)
  }
}

async function onStatusFilterChange(status: number) {
  console.log('on show all records change')
  if (status && status !== currentRequestStatus.value) {
    pageNumber.value = 0
    data.value = []
    await doRefresh(currentRequestState.value, status, pageNumber.value)
  }
}

function openApproveRejectDialog(requests: any[], action: actionType) {
  const actionNumber = action === actionType.approve ? requestConstants.requestStates.approve : requestConstants.requestStates.reject
  showApproveRejectDialog.value?.showDialog(requests, actionNumber, true)
}

async function onApproveReject() {
  doRefresh()
}

async function onConfirmRequest(request: any) {
  selectedRequestArticles.value = []
  if (userStore.activeCatalog && request.Content) {
    let articleObject: MyArticle | any | undefined
    let priceGroups = userStore.priceGroups
    let catalogAttributes = userStore.myAttributes
    let catalogDetails: CatalogDetails = userStore.activeCatalog!
    if (request.SourceCatalogCode! === userStore.activeCatalog?.CatalogCode) {
      articleObject = await appConfig.DB!.getArticle(userStore.activeCatalog!, request.SourceArticleId)
    }
    else {
      if (!userStore.indexedCatalogDetailsFromAdmin[request.SourceCatalogCode!]) {
        catalogDetails = new CatalogDetails(await appConfig.DB!.getCatalogDetails(request.SourceCatalogCode!), userStore.userProfile.Roles)
      }
      else {
        catalogDetails = userStore.indexedCatalogDetailsFromAdmin[request.SourceCatalogCode!]
      }
      if (catalogDetails) {
        priceGroups = {
          retail: catalogDetails.CatalogPriceGroupList.find(pg => pg.Name === 'MSRP') as CatalogPriceGroup | undefined,
          wholesale: catalogDetails.CatalogPriceGroupList.find(pg => pg.Name === 'Wholesale Price') as CatalogPriceGroup | undefined,
          outlet: catalogDetails.CatalogPriceGroupList.find(pg => pg.Name === 'Outlet Price') as CatalogPriceGroup | undefined,
        }
        // priceGroups: { retail: undefined as CatalogPriceGroup | undefined, wholesale: undefined as CatalogPriceGroup | undefined, outlet: undefined as CatalogPriceGroup | undefined },
        const activeArticleStateList = userStore.articleStateList.filter(articleState => articleState.Status)
        catalogAttributes = await appConfig.DB!.buildMyAttributes(catalogDetails, {}, userStore.currentUsername, true, userStore.currentCustomer, userStore.currentCustomerSegmentations, activeArticleStateList, false)
        const response = await getArticleDetails(request.SourceCatalogCode!, request.SourceArticleId!)
        if (response && response.data && response.data.Article) {
          articleObject = response.data.Article
        }
      }
    }
    if (request.IsCreateArticleRequest) {
      currentArticle.value = articleObject
      const requestArticle = await utils.getRequestArticle(catalogDetails, request, appConfig.DB, catalogAttributes, priceGroups)
      // for other catalgos just considering the price groups by there name
      const retailPrice = priceGroups.retail && requestArticle._Prices ? requestArticle._Prices[priceGroups.retail.Id]?.Price || 0 : 0
      const wholesalePrice = priceGroups.wholesale && requestArticle._Prices ? requestArticle._Prices[priceGroups.wholesale.Id]?.Price || 0 : 0
      const outletPrice = priceGroups.outlet && requestArticle._Prices ? requestArticle._Prices[priceGroups.outlet.Id]?.Price || 0 : 0
      selectedRequestArticles.value.push(new MyArticle(requestArticle, catalogDetails.AssignedCatalogAttributes, retailPrice, wholesalePrice, outletPrice, [], true, [], [], [], catalogDetails!.Season, catalogDetails!.DataSourceTypeId, catalogDetails!.Config.ArticlesCustomSortOrder))
      if (selectedRequestArticles.value.length) {
        currentView.value = viewEnums.addArticleForm
        // if different catalog fetch the model reuqests
        let requestModelHasAnyConfirmedRequest: any = {}
        if (request.SourceCatalogCode! === userStore.activeCatalog?.CatalogCode) { // fetch model requests
          await appConfig.DB?.requests.where({ CatalogCode: userStore.activeCatalog.CatalogCode, RequestModelNumber: requestArticle.ModelNumber }).filter(request => request.State === requestConstants.requestStates.confirm).first()
        }
        else {
          const modelRequestResponse = await getRequests(request.SourceCatalogCode!, request.SourceModelId!)
          const modelRequests = modelRequestResponse.data
          requestModelHasAnyConfirmedRequest = modelRequests.filter(request => request.State === requestConstants.requestStates.confirm)[0]
        }
        styleCreateAction.value = request.RequestSource === requestConstants.requestSources.similarStyle && !requestModelHasAnyConfirmedRequest ? 'copyModel' : 'carryoverModel'
        isAddArticleDrawerVisible.value = true
      }
    }
    else { // edit request
      confirmRequestId.value = null
      confirmRequestCatalogCode.value = null
      const title = t('general.alert')
      let message = t('requests.confirmEditRequestMessage')
      // add comment and reason
      const confirmContentArray: any[] = []
      confirmContentArray.push({ header: `<strong>${t('requests.title.approvalRejectionReason')}:</strong>` })
      confirmContentArray.push(`${t('requestsTable.fields.reason')}: ${request.Reason}`)
      confirmContentArray.push(`${t('requestsTable.fields.comment')}: ${request.Comment}`)

      const attributesList = Object.values(userStore.myAttributes!)
      const modelLevelAttributeNames = attributesList
        .filter(attribute => attribute.IsModelLevel)
        .map(attribute => attribute.SystemName)

      const key = Object.keys(request.Content)[0]
      // show impacted seasons for confirm edit prices/ seasonless attributes request
      if (request.ImpactedCatalogs && request.ImpactedCatalogs.length) {
        const uniqueSeasons = [...new Set(request.ImpactedCatalogs.map(season => season.Season))].join(',')
        const affectedSeasonsString = uniqueSeasons
        message = t('requests.confirmEditPricesRequestMessage', { seasons: affectedSeasonsString })
      }

      await confirmDialog(title, message, confirmContentArray, async () => {
        let newValue = typeof request.Content === 'string' ? utils.tryParse(request.Content)[key] : request.Content[key]
        const propertiesToUpdate = { state: requestConstants.requestStates.confirm, comment: request.Comment, reason: request.Reason, updatedByUserName: userStore.currentUsername }
        const attr = attributesList.filter(attribute => attribute.SystemName === key)[0]
        if (articleObject) {
          currentArticle.value = articleObject
          if (attr && (!attr.IsStatic || key === 'ModelName')) {
            const updatePayload = {
              ModelId: articleObject.ModelId,
              ModelNumber: articleObject.ModelNumber,
              ArticleNumber: articleObject.ArticleNumber,
              ArticleName: articleObject.ArticleName,
            }
            if (attr.AttributeType === AttributeType.ColorPalette) {
              newValue = JSON.stringify(newValue)
            }
            updatePayload[key] = newValue
            try {
              await updateArticle(request.CatalogCode, articleObject.Id, updatePayload, modelLevelAttributeNames.includes(key), request.Id)
              await refreshLocalArticlesData(true, articleObject.ModelId)
              await updateRequestsLocally([request.Id], propertiesToUpdate)
              doRefresh()
              notificationsStore.addNotification({ message: t('updateArticle.updateSuccessfully'), type: 'Success' })
            }
            catch (error) {
              console.error(error)
            }
          }
          if ((attr && attr.IsStatic) || key === editRequestKeys.prices) {
            if (key === editRequestKeys.stateName) {
              requestConfirmValue.value = Number.parseInt(newValue)
              confirmRequestId.value = request.Id
              isDrawerVisible.value = true
              isStateDrawerVisible.value = true
              confirmRequestCatalogCode.value = request.CatalogCode
            }
            else if (key === editRequestKeys.colorId) {
              const updatePayload = {
                ModelId: articleObject.ModelId,
                ModelNumber: articleObject.ModelNumber,
                ArticleNumber: articleObject.ArticleNumber,
                ArticleName: articleObject.ArticleName,
              }
              const colorId = newValue ? Object.keys(newValue)[0] : newValue
              updatePayload[key] = colorId
              try {
                await updateArticle(request.CatalogCode, articleObject.Id, updatePayload, modelLevelAttributeNames.includes(key), request.Id)
                await refreshLocalArticlesData(true, articleObject.ModelId)
                await updateRequestsLocally([request.Id], propertiesToUpdate)
                doRefresh()
                notificationsStore.addNotification({ message: t('updateArticle.updateSuccessfully'), type: 'Success' })
              }
              catch (error) {
                console.error(error)
              }
            }
            else if (key === editRequestKeys.status) {
              requestConfirmValue.value = Number.parseInt(newValue)
              confirmRequestId.value = request.Id
              isActivate.value = newValue === 1
              isDrawerVisible.value = true
              isStatusDrawerVisible.value = true
              confirmRequestCatalogCode.value = request.CatalogCode
            }
            else if (key === editRequestKeys.sizeScale) {
              try {
                await updateModelSizeScale(request.CatalogCode, request.SourceModelId!, { SizeScaleId: newValue }, request.Id)
                await refreshLocalArticlesData(true, articleObject.ModelId)
                await updateRequestsLocally([request.Id], propertiesToUpdate)
                doRefresh()
                notificationsStore.addNotification({ message: t('updateArticle.updateSuccessfully'), type: 'Success' })
              }
              catch (error) {
                console.error(error)
              }
            }
            else if (key === editRequestKeys.prices) {
              const requestedPrices = request.Content.Prices.map(contentPrice => ({
                PriceGroupId: contentPrice.Id,
                Price: Number.parseFloat(contentPrice.Price),
              }))

              const articleIdField = catalogDetails?.IsPriceByStyleApply ? 'ModelNumber' : 'Id'
              const payload: UpdateArticlePricesModel = {
                Prices: requestedPrices,
              }

              try {
                const response = await updateArticlePrices(request.CatalogCode, articleObject[articleIdField], payload, catalogDetails!.IsPriceByStyleApply, request.Id)

                await refreshLocalArticlesData(true, articleObject.ModelId)
                await updateRequestsLocally([request.Id], propertiesToUpdate)
                doRefresh()

                // update local articles data
                const responseArticleMap: Record<number, ArticlePriceModel[]> = {}

                if (response.data && Array.isArray(response.data)) {
                  response.data.forEach((item: ArticlePriceModel) => {
                    if (!responseArticleMap.hasOwnProperty(item.ArticleId)) {
                      responseArticleMap[item.ArticleId] = []
                    }
                    responseArticleMap[item.ArticleId].push(item)
                  })
                }

                const articlePriceData: ArticlePrice[] = []
                for (const articleId in responseArticleMap) {
                  const articlePrice = { Id: Number(articleId), Prices: responseArticleMap[articleId] }
                  articlePriceData.push(new ArticlePrice(request.CatalogCode, articlePrice))
                }

                // TODO CHECK THIS
                await appConfig.DB!.bulkUpdateArticles(request.CatalogCode, 'prices', articlePriceData, true)
                emitter('catalogDataUpdated', { source: 'Price' })

                notificationsStore.addNotification({ message: t('updateArticle.updateSuccessfully'), type: 'Success' })
              }
              catch (error) {
                console.error(error)
              }
            }
          }
        }
      })
    }
  }
}

// currently only delete is allowed
async function onActivateDeactivate(requests: RequestModel[], status: ZeroOrOneType) {
  if (userStore.activeCatalog) {
    const artcileNumbers: string[] = []
    const requestObj: UpdateRequestsStatusModel[] = []
    requests.forEach((request) => {
      artcileNumbers.push(request.SourceArticleNumber)
      requestObj.push({
        Id: request.Id,
        Status: status,
      })
    })
    const title = t('general.alert')
    const activateOrDeactivateAction = status === 0 ? 'delete' : 'activate'
    const message = t('activeOrDeactiveArticle.activateOrDeactivateAlert', { action: activateOrDeactivateAction, number: artcileNumbers.toString() })

    await confirmDialog(title, message, [], async () => {
      await updateSeasonlessRequestStatuses(requestObj)
      notificationsStore.addNotification({ message: t(status === 0 ? 'activeOrDeactiveArticle.deactivateRequestSuccessfully' : 'activeOrDeactiveArticle.activateRequestSuccessfully', 1), type: 'Success' })
      doRefresh()
    })
  }
}

async function onArticlesUpdated() {
  const message = t('general.updatedSuccessfully')
  notificationsStore.addNotification({ message, type: 'Success' })
  doRefresh()
  closeDrawer()
}

async function showDetails(request: RequestModel) {
  if (userStore.userProfile.isValidPrivilege(privileges.admin.nav.seasonlessRequestDetails)) {
    router.push({ name: 'SeasonlessRequestsDetails', params: { requestId: request.Id, catalogCode: request.CatalogCode } })
  }
}

function closeDrawer() {
  isStateDrawerVisible.value = false
  isStatusDrawerVisible.value = false
  isDrawerVisible.value = false
}

function onAddArticleDrawerClosed() {
  currentArticle.value = null
  styleCreateAction.value = undefined
  isAddArticleDrawerVisible.value = false
}
</script>
