<template>
  <div ref="imagesEl" class="flex flex-col overflow-hidden w-full h-full bg-white">
    <div class="flex-1 w-full h-full overflow-hidden">
      <div class="w-full h-full select-none">
        <no-image v-if="!article._Assets.length && !placeHolderAsset" class="w-full h-full text-dark-25" />

        <image-loader
          v-else-if="!article._Assets.length && placeHolderAsset" :asset="placeHolderAsset"
          :context-key="activeCatalog.ContextKey" :size="0" format="png"
        />
        <div v-else class="flex flex-col w-full h-full">
          <pan-zoom ref="panzoomEl" class="flex-1 w-full h-full cursor-grab select-none" :height="panzoomHeight">
            <image-loader
              v-if="!isFullScreen" :asset="article._Assets[activeImageIndex]" :context-key="activeCatalog.ContextKey"
              :size="400" :format="activeImageFormat"
            />
            <image-loader
              v-else :asset="article._Assets[activeImageIndex]" :context-key="activeCatalog.ContextKey" :size="0"
              :format="activeImageFormat"
            />
          </pan-zoom>
          <div class="flex items-center h-8 mb-1" :class="isFullScreen ? 'justify-center' : 'justify-end'">
            <div class="flex-1 text-xs text-grey">
              <div>{{ article._Assets[activeImageIndex]?.Key }}</div>
              <div>{{ article._Assets[activeImageIndex] ? new Date(article._Assets[activeImageIndex].UpdatedOn).toLocaleString() : '' }}</div>
            </div>
            <font-awesome-icon class="w-6 h-6 p-1 cursor-pointer hover:text-primary-500" icon="fa-light fa-refresh" @click="onReset" />
            <font-awesome-icon class="w-6 h-6 p-1 cursor-pointer hover:text-primary-500" icon="fa-light fa-magnifying-glass-minus" @click="onZoomOut" />
            <font-awesome-icon class="w-6 h-6 p-1 cursor-pointer hover:text-primary-500" icon="fa-light fa-magnifying-glass-plus" @click="onZoomIn" />
            <font-awesome-icon class="w-6 h-6 p-1 cursor-pointer hover:text-primary-500" icon="fa-light fa-chevron-left" @click="onPrevious" />
            <font-awesome-icon class="w-6 h-6 p-1 cursor-pointer hover:text-primary-500" icon="fa-light fa-chevron-right" @click="onNext" />
            <font-awesome-icon v-if=" article.CatalogCode === userStore.activeCatalog?.CatalogCode && userStore.activeCatalog && userStore.activeCatalog.Config.WhiteboardImagePlaceholderOptions.keys.length && userStore.activeCatalog.Config.WhiteboardImagePlaceholderOptions.keys.includes(article._Assets[activeImageIndex]?.Key)" class="w-6 h-6 p-1 cursor-pointer hover:text-primary-500" icon="fa-light fa-trash-can" @click="deleteAsset" />
            <font-awesome-icon class="w-6 h-6 p-1 cursor-pointer hover:text-primary-500" :icon="fullScreenIcon" @click="toggleFullScreen" />
          </div>
        </div>
      </div>
      <!-- TODO: Add 3d image -->
    </div>
    <tx-horizontal-scroll class="relative h-25">
      <div
        v-for="(asset, index) in article._Assets" :key="asset.Id" v-tooltip="{ text: asset.Key, theme: { placement: 'top' } }"
        class="inline-block p-1 cursor-pointer h-25" :style="{ minWidth: `${otherImageSize}px` }"
        :class="{ active: index === activeImageIndex }" @click="onImgClick(index)"
      >
        <threed-icon v-if="asset.Is3DModel" class="w-20 h-20" />
        <image-loader v-else :asset="asset" :context-key="activeCatalog.ContextKey" :size="95" />
      </div>
      <div class="h-0.75 bg-grey-light absolute top-24 mt-px left-0 z-10 w-full" />
      <div
        class="h-0.75 text-primary-500 absolute top-24 mt-px transition-all z-20"
        :style="{ width: '103px', left: `${activeImageIndex * 103}px` }"
      />
    </tx-horizontal-scroll>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import NoImage from '@/shared/components/svg/NoImage.vue'
import ThreedIcon from '@/shared/components/svg/ThreedIcon.vue'
import ImageLoader from '@/shared/components/ImageLoader.vue'
import PanZoom from '@/shared/components/PanZoom.vue'
import TxHorizontalScroll from '@/shared/components/TxHorizontalScroll.vue'
import type MyArticle from '@/models/myArticle'
import { useUserStore } from '@/store/userData'
import utils from '@/services/utils'
import { changeAssetStatus } from '@/api/dune/asset'
import { useNotificationStore } from '@/store/notification'
import { useConfirmDialog } from '@/shared/composables/confirmDialog'

const props = withDefaults(defineProps<{
  article: MyArticle
  otherImageSize?: number
}>(), { otherImageSize: 80 })

const emit = defineEmits<{
  (e: 'assetStatusUpdated'): void
}>()

const confirmDialog = useConfirmDialog()

const userStore = useUserStore()
const notificationStore = useNotificationStore()
const { t } = useI18n()

const imagesEl = ref<HTMLElement>()
const panzoomEl = ref<InstanceType<typeof PanZoom>>()
const activeImageIndex = ref(0)

// full screen
const isFullScreen = ref(false)
const fullScreenIcon = computed(() => isFullScreen.value ? 'fa-light fa-minimize' : 'fa-light fa-arrows-maximize')

const activeCatalog = computed(() => props.article.CatalogCode === userStore.activeCatalog?.CatalogCode ? userStore.activeCatalog : userStore.linkedCatalogDetails[props.article.CatalogCode])

const panzoomHeight = computed(() => {
  if (isFullScreen.value) {
    return `${window.screen.height - 150}px`
  }
  return '100%'
})

const activeImageFormat = computed(() => {
  const activeImage = props.article._Assets[activeImageIndex.value]
  if (activeImage.Is3DModel) {
    return activeImage.Key?.trim().toLowerCase() === 'glb' ? 'glb' : 'gltf'
  }
  return isFullScreen.value ? 'png' : undefined
})
const placeHolderAsset = computed(() => {
  if (!props.article._Assets.length && userStore && userStore.indexedSilhouetteImages && userStore.activeCatalog?.Config.SilhouetteImagesCriteria) {
    const imageKey = utils.getPlaceHolderImageKey(userStore.activeCatalog.Config.SilhouetteImagesCriteria, props.article, userStore.indexedSilhouetteImages)
    if (utils.isValidStringValue(imageKey) && userStore.indexedSilhouetteImages[imageKey] && userStore.activeCatalog.ContextKey && userStore.activeCatalog.DuneContext) {
      return userStore.indexedSilhouetteImages[imageKey]
    }
  }
  return undefined
})

function onImgClick(index: number) {
  activeImageIndex.value = index
}

function onPrevious() {
  if (activeImageIndex.value === 0) {
    activeImageIndex.value = props.article._Assets.length - 1
    return
  }
  activeImageIndex.value--
}

function onNext() {
  if (activeImageIndex.value + 1 === props.article._Assets.length) {
    activeImageIndex.value = 0
    return
  }
  activeImageIndex.value++
}

function onZoomIn() {
  panzoomEl.value?.zoomIn()
}

function onZoomOut() {
  panzoomEl.value?.zoomOut()
}

function onReset() {
  panzoomEl.value?.reset()
}

function toggleFullScreen() {
  if (document.fullscreenElement) {
    document.exitFullscreen()
    isFullScreen.value = false
  }
  else {
    imagesEl.value?.requestFullscreen().then(() => isFullScreen.value = true)
      .catch((error) => {
        console.warn(`unable to go full screen, \n ${error}`)
        isFullScreen.value = false
      })
  }
}
async function deleteAsset() {
  if (userStore.activeCatalog) {
    await confirmDialog(t('general.alert'), t('articleDetails.deleteAsset'), [], async () => {
      await changeAssetStatus(userStore.activeCatalog!.DuneContext, userStore.activeCatalog!.ContextKey, [props.article._Assets[activeImageIndex.value].Id], false).then(() => {
        notificationStore.addNotification(({ message: t('articleDetails.alerts.imageDeletedSuccessfully'), type: 'Success' }))
        emit('assetStatusUpdated')
      }).catch(() => {
        notificationStore.addNotification(({ message: t('articleDetails.alerts.imageDeletedFailed'), type: 'Alert' }))
      })
    })
  }
}

function handleFullScreen() {
  if (document.fullscreenElement) {
    isFullScreen.value = true
  }
  else {
    isFullScreen.value = false
  }
}

onMounted(() => {
  document.addEventListener('fullscreenchange', handleFullScreen, false)
})

onUnmounted(() => {
  document.removeEventListener('fullscreenchange', handleFullScreen)
})
</script>
